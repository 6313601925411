import React from 'react';
import { APIRegistrar /*, NewPolicy*/ } from './common/api';
//import { getAPITokenHeaders } from './password';

interface RequesterListProps {
  registrars: APIRegistrar[],
  onSelectRegistrar: (newRequesterID: number) => void
}

export default function RegistrarList(props: RequesterListProps) {
  return (
    <div>
      <h2>Registrars</h2>
      <br></br>

      <table style={{ width: "100%" }} className="policy-list-table">
          <tbody>
          {
              props.registrars.map(reg => (
              <tr key={reg.id}
                  onClick={() => props.onSelectRegistrar(reg.id)}>
                  <td>
                  <a href={`#/registrar/${reg.id}`}
                      onClick={() => props.onSelectRegistrar(reg.id)}>
                      {reg.name}
                  </a>
                  <br />
                  </td>
              </tr>
              ))
          }
          </tbody>
      </table>
    </div>
  );
}

// function newRegistrar(): Promise<number> {
//   return new Promise((resolve, reject) => {
//     const headers = getAPITokenHeaders();
//     headers.set('Content-Type', 'application/json');
//     fetch(`/api/registrar`, {
//       method: 'PUT',
//       headers: headers,
//       body: JSON.stringify({
//         id: 0,
//         name: "Untitled Registrar"
//       } as APIRegistrar)
//     }).then(async rawResponse => {
//       const response = await rawResponse.json() as unknown as NewPolicy;
//       if (response.status === 'success') {
//         return resolve(response.id);
//       } else {
//         return reject(false);
//       }
//     }).catch(error => reject(error));
//   });
// }
