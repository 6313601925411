import React from 'react';
import { /*APIRegistrarGroup,*/ APIRegistrarGroupMembers, GetRegistrarGroupMembers } from './common/api';
import LoadingSpinner from './LoadingSpinner';
import navHeader from './NavHeader';
import { clearPassword, getAPITokenHeaders } from './password';
import RequesterList from './RequesterList';
import RequesterGroupList from './RequesterGroupList';
import RegistrarList from './RegistrarList';
import RegistrarGroupList from './RegistrarGroupList';

interface RegistrarGroupMembersProps {
    onSelectRequester: (newRequesterID: number) => void,
    onSelectRqG: (newRqGID: number) => void,
    onSelectRegistrar: (newRegistrarID: number) => void;
    onSelectRrG: (newRrGID: number) => void;
}

export default function RegistrarGroupMembersList(props: RegistrarGroupMembersProps) {

  const [registrarGroupMemberList, setRegistrarGroupMemberList] = React.useState<APIRegistrarGroupMembers | null>(null);
  const [isLoadingList, setIsLoadingList] = React.useState<boolean>(false);
  //const [registrarGroups, setRegistrarGroups] = React.useState<APIRegistrarGroup[] | null>(null);

  if (registrarGroupMemberList === null && !isLoadingList) {
    setIsLoadingList(true);
    getRegistrarGroupMembers().then(setRegistrarGroupMemberList).finally(() => setIsLoadingList(false));
  }

  if (registrarGroupMemberList === null) {
    return (<LoadingSpinner />);
  }

  return (
    <div> {navHeader()}
      <h2>Registrar Group Members</h2>
      <div>
        <div style={{float: 'left', width: '25%'}}>
          <RequesterList
            requesters={registrarGroupMemberList.requesters}
            onSelectRequester={props.onSelectRequester} />
        </div>
        <div style={{float: 'left', width: '25%'}}>
            <RequesterGroupList
              requesterGroups={registrarGroupMemberList.requester_groups}
              onSelectRqG={props.onSelectRqG} />
        </div>
        <div style={{float: 'left', width: '25%'}}>
            <RegistrarList
              registrars={registrarGroupMemberList.registrars}
              onSelectRegistrar={props.onSelectRegistrar} />
        </div>
      </div>
      <div style={{float: 'left', width: '25%'}}>
          <RegistrarGroupList
            registrarGroups={registrarGroupMemberList.registrar_groups}
            onSelectRegistrarGroup={props.onSelectRrG} />
        </div>
    </div>
  );
}

export async function getRegistrarGroupMembers(): Promise<APIRegistrarGroupMembers> {
  const rawResponse = await fetch('/api/registrar_group_member_query_all', {
      headers: getAPITokenHeaders()
    });
    if (rawResponse.status === 401) {
      clearPassword();
      throw new Error("Incorrect password");
    }
    const response = await rawResponse.json() as unknown as GetRegistrarGroupMembers;
    if (response.status === 'success') {
      return response.registrar_group_members;
    } else {
      throw new Error(response.error);
    }
}
