// A constant representing the key used to store the shared password in local storage
const LOCAL_STORAGE_KEY = 'jakeSharedPassword';

// Store a password in local storage
export function storePassword(password: string)
  {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, password);
  }

// Retrieve a password from local storage
export function getPassword()
  {
  return window.localStorage.getItem(LOCAL_STORAGE_KEY);
  }

// Clear the stored password from local storage
export function clearPassword()
  {
  window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  }

// Get API token headers with the stored password
export function getAPITokenHeaders()
  {
  const password = getPassword();
  if (password) return new Headers({ 'Authorization': `Bearer ${password}` });
  return new Headers();
  }
