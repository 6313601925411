import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { APIPolicy } from './common/api';
import { savePolicy } from './PolicyEditor';
import CloseButton from './CloseButton';

// Function to check the integrity of the imported file
async function checkFile(e: React.ChangeEvent<HTMLInputElement>, setData: React.Dispatch<React.SetStateAction<string | null>>, setFileError: React.Dispatch<React.SetStateAction<string | null>>)
  {
  let jsonData;
  try
    {
    // Check if a file is selected
    if (e.target.files)
      {
      // Read the content of the file
      let data = await getTextFromFile(e.target.files[0]);
      // Handle potential errors
      if (data instanceof DOMException)
        {
        setFileError(data.message);
        }
      else if (typeof data === 'string')
        {
        jsonData = JSON.parse(data);
        setData(data);
        setFileError(null);

        //Check if has required fields for database
        const POST_POLICY_REQUIRED_FIELDS = ['name', 'tld_list_id', 'rules', 'scope_attributes'];
        for (let i = 0; i < POST_POLICY_REQUIRED_FIELDS.length; i++)
          {
          if (!jsonData.hasOwnProperty(POST_POLICY_REQUIRED_FIELDS[i])) {setFileError("Data does not meet database expectations. Please reexport the policy and try again."); return;}
          }
        }
      }
    else
      {
      setFileError("Please select a file");
      }
    }
  catch (e)
    {
    // Handle parsing errors
    let message = "An error has occurred. Please reexport and try again."; //Default error message
    if (e instanceof SyntaxError) message = "Data is not formatted properly. Please try again with a properly formatted file.";
    setFileError(message);
    return;
    }
  }

// Function to read text content from a file
function getTextFromFile(file: File)
  {
  return new Promise ((resolve, reject) =>
    {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {if (typeof(fileReader.result) === 'string') resolve(fileReader.result);}
    fileReader.onerror = (e) => reject(fileReader.error);
    fileReader.readAsText(file);
    });
  }

// Function to import policy from file
async function importPolicy(fileData: string | null, policyID: number, setFileError: React.Dispatch<React.SetStateAction<string | null>>)
  {
  if (fileData)
    {
    try
      {
      // Parse the file data and assign the policy ID
      let policy = JSON.parse(fileData) as APIPolicy;
      policy.id = policyID;
      // Save the policy and handle success or failure
      const status = await savePolicy(policy);
      if (status) {window.location.reload(); return;}
      setFileError('An error has occurred. Please try again later.');
      }
    catch (error)
      {
      setFileError('An error has occurred. Please try again later.');
      }
    }
  }

// Props for ImportPolicy component
interface ImportPolicyProps
  {
  policyID: number;
  open: boolean;
  closeFunc: () => void;
  }

// ImportPolicy component
export default function ImportPolicyModal(props: ImportPolicyProps)
  {
  // State to hold file data and file error
  const [fileData, setFileData] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>("Please select a file");

  return (<ReactModal isOpen={props.open} onRequestClose={props.closeFunc} appElement={document.getElementById('root') ?? undefined}>
    <CloseButton closeFunc={props.closeFunc}/>
    <input type={"file"} accept={".policy"} onChange={(e) => { checkFile(e, setFileData, setFileError) }}/>
    {fileError === null ? <p><button onClick={() => {importPolicy(fileData, props.policyID, setFileError)}}>Import</button></p> : <p style={{ textAlign: "center" }}>{fileError}</p>}
    </ReactModal>);
  }