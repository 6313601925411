import React from 'react';
import { APIRequesterGroup, NewPolicy } from './common/api';
import { /*clearPassword,*/ getAPITokenHeaders } from './password';

interface RequesterGroupListProps {
  requesterGroups: APIRequesterGroup[],
  onSelectRqG: (newRequesterID: number) => void
}

export default function RequesterGroupList(props: RequesterGroupListProps) {
  return (
    <div>
      <h2>Requester Groups</h2>
      <button
        onClick={
          () => {
            newRqG().then((id) => window.location.hash = `/requester_group/${id}`);
          }
        }>Create New Requester Group</button>
      <table style={{ width: "100%" }} className="policy-list-table">
          <tbody>
          {
              props.requesterGroups.map(rqG => (
              <tr key={rqG.id}
                  onClick={() => props.onSelectRqG(rqG.id)}>
                  <td>
                  <a href={`#/requester_group/${rqG.id}`}
                      onClick={() => props.onSelectRqG(rqG.id)}>
                      {rqG.name}
                  </a>
                  <br />
                  </td>
              </tr>
              ))
          }
          </tbody>
      </table>
    </div>
  );
}

function newRqG(): Promise<number> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/requester_group`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify({
        id: 0,
        name: "Unnamed Requester Group",
        obligations: ""
      } as APIRequesterGroup)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as NewPolicy;
      if (response.status === 'success') {
        return resolve(response.id);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}
