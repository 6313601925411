import jsPDF from "jspdf";
import autoTable, { CellHookData, CellDef, UserOptions } from "jspdf-autotable";
import { APIDictionary, APIAttributeOption, APIRequestTemplate, APIRegistration, APIRegistrantFacts } from "./common/api";
import { PolicyEditorState } from "./PolicyEditor";
import { contrastColor } from './webutils';
import { renderToStaticMarkup } from "react-dom/server"

// Colorize cells based on specific conditions
function colorizeCell(data: CellHookData, dict: APIDictionary)
  {
  if (data.section !== "body") return;
  const rawCell = data.cell.raw as HTMLTableCellElement;

  if (data.cell.text[0] === ">" || data.cell.text[0] === "X")
    {
    data.doc.setFillColor("white");
    data.doc.setTextColor("red");
    data.doc.setFontSize(13);
    }
  else if (data.cell.text[0] === "<") {
    data.doc.setFillColor("white");
    data.doc.setTextColor("green");
    data.doc.setFontSize(13);
  }
  else if (data.cell.text[0] === ".") {
    data.doc.setFillColor("rgb(26, 188, 156)");
    data.doc.setTextColor("rgb(26, 188, 156)");
    data.cell.styles.lineWidth = 0;
  }
  // For legend table, join is for multi word vals
  // Find attribute value in dictionary
  else if (dict.rule_attributes.some(att => att.values.some(val => val.name === data.cell.text.join(" "))))
    {
    let attOp: APIAttributeOption = dict.rule_attributes[0].values[0];
    dict.rule_attributes.forEach(att => {const foundAtt = att.values.find(value => value.name === data.cell.text.join(" ")); attOp = foundAtt ? foundAtt : attOp;});
    data.doc.setFillColor(attOp.color);
    data.doc.setTextColor(contrastColor(attOp.color));
    }
  else
    {
    try
      {
      const cellSpan = rawCell.getElementsByClassName("attribute-value")[0];
      if (cellSpan)
        {
        const cellColor = window.getComputedStyle(cellSpan).backgroundColor;
        const textColor = window.getComputedStyle(cellSpan).color;
        data.doc.setFillColor(cellColor);
        data.doc.setTextColor(textColor);
        }
      }
    catch (error) {/* console.warn(error); */}
    }
  }

// Draw a footer with copyright and page information
function drawFooter(pdfStartPage: number, pdfAnnotation: string, data: Parameters<NonNullable<UserOptions["didDrawPage"]>>[0])
  {
  const depth = 580;
  data.doc.setFontSize(10);
  const footer = "© Edgemoor Research Institute " + new Date().getFullYear();
  data.doc.text(footer, 50, depth);
  const startPage = pdfStartPage === 0 ? 1 : pdfStartPage;
  const pageInfo = pdfAnnotation + " " + (data.doc.getNumberOfPages() + startPage - 1);
  //TODO: prevent this from being drawn twice on the same page
  data.doc.text(pageInfo, data.doc.internal.pageSize.getWidth() - data.doc.getTextWidth(pageInfo) - 42, depth);
  }

// Draw a legend based on the policy editor state and data dictionary
function drawLegend(state: Readonly<PolicyEditorState>, dataDictionary: APIDictionary, doc: jsPDF)
  {
  let legend: (string | CellDef)[][] = [[]];
  let head: CellDef[][] = [[]];

  //Add comparison symbols
  if (state.comparisonPolicy)
    {
    head[0].push({ content: "Comparison symbols", colSpan: 2, styles: { halign: 'center' } } as CellDef);
    legend[0].push({ content: ">", styles: { halign: 'center' } } as CellDef, 'Right policy is stricter than left policy');
    legend.push([{ content: "<", styles: { halign: 'center' } } as CellDef, 'Left policy is stricter than, and hense complies with right policy']);
    legend.push([{ content: "[blank]", styles: { halign: 'center' } } as CellDef, "Policies agree"]);
    legend.push([{ content: "X", styles: { halign: 'center' } } as CellDef, "Policies are different and not comparable"]);
    }

  dataDictionary.rule_attributes.forEach((att, attIdx) =>
    {
    head[0].push({ content: att.name, colSpan: 2, styles: { halign: 'center' } } as CellDef); //Extra head so doesn't get colorized
    let valIdx = 0; //Tracks what row in column we are
    if (state.comparisonPolicy) attIdx = attIdx + 1; //Move over one column if have the comparison column
    att.values.forEach((val) =>
      {
      //Make row if doesn't exist
      if (!legend[valIdx]) legend[valIdx] = [];
      //Don't show attribute values that don't have descriptions
      if (val.description)
        {
        while (attIdx !== 0 && legend[valIdx][attIdx * 2 - 1] === undefined) legend[valIdx].push(""); // Move over in the table so the entire attribute is in one column
        legend[valIdx].push({ content: val.name, styles: { halign: 'center' } } as CellDef, val.description);
        valIdx += 1;
        }
      });
    });

  legend = legend.filter(row => row.length !== 0); //Get rid of any empty rows
  doc.addPage();
  doc.text("Legend", (doc.internal.pageSize.getWidth() - doc.getTextWidth("Legend")) / 2, 40);
  autoTable(doc, {theme: "grid", startY: 90, head: head, body: legend, willDrawCell: (data) => {if (data.section !== 'head') colorizeCell(data, dataDictionary);}, didDrawPage: (data) => drawFooter(state.pdfStartPage, state.pdfAnnotation, data),});
  }

// Parse cell content from HTML table cells
function parseCellContent(orgCell: HTMLTableCellElement)
  {
  // Work on cloned node to make sure no changes are applied to html table
  const cell = orgCell.cloneNode(true) as HTMLTableCellElement;
  // Remove extra space and line breaks in markup to make it more similar to what would be shown in html
  cell.innerHTML = cell.innerHTML.replace(/\n/g, '').replace(/ +/g, ' ');
  // Preserve <br> tags as line breaks in the pdf; start with '<br' and ends with '>'.
  cell.innerHTML = cell.innerHTML.split(/<br.*?>/).map((part: string) => part.trim()).join('\n');
  // innerText for ie
  return cell.innerText || cell.textContent || '';
  }

// Add a cell to a row in the result table
function addCellToRow(resultRow: CellDef[], cell: HTMLTableCellElement | undefined, content: string)
  {
  resultRow.push({rowSpan: cell ? cell.rowSpan : 1, colSpan: cell ? cell.colSpan : 1, _element: cell, content: content,});
  }

// Parse row content from HTML table rows
function parseRowContent(row: HTMLTableRowElement, state: Readonly<PolicyEditorState>)
  {
  const resultRow: CellDef[] = [];
  for (let i = 0; i < row.cells.length; i++)
    {
    if (state.comparisonPolicy && row.cells[i].className === "notescol") continue;
    const cell = row.cells[i];
    let content = parseCellContent(cell);
    if (state.comparisonPolicy && cell.getElementsByClassName("note-container").length) continue; // Second check for notes because they are really annoying
    if (cell.colSpan > 1 && state.hiddenGroupsAttributes.some(eg => eg.name === content)) return; // Don't show anything for row if hidden
    let setSign = "";
    if (state.comparisonPolicy)
      {
      if (row.cells[i].className !== "spacercol")
        {
        for (const setSymbol of [["chevron_right", ">"], ["chevron_left", "<"], ["close", "X"]])
          {
          if (content.includes(setSymbol[0]))
            {
            content = content.replace(setSymbol[0], "");
            setSign = setSymbol[1];
            }
          }
        }
      else
        {
        addCellToRow(resultRow, cell, ".");
        continue;
        }
      }
    addCellToRow(resultRow, cell, content);
    if (state.comparisonPolicy && (row.cells[i].className === "att" || row.cells[i].className === "emptyAtt")) addCellToRow(resultRow, undefined, setSign);
    }

  const style = window.getComputedStyle(row);
  if (resultRow.length > 0 && (style.display !== 'none')) return resultRow;
  }

// Parse HTML and generate a matrix of cell definitions
function parseHtml(input: string, state: Readonly<PolicyEditorState>)
  {
  const tableElement = window.document.querySelector(input) as HTMLTableElement;
  const body: CellDef[][] = [];
  for (let i = 3; i < tableElement.rows.length; i++)
    {
    if (tableElement.rows[i].hasAttribute("th")) continue;
    const row = parseRowContent(tableElement.rows[i], state);
    if (!row) continue;
    body.push(row);
    }
  return body;
  }

// Hide attributes in the head of the table based on the state
function hideAttributesInHead(head: (string[] | CellDef[])[], state: Readonly<PolicyEditorState>)
  {
  const attributesInHead = head[head.length - 1] as string[]; //Last element in head is attributes

  //If have comparision columns, get rid of those if hidden as well - comparision column is one after attribute
  //Change length of titles to match number of shown attributes
  if (state.comparisonPolicy)
    {
    head[head.length - 1] = attributesInHead.filter((att, idx) => state.hiddenGroupsAttributes.every(hiddenAtt => hiddenAtt.name !== att && (hiddenAtt.name !== attributesInHead[idx !== 0 ? idx - 1 : idx] || att !== " ")));
    const numberAttributesHidden = (attributesInHead.length - head[head.length - 1].length) / 3; // 3 hidden columns for each attribute - 2 for policies, 1 for comparision
    const titlesInHead = head[0] as CellDef[];
    head[0][1] = { ...titlesInHead[1], colSpan: 12 - (numberAttributesHidden * 2) };
    head[0][3] = { ...titlesInHead[3], colSpan: 6 - numberAttributesHidden };
    return head;
    }

  head[head.length - 1] = attributesInHead.filter(att => state.hiddenGroupsAttributes.every(hiddenAtt => hiddenAtt.name !== att));
  return head;
  }

// Initialize a new jsPDF document
function initDoc()
  {
  const unit = "pt";
  const size = "letter";
  const orientation = "landscape"; // portrait or landscape
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(24);
  return doc;
  }

// Add request tables to the PDF document; converts a react element to a static html table for the jspdf api
function addRequestTables(doc: jsPDF, pdfStartPage: number, pdfAnnotation: string, ...tables: JSX.Element[])
  {
  autoTable(doc, {theme: 'grid', startY: (doc as any).lastAutoTable.finalY + 50, html: reactTbodiesToTable(...tables), didDrawPage: (data) => drawFooter(pdfStartPage, pdfAnnotation, data),});
  }

// Convert React table bodies to an HTML table
function reactTbodiesToTable(...tables: JSX.Element[])
  {
  const finalTable = document.createElement('table');
  const headerTable = document.createElement('table');
  headerTable.innerHTML = renderToStaticMarkup(tables[0]);
  if (headerTable.tHead) finalTable.appendChild(headerTable.tHead);
  tables.forEach(table =>
    {
    const HTMLtable = document.createElement('table');
    HTMLtable.innerHTML = renderToStaticMarkup(table);
    HTMLtable.deleteTHead();
    if (HTMLtable.tBodies[0]) finalTable.appendChild(HTMLtable.tBodies[0]);
    });
  return finalTable;
  }

// Export a request template to a PDF document; builds a PDF from a request template
export function exportRequestTemplateToPDF(requestTemplate: APIRequestTemplate, pdfStartPage: number, pdfAnnotation: string, resultTables: JSX.Element[], ...attributeTables: JSX.Element[])
  {
  if (!requestTemplate || attributeTables.some(table => !table)) throw new Error( "Tried to export a null request template as a PDF");
  const doc = initDoc();
  const title = requestTemplate.name ?? "Untitled Request Template";
  doc.text(title, (doc.internal.pageSize.getWidth() - doc.getTextWidth(title)) / 2, 50);
  autoTable(doc, {theme: 'grid', startY: 60,});
  addRequestTables(doc, pdfStartPage, pdfAnnotation, ...attributeTables);
  if (resultTables.length > 0)
    {
    doc.addPage();
    doc.text("Results", (doc.internal.pageSize.getWidth() - doc.getTextWidth("Results")) / 2, 50);
    autoTable(doc, {theme: 'grid', startY: 60,});
    addRequestTables(doc, pdfStartPage, pdfAnnotation, ...resultTables);
    }
  doc.save(title);
  }

// Capitalize a string
function capitalize(str: string)
  {
  if (str === "no dg") return "No DG"; //hard coding this because double caps
  return str.replace( /(^|\s)([a-z])/g , function(m,p1,p2){ return p1+p2.toUpperCase(); } );
  }

// Export a registration to a PDF document; builds a PDF from a registration
export function exportRegistrationToPDF(registration: APIRegistration, pdfStartPage: number, pdfAnnotation: string, registrationTable: JSX.Element, dataDictionary: APIDictionary, registrantFacts: APIRegistrantFacts | null)
  {
  if (!registrantFacts) throw new Error ("Tried to export a null facts set to a PDF");
  const doc = initDoc();
  const title = registration.name ?? "Untitled Registration";

  doc.text(title, (doc.internal.pageSize.getWidth() - doc.getTextWidth(title)) / 2, 50);

  autoTable(doc, {theme: 'grid', startY: 60,});

  const pdfTable = reactTbodiesToTable(registrationTable);

  pdfTable.tBodies[0].childNodes.forEach((row, rowIdx) =>
    {
    row.childNodes.forEach((cell) =>
      {
      if (cell.childNodes[1]?.nodeName === "SELECT")
        {
        if (cell.childNodes[1]?.textContent === "DefaultV3 Pleaseø")
          {
          const tmpNode = document.createTextNode(capitalize(registrantFacts.facts.find(rf => rf.element_id === parseInt(cell.childNodes[0].textContent??""))?.V3RQ??"Default"));
          while (cell.firstChild) cell.removeChild(cell.firstChild);
          cell.appendChild(tmpNode);
          }
        if (cell.childNodes[1]?.textContent === "No DGDG Pleaseø")
          {
          const tmpNode = document.createTextNode(capitalize(registrantFacts.facts.find(rf => rf.element_id === parseInt(cell.childNodes[0].textContent??""))?.DG??"No DG"));
          while (cell.firstChild) cell.removeChild(cell.firstChild);
          cell.appendChild(tmpNode);
          }
        }
      if (cell.childNodes[1]?.nodeName === "INPUT")
        {
        const tmpNode = document.createTextNode(registrantFacts.facts.find(rf => rf.element_id === parseInt(cell.childNodes[0].textContent??""))?.value??"");
        while (cell.firstChild) cell.removeChild(cell.firstChild);
        cell.appendChild(tmpNode);
        }
      });
    });

  autoTable(doc, {theme: 'grid', startY: 60, html: pdfTable, didParseCell: (data) => {const cellText = data.cell.text[0]; if (cellText.substring(0, cellText.length / 2) === cellText.substring(cellText.length / 2, cellText.length)) data.cell.text[0] = cellText.substring(0, cellText.length / 2); data.cell.text[0] = data.cell.text[0].replace("Choose...", "");}, willDrawCell: (data) => {colorizeCell(data, dataDictionary);}, columnStyles: {4: { cellWidth: 45 }}, didDrawPage: (data) => drawFooter(pdfStartPage, pdfAnnotation, data),});

  doc.save(title);
  }

// Export a policy to a PDF document; builds a PDF from the policy editor
export function exportPolicyToPDF(state: Readonly<PolicyEditorState>, dataDictionary: APIDictionary)
  {
  const policy = state.policy;
  if (!policy) throw Error ("Tried to export a null policy as a PDF");
  const doc = initDoc();
  const title = policy.name ?? "Untitled Policy";

  doc.text(title, (doc.internal.pageSize.getWidth() - doc.getTextWidth(title)) / 2, 40);

  autoTable(doc, {theme: 'grid', startY: 90, head: [["Organization", "PoC", "Email", "Effective Date", "Version", "Completion", "Status", "Notes"]], body: [[policy.org_name ?? "", policy.prime_poc ?? "", policy.prime_email ?? "", policy.effective_date ?? "", policy.version ?? "", policy.completion ?? "", policy.status ?? "", policy.notes ?? "",], [policy.org_type ?? "", policy.alt_poc ?? "", policy.alt_email ?? "",]],});
  doc.text("Scope", (doc.internal.pageSize.getWidth() - doc.getTextWidth("Scope")) / 2, (doc as any).lastAutoTable.finalY + 90);

  autoTable(doc, {theme: 'grid', startY: (doc as any).lastAutoTable.finalY + 125, html: ".PolicyEditor-scope", didParseCell: (data) => {const cellText = data.cell.text[0]; if (cellText.substring(0, cellText.length / 2) === cellText.substring(cellText.length / 2, cellText.length)) data.cell.text[0] = cellText.substring(0, cellText.length / 2); data.cell.text[0] = data.cell.text[0].replace("Choose...", "");}, columnStyles: {0: { cellWidth: 100 }}, willDrawCell: (data) => colorizeCell(data, dataDictionary), didDrawPage: (data) => drawFooter(state.pdfStartPage, state.pdfAnnotation, data),});

  if (state.pdfIncludeLegend) drawLegend(state, dataDictionary, doc);

  let head = state.comparisonPolicy ? [[{ content: "", colSpan: 3, styles: { halign: 'center', fillColor: [22, 160, 133] } } as CellDef, { content: title, colSpan: 12, styles: { halign: 'center', fillColor: [22, 160, 133], lineWidth: 1 } } as CellDef, { content: "", colSpan: 1, styles: { halign: 'center', fillColor: [22, 160, 133], lineWidth: 1 } } as CellDef, { content: state.comparisonPolicy?.name.replaceAll(" ∩ ", "\n"), colSpan: 6, styles: { halign: 'center', fillColor: [22, 160, 133], lineWidth: 1 } } as CellDef], ["Group", "Element", "Category", "Coll", " ", "Val", " ", "V3rq", " ", "Sens", " ", "DG", " ", "Store", " ", " ", "Coll", "Val", "V3rq", "Sens", "DG", "Store"]] : [["Group", "Element", "Category", "Coll", "Val", "V3rq", "Sens", "DG", "Store", "Notes"]];

  head = hideAttributesInHead(head, state);

  const policyTable = parseHtml(".PolicyEditor-table", state);

  autoTable(doc, {theme: 'grid', startY: 600, head: head, body: policyTable, columnStyles: state.comparisonPolicy ? {} : {9: { cellWidth: 300 }}, styles: { cellPadding: 2, halign: "center" }, didParseCell: (data) => {if (data.cell.text[0].substring(0, data.cell.text[0].length / 2) === data.cell.text[0].substring(data.cell.text[0].length / 2, data.cell.text[0].length)) data.cell.text[0] = data.cell.text[0].substring(0, data.cell.text[0].length / 2); if (data.column.index === data.table.columns.length - 1 && !state.comparisonPolicy) {const rawCell = data.cell.raw as HTMLTableCellElement; if (typeof rawCell != "string" && rawCell) {const noteVal = rawCell.getElementsByClassName("material-icons-outlined")[0]?.getAttribute("data-note-contents"); data.cell.text[0] = noteVal ? noteVal : "";}}}, willDrawCell: (data) => {if (data.section !== 'head') colorizeCell(data, dataDictionary);}, didDrawPage: (data) => drawFooter(state.pdfStartPage, state.pdfAnnotation, data),});

  doc.save(title);
  }
