import React from 'react';

// Props for the Note component
interface NoteProps
  {
  value: string | null;
  onChange?: (newValue: string) => void;
  }

// State for the Note component
interface NoteState
  {
  mouseover: boolean;
  expanded: boolean;
  }

// Note component definition
export default class Note extends React.Component<NoteProps, NoteState>
  {
  constructor(props: NoteProps)
    {
    super(props);
    this.state =
      {
      mouseover: false,
      expanded: false,
      };
    }

  render()
    {
    // behavior of mouseout is a little tricky, depending on whether there is an existing note or not.
    // If there is an existing note, then we will show the note and handle mouseout in that box.
    // Otherwise, we will handle mouseout on the icon since there isn't anything else.
    return (<div className="note-container" onMouseOver={() => this.setState({ mouseover: true })} onMouseOut={() => {if (!this.props.value) this.setState({ mouseover: false });}} style={{position: "relative"}}>
      <span className="material-icons-outlined" style={{color: this.props.value ? 'black' : 'blue', visibility: this.props.value || this.state.mouseover ? 'visible' : 'hidden', cursor: 'pointer',}} onClick={() => this.setState({ expanded: true })} title="Edit note" data-note-contents={this.props.value}>sticky_note_2</span>
      <div className="note-body" style={{display: (this.props.value && this.state.mouseover) || this.state.expanded ? 'block' : 'none', position: "absolute", top: 0, left: 0, background: "white", border: "1px solid black", boxShadow: "5px 5px 20px rgba(0, 0, 0, 0.8)", zIndex: 1000}}onClick={() => this.setState({ expanded: true })} onMouseOut={() => this.setState({ mouseover: false })}>{(this.props.value && this.state.mouseover) || this.state.expanded ? (<><textarea style={{fontFamily: "sans-serif", width: "240px", height: "240px",}} autoFocus={true} onKeyUp={e => {if (e.ctrlKey && e.key === 'Enter') this.setState({mouseover: false, expanded: false});}} onBlur={() => this.setState({ expanded: false })} onChange={(e) => this.props.onChange?.(e.target.value)}>{this.props.value}</textarea><div style={{position: "absolute", right: "4px", bottom: "4px", fontStyle: "italic", color: "gray", pointerEvents: "none"}}>Ctrl-Enter to close</div></>) : null}</div>
      </div>)
    }
  }
