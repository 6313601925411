/**
 * CloseButtonProps interface defines the expected props for the CloseButton component.
 */
interface CloseButtonProps
  {
  closeFunc: () => void
  }

/**
 * CloseButton component represents a close button used for closing a modal or dialog.
 * @param props - The props for the CloseButton component.
 */
export default function CloseButton(props: CloseButtonProps)
  {
  return (<header><button onClick={props.closeFunc} className='close-button'> X </button></header>);
  }
