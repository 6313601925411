import React from 'react';
import _ from "lodash";
import { APIRequestTemplate, APIRequestTemplateItem, APITypedAttribute } from "./api";

export function updateAttribute(requestTemplate: APIRequestTemplate, attrId: number, value: string, setRequestTemplate: React.Dispatch<React.SetStateAction<APIRequestTemplate | null>>) : void
  {
  const newRequestObject: APIRequestTemplate = _.clone(requestTemplate);
  const existingAttrIndex = newRequestObject.attributes.findIndex(a => a.attribute_id === attrId);

  newRequestObject.attributes = newRequestObject.attributes.slice();

  if (existingAttrIndex !== -1)
    {
    const newAttr = Object.assign({}, requestTemplate.attributes[existingAttrIndex], { value: value });
    newRequestObject.attributes[existingAttrIndex] = newAttr;
    }
  else
    {
    const newItem: APIRequestTemplateItem = {attribute_id: attrId, value: value,};
    newRequestObject.attributes.push(newItem);
    }

  setRequestTemplate(newRequestObject);
  }

export function textDisplay(requestTemplate: APIRequestTemplate, type: string, request_attributes: APITypedAttribute[]) : (JSX.Element | undefined)[]
  {
  return request_attributes.map(attr =>
    {
    const requestAttr = requestTemplate.attributes.filter(a => a.attribute_id === attr.attribute.id)[0];
    return ((attr.type === type) ? <tr key={attr.attribute.id} className="uneditable-request-field"><td>{attr.attribute.name}</td><td><input id={String(attr.attribute.id)} type="text" value={requestAttr?.value || ""} readOnly /></td></tr> : undefined);
    });
  }

//Steve requested that these be spaced out in the request form
//TODO: make this a field of all request attributes in the datadictionary

export function dropDownDisplay(requestTemplate: APIRequestTemplate, type: string, request_attributes: APITypedAttribute[]) : (JSX.Element | undefined)[]
  {
  let spaced_rows = ["Search_name", "Processing", "Exigent_OK", "DNS", "Sensitivity", "Logging"];
  return (request_attributes.map(attr =>
    {
    const requestAttr = requestTemplate.attributes.filter(a => a.attribute_id === attr.attribute.id)[0];
    return (attr.type === type) ? ((spaced_rows.includes(attr.attribute.name)) ? <><tr><td>&nbsp;</td><td></td></tr><tr className="uneditable-request-field"><td>{attr.attribute.name}</td><td><select value={requestAttr?.value || "-1"}><option value="-1"></option> {attr.attribute.values?.map((a) => {return <option value={a.value} disabled={requestAttr?.value !== a.value.toString()}>{a.name}</option>})}</select></td></tr></> : <tr className="uneditable-request-field"><td>{attr.attribute.name}</td><td><select value={requestAttr?.value || "-1"}><option value="-1"></option>{attr.attribute.values?.map((a) => {return <option value={a.value} disabled={requestAttr?.value !== a.value.toString()}>{a.name}</option>})}</select></td></tr>) : undefined;
    }));
  }

export function selectedInput(requestTemplate: APIRequestTemplate, type: string, request_attributes: APITypedAttribute[]) : (JSX.Element | undefined)[]
  {
  return request_attributes.map(attr =>
    {
    const requestAttr = requestTemplate.attributes.filter(a => a.attribute_id === attr.attribute.id)[0];
    return (attr.type === type) ? <tr className="editable-request-field"><td>{attr.attribute.name}</td><td>{type === "rule" ? attr.attribute.values?.find((a) => a.value === parseInt(requestAttr?.value))?.name : requestTemplate.attributes.filter(a => a.attribute_id === attr.attribute.id)[0]?.value || ""}</td></tr> : undefined;
    });
  }

export const requestSubTable = (f: Function) : JSX.Element => (<table><thead><tr><th>Attribute</th><th>Value</th></tr></thead><tbody>{f()}</tbody></table>);
