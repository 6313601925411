//import _ from "lodash";
import React from "react";
import { APIRequesterGroup, APIRequesterGroupMembership } from "./common/api";
import LoadingSpinner from "./LoadingSpinner";
import { getRegistrarGroupMembers } from "./RegistrarGroupMembersList";

interface RqGListSelectorProps {
  reqID: number | null;
  reqRequesterGroupsAlreadyJoined: APIRequesterGroupMembership[] | null;
  joiningOrLeavingRqG: "joining" | "leaving";
  onSelect: (newRqG: APIRequesterGroup) => void;
}

interface RqGListSelectorState {
  requesterGroups: APIRequesterGroup[] | null;
}

export default class RqGListSelector extends React.Component<RqGListSelectorProps, RqGListSelectorState> {
  constructor(props: RqGListSelectorProps) {
    super(props);
    this.state = {
      requesterGroups: null,
    };
    getRegistrarGroupMembers().then(registrarGroupMembers => this.setState({
      requesterGroups: registrarGroupMembers.requester_groups
    }));
  }

  render() {

    return (
      <div className="TLDListSelector">
        <h2>Choose a requester group to {this.props.joiningOrLeavingRqG==="joining" ? "join" : "leave"}</h2>
        {
          this.state.requesterGroups !== null ?
            (
              <table className="TLDListSelector-table">
                <tbody>
                  {this.props.joiningOrLeavingRqG === "joining" ?
                    this.state.requesterGroups.map(rqG =>
                      this.props.reqRequesterGroupsAlreadyJoined?.some(rqGJoined =>
                        rqGJoined.requester_group_id === rqG.id) ? null : ( //Show accrediting authorities we have not joined on joining
                      <tr tabIndex={0}
                        onClick={() => {
                          this.props.onSelect(rqG);
                          window.location.reload();
                        }}
                      >
                        <td>{rqG.name}</td>
                      </tr>
                    )) : this.state.requesterGroups.map(rqG =>
                      this.props.reqRequesterGroupsAlreadyJoined?.some(rqGJoined =>
                        rqGJoined.requester_group_id === rqG.id) ? ( //Show accrediting authorities we have joined on leaving
                      <tr tabIndex={0}
                        onClick={() => {
                          this.props.onSelect(rqG);
                          window.location.reload();
                        }}
                      >
                        <td>{rqG.name}</td>
                      </tr>
                      ) : null)}
                </tbody>
              </table>
            )
            :
            <LoadingSpinner />
        }
      </div>
    );
  }
}
