import _ from "lodash";
import React from "react";
import ReactModal from 'react-modal';
import { APIRequesterGroup, APISuccess, APIError, APIRegistrarGroup, APIRegistrar, APIRequestTemplate } from "./common/api";
import LoadingSpinner from "./LoadingSpinner";
import navHeader from "./NavHeader";
import { checkUnsavedBeforeUnload } from "./common/util";
import { getRegistrarGroupMembers } from "./RegistrarGroupMembersList";
import { getAPITokenHeaders /*, clearPassword*/ } from "./password";
import { getAllRqGs } from "./RequesterEditor";
import { getRequestTemplates } from "./RequestTemplateList";
import RrGListSelector from "./RegistrarGroupMemberEditor";

interface RegistrarGroupEditorProps {
  selectedRegistrarGroupID: number | null;
}

export default function RegistrarGroupEditor(props: RegistrarGroupEditorProps){
  const [isLoadingRegistrarGroup, setIsLoadingRegistrarGroup] = React.useState<boolean>(false);
  const [registrarGroup, setRegistrarGroup] = React.useState<APIRegistrarGroup | null>(null);
  const [isLoadingAllRqGs, setIsLoadingAllRqGs] = React.useState<boolean>(false);
  const [allRqGs, setAllRqGs] = React.useState<APIRequesterGroup[] | null>(null);
  const [isLoadingAllRrs, setIsLoadingAllRrs] = React.useState<boolean>(false);
  const [allRrs, setAllRrs] = React.useState<APIRegistrar[] | null>(null);
  const [isLoadingAllReqTemps, setIsLoadingAllReqTemps] = React.useState<boolean>(false);
  const [allReqTemps, setAllReqTemps] = React.useState<APIRequestTemplate[] | null>(null);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [isSelectingRrGMembers, setIsSelectingRrGMembers] = React.useState<boolean>(false);
  const [isAddingToRrG, setIsAddingToRrG] = React.useState<boolean | null>(null);

  if (isLoadingRegistrarGroup) {
    return (<LoadingSpinner />);
  }

  if (!registrarGroup || (
    registrarGroup.id !== props.selectedRegistrarGroupID && registrarGroup.id !== 0 && props.selectedRegistrarGroupID !== null
  )) {
    if (props.selectedRegistrarGroupID !== null) {
      console.log("Initiating load of registrar group information");
      setIsLoadingRegistrarGroup(true);
      // TODO: handle error case (shouldn't endlessly retry)
      getRegistrarGroup(props.selectedRegistrarGroupID).then(setRegistrarGroup).finally(() => {
          setIsLoadingRegistrarGroup(false);
      });
    }

    return null;
  }

  if (!allRqGs && !isLoadingAllRqGs) {
    setIsLoadingAllRqGs(true);
    getAllRqGs().then(setAllRqGs).finally(() => setIsLoadingAllRqGs(false));
  }

  if (!allRrs && !isLoadingAllRrs) {
    setIsLoadingAllRrs(true);
    getRegistrarGroupMembers().then(allMembers => setAllRrs(allMembers.registrars)).finally(() => setIsLoadingAllRrs(false));
  }

  if (!allReqTemps && !isLoadingAllReqTemps) {
    setIsLoadingAllReqTemps(true);
    getRequestTemplates().then(setAllReqTemps).finally(() => setIsLoadingAllReqTemps(false));
  }

  checkUnsavedBeforeUnload()(() => isDirty);

  return registrarGroup ? (
    <div> {navHeader()}
      <input type="text" value={registrarGroup.description || ""}
          onChange={(e) => {
            const newValue = e.target.value;
            const newRequestObject: APIRegistrarGroup = _.clone(registrarGroup);
            newRequestObject.description = newValue;
            setRegistrarGroup(newRequestObject);
            setIsDirty(true);
          }}
        />
      <button
        disabled={!isDirty}
        onClick={() => {
          saveRegistrarGroup(registrarGroup);
          setIsDirty(false);
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>save</span>
        Save
      </button>
      <button onClick={() => {
        if (window.confirm('Are you sure you wish to delete this item?')) {
          deleteRegistrarGroup(registrarGroup.id).then(() =>
          window.location.hash = '/registrar_group_list/');
        }
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>delete</span>
        Delete Registrar Group
      </button>
      <br></br>

      <button onClick={() => {
        setIsSelectingRrGMembers(true);
        setIsAddingToRrG(true);
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>group</span>
        Add Registrar Group Members
      </button>
      <button onClick={() => {
        setIsSelectingRrGMembers(true);
        setIsAddingToRrG(false);
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>group_remove</span>
        Remove Registrar Group Members
      </button>
      <ReactModal
        isOpen={isSelectingRrGMembers && isAddingToRrG !== null}
        onRequestClose={() => {
          setIsSelectingRrGMembers(false);
          setIsAddingToRrG(null);
        }}
        ariaHideApp={false}
      >
      <RrGListSelector
        rrGID={registrarGroup.id}
        membersAlreadyJoined={registrarGroup}
        addingOrRemoving= {isAddingToRrG ? "adding" : "removing"}
        onSubmit={(newRrG) => {
          isAddingToRrG ? addMembers(newRrG, registrarGroup) : removeMembers(newRrG);
          setIsSelectingRrGMembers(false);
          setIsAddingToRrG(null);
        }}
      />
      </ReactModal>

      <h2>Obligations document:</h2>
      <textarea cols={75} rows={5} name="obligationsDoc" value={registrarGroup.obligations || ""}
        onChange={(e) => {
          const newObligations = e.target.value;
          const newRrGObject: APIRegistrarGroup = _.clone(registrarGroup);
          newRrGObject.obligations = newObligations;
          setRegistrarGroup(newRrGObject);
          setIsDirty(true);
        }} />

      <h2>Registrar Group Members</h2>
      <table>
        <table style={{width: "33%", float: "left"}}>
          <thead>
            <th>Requester Groups</th>
          </thead>
          <tbody>
            {registrarGroup.requester_group_ids.map(rqGID =>
              <tr>
                <td>{allRqGs?.filter(rqG => rqG.id === rqGID)[0].name}</td>
              </tr>
            )}
          </tbody>
        </table>
        <table style={{width: "33%", float: "left"}}>
          <thead>
            <th>Registrars</th>
          </thead>
          <tbody>
            {registrarGroup.registrar_ids.map(rrID =>
              <tr>
                <td>{allRrs?.filter(rr => rr.id === rrID)[0].name}</td>
              </tr>
            )}
          </tbody>
        </table>
        <table style={{width: "33%", float: "left"}}>
          <thead>
            <th>Request Templates</th>
          </thead>
          <tbody>
            {registrarGroup.request_template_ids.map(reqTempID =>
              <tr>
                <td>{allReqTemps?.filter(reqTemp => reqTemp.id === reqTempID)[0].name}</td>
              </tr>
            )}
          </tbody>
        </table>
      </table>
    </div>
  ) : null
}

async function getRegistrarGroup(registrarGroupID: number): Promise<APIRegistrarGroup> {
  return (await getRegistrarGroupMembers()).registrar_groups.filter(rrG => rrG.id === registrarGroupID)[0];
}

async function addMembers(newMembers: APIRegistrarGroup, currentMembers: APIRegistrarGroup): Promise<boolean> {
  const rqGsAdded = newMembers.requester_group_ids.map((newRqGID) =>
    joinRegistrarGroup("rqG", newRqGID, currentMembers.id, currentMembers)
  );

  const rrsAdded = newMembers.registrar_ids.map((newRrID) =>
    joinRegistrarGroup("rr", newRrID, currentMembers.id, currentMembers)
  );

  const reqTempsAdded = newMembers.request_template_ids.map((newReqTempID) =>
    joinRegistrarGroup("reqTemp", newReqTempID, currentMembers.id, currentMembers)
  );

  const allAdded = rqGsAdded.concat(rrsAdded).concat(reqTempsAdded);
  return new Promise((resolve, reject) => {
    if (allAdded.some(async result => (await result) === false)) {
      reject(false);
    } else {
      resolve(true);
    }
  });
}

async function joinRegistrarGroup(memberType: "rqG" | "rr" | "reqTemp", memberID: number, rrGID: number, currentMembers: APIRegistrarGroup): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/registrar_group_membership/${rrGID}`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify({
        memberType: memberType,
        memberID: memberID,
        currentMembers: currentMembers
      }),
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

async function removeMembers(membersToRemove: APIRegistrarGroup): Promise<boolean> {
  const rqGsRemoved = membersToRemove.requester_group_ids.map((rqGID) =>
    removeFromRegistrarGroup("rqG", rqGID, membersToRemove.id)
  );

  const rrsRemoved = membersToRemove.registrar_ids.map((rrID) =>
    removeFromRegistrarGroup("rr", rrID, membersToRemove.id)
  );

  const reqTempsRemoved = membersToRemove.request_template_ids.map((reqTempID) =>
    removeFromRegistrarGroup("reqTemp", reqTempID, membersToRemove.id)
  );

  const allRemoved = rqGsRemoved.concat(rrsRemoved).concat(reqTempsRemoved);
  return new Promise((resolve, reject) => {
    if (allRemoved.some(async result => (await result) === false)) {
      reject(false);
    } else {
      resolve(true);
    }
  });
}

async function removeFromRegistrarGroup(memberType: "rqG" | "rr" | "reqTemp", memberID: number, rrGID: number): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/registrar_group_membership/${rrGID}`, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify({
        memberType: memberType,
        memberID: memberID,
      })
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

function saveRegistrarGroup(registrarGroup: APIRegistrarGroup): Promise<boolean>{
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/registrar_group/${registrarGroup.id}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(registrarGroup)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

async function deleteRegistrarGroup(registrarGroupID: Number): Promise<boolean> {
  const rawResponse = await fetch(`/api/registrar_group/${registrarGroupID}`,
    {
      method: 'DELETE',
      headers: getAPITokenHeaders()
    });
  const response = await rawResponse.json() as unknown as APISuccess | APIError;
  if (response.status === 'success') {
    return true;
  } else {
    throw new Error(response.error);
  }
}
