//import _ from "lodash";
import React from "react";
import ReactModal from "react-modal";
import { APIRegistrar, APISuccess, APIError } from "./common/api";
import LoadingSpinner from "./LoadingSpinner";
import navHeader from "./NavHeader";
//import { checkUnsavedBeforeUnload } from "./common/util";
import { getRegistrarGroupMembers } from "./RegistrarGroupMembersList";
import { getAPITokenHeaders } from "./password";
import RegistrarGroupCreator from "./RegistrarGroupCreator";
import RegistrarGroupTable from "./RegistrarGroupTable";

interface RegistrarEditorProps {
  selectedRegID: number | null;
}

export default function RegistrarEditor(props: RegistrarEditorProps){
  const [isLoadingRegistrar, setIsLoadingRegistrar] = React.useState<boolean>(false);
  const [registrar, setRegistrar] = React.useState<APIRegistrar | null>(null);
  //const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [isMakingRrG, setIsMakingRrG] = React.useState<boolean>(false);

  if (isLoadingRegistrar) {
    return (<LoadingSpinner />);
  }

  if (!registrar || (
    registrar.id !== props.selectedRegID && registrar.id !== 0 && props.selectedRegID !== null
  )) {

    if (props.selectedRegID) {
      console.log("Initiating load of registrar information");
      setIsLoadingRegistrar(true);
      // TODO: handle error case (shouldn't endlessly retry)
      getRegistrar(props.selectedRegID).then(setRegistrar).finally(() => {
          setIsLoadingRegistrar(false);
      });
    }
    if (registrar) {
        saveNewRegistrar(registrar);
    }
    return null;
  }

  //checkUnsavedBeforeUnload()(() => isDirty);

  return registrar ? (
    <div> {navHeader()}
      <h2>{registrar.name || ""} </h2>
      <button onClick={() => {
        setIsMakingRrG(true);
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>article</span>
        Create Registrar Group
      </button>
      <ReactModal
        isOpen={isMakingRrG}
        onRequestClose={() => {
          setIsMakingRrG(false)
        }}
        ariaHideApp={false}
      >
      <RegistrarGroupCreator
        currentObjID={registrar.id}
        currentObjType="Rr"
      />
      </ReactModal>

      <RegistrarGroupTable
       currentObjID={registrar.id}
       currentObjType="Rr"
       />
    </div>
  ) : null
}

async function getRegistrar(regID: number): Promise<APIRegistrar> {
  return (await getRegistrarGroupMembers()).registrars.filter(reg => reg.id === regID)[0];
}

function saveNewRegistrar(registrar: APIRegistrar): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/registrar`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(registrar)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

// function saveRegistrar(registrar: APIRegistrar): Promise<boolean>{
//   return new Promise((resolve, reject) => {
//     const headers = getAPITokenHeaders();
//     headers.set('Content-Type', 'application/json');
//     fetch(`/api/registrar/${registrar.id}`, {
//       method: 'POST',
//       headers: headers,
//       body: JSON.stringify(registrar)
//     }).then(async rawResponse => {
//       const response = await rawResponse.json() as unknown as APISuccess | APIError;
//       if (response.status === 'success') {
//         return resolve(true);
//       } else {
//         return reject(false);
//       }
//     }).catch(error => reject(error));
//   });
// }

// async function deleteRegistrar(regID: Number): Promise<boolean> {
//   const rawResponse = await fetch(`/api/registrar/${regID}`,
//     {
//       method: 'DELETE',
//       headers: getAPITokenHeaders()
//     });
//   const response = await rawResponse.json() as unknown as APISuccess | APIError;
//   if (response.status === 'success') {
//     return true;
//   } else {
//     throw new Error(response.error);
//   }
// }
