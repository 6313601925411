
// Functional component for the navigation header
function NavHeader()
  {
  // Navigation items with associated labels and hashes
  const navItems =
    [
    { label: 'Policies', hash: '' },
    { label: 'Registrar Groups', hash: '/registrar_group_list/' },
    { label: 'Request Templates', hash: '/request_template_list/' },
    { label: 'Registrations', hash: '/registration_list/' },
    ];

  // Handle button click event by updating the window location hash
  const handleButtonClick = (hash: string) => {window.location.hash = hash;};

  // Render the navigation header with buttons for each navigation item
  return (<div className="toolbar" style={{ marginBottom: '1em' }}>{navItems.map((item, index) => (<button key={index} type="button" onClick={() => handleButtonClick(item.hash)}><span className="material-icons-outlined" aria-hidden={true}>exit_to_app</span>{item.label}</button>))}</div>);
  }

export default NavHeader;
