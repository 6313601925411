
// Data representing the legend items
const legendData =
  [
  {letter: "a", description: "Affirmed", notes: "Form is complete. Client has affirmed or reaffirmed." },
  {letter: "b", description: "Needs reaffirmation", notes: "One of us has modified a completed form." },
  { letter: "c", description: "Complete", notes: "Needs client affirmation." },
  {letter: "d", description: "Draft" },
  {letter: "p", description: "Missing data but no red", notes: "Form is incomplete but there aren’t any red splotches." },
  {letter: "q", description: "Missing data with red", notes: "Form has obvious inconsistencies or incompleteness." },
  {letter: "x", description: "Obsolete", notes: "This should be deleted from the system." },
  {letter: "z", description: "Fictional", notes: "This policy is our own invention and does not apply to a real organization. It might be based on a real organization, but it’s not intended to be attributed. This prefix can be combined with another letter." }
  ];

// Functional component representing a legend table
function LegendComponent()
  {
  return (<div className="policy-legend"><table><caption> Table 1: Policy Legend </caption><tbody>{legendData.map((item) => (<tr title={item.notes} key={item.letter}><td><div><span className="bold">{item.letter}</span></div></td><td><div><span className="bold">{item.description}</span></div></td></tr>))}</tbody></table></div>);
  }

export default LegendComponent;
