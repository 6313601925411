import _ from 'lodash';
import React from 'react';
import { APIDictionary, APIRequestTemplate, GetRequestTemplate } from './common/api';
import LoadingSpinner from './LoadingSpinner';
import { getAPITokenHeaders } from './password';
import { APISuccess, APIError } from './common/api';
import navHeader from './NavHeader';
import { exportRequestTemplateToPDF } from './PDfDownload';
import { requestSubTable, selectedInput, textDisplay, updateAttribute } from './common/requestUtils';
import { checkUnsavedBeforeUnload } from './common/util';

interface RequestTemplateEditorProps
  {
  selectedRequestTemplateID: number | null;
  dataDictionary: APIDictionary;
  }

export default function RequestTemplateEditor(props: RequestTemplateEditorProps)
  {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [requestTemplate, setRequestTemplate] = React.useState<APIRequestTemplate | null>(null);
  const [ pdfStartPage, setPdfStartPage ] = React.useState<number | null>(null);
  const [ pdfAnnotation, setPdfAnnotation ] = React.useState<string | null>(null);
  const [ isDirty, setIsDirty ] = React.useState<boolean>(false);

  if (isLoading) return (<LoadingSpinner />);

  if (!requestTemplate || (requestTemplate.id !== props.selectedRequestTemplateID && requestTemplate.id !== 0 && props.selectedRequestTemplateID !== null)) {
    if (props.selectedRequestTemplateID)
      {
      setIsLoading(true);
      // TODO: handle error case (shouldn't endlessly retry)
      getRequestTemplate(props.selectedRequestTemplateID).then(setRequestTemplate).finally(() => setIsLoading(false));
      }
    else
      {
      setRequestTemplate({id: props.selectedRequestTemplateID || 0, name: "Untitled Request Template", attributes: []});
      if (requestTemplate) saveNewRequestTemplate(requestTemplate);
      }
    return null;
    }

  checkUnsavedBeforeUnload()(() => isDirty);

  return requestTemplate ? (
    <div> {navHeader()}

      <div className="RequestTemplateEditor">
        <input id="requestTemplateName" type="text" value={requestTemplate?.name || ""} onChange={(e) => { const newValue = e.target.value; const newRequestObject: APIRequestTemplate = _.clone(requestTemplate); newRequestObject.name = newValue; setRequestTemplate(newRequestObject); setIsDirty(true);}}/>
        <button disabled={!isDirty} onClick={() => {saveRequestTemplate(requestTemplate); setIsDirty(false);}}><span className="material-icons-outlined" aria-hidden={true}>save</span>Save</button>
        <button onClick={() => {if (window.confirm('Are you sure you wish to delete this item?')) {deleteRequest(requestTemplate.id).then(() => window.location.hash = '/request_template_list/');}}}><span className="material-icons-outlined" aria-hidden={true}>delete</span>Delete Request Template</button>
        <button onClick={() => {window.location.hash = "/request/" + props.selectedRequestTemplateID;}}><span className="material-icons-outlined" aria-hidden={true}>exit_to_app</span>Go to Execution</button>
        <div></div>
        <label htmlFor="starting_page">Starting Page Number:</label>
        <input id="starting_page" type="number" className="wrapper-pdf-meta" onChange={(e) => {setPdfStartPage(parseInt(e.target.value)); }}/>
        <label className="wrapper-pdf-meta-label" htmlFor="annotation">Annotation:</label>
        <input id="annotation" type="string" className="wrapper-pdf-meta" onChange={(e) => {setPdfAnnotation(e.target.value); }}/>
        <button onClick={() => {exportRequestTemplateToPDF(requestTemplate, pdfStartPage??0, pdfAnnotation??"", [],
            requestSubTable(() => selectedInput(requestTemplate, "fill", props.dataDictionary.request_attributes)),
            requestSubTable(() => selectedInput(requestTemplate, "wrapper", props.dataDictionary.request_attributes)),
            requestSubTable(() => selectedInput(requestTemplate, "header", props.dataDictionary.request_attributes)),
            requestSubTable(() => selectedInput(requestTemplate, "rule", props.dataDictionary.request_attributes))
          );}}><span className="material-icons-outlined" aria-hidden={true}>download</span>Download as PDF</button>
        <div style={{ padding: 10 }}></div>
        <table cellPadding={100} className="noBorder"><tbody>
          <tr><td>{requestSubTable(() => textDisplay(requestTemplate, "fill", props.dataDictionary.request_attributes))}<div style={{ padding: 10 }}></div>{requestSubTable(() => textInput(requestTemplate, "wrapper"))}</td><td><div style={{ padding: 10 }}></div>{requestSubTable(() => textInput(requestTemplate, "header"))}<div style={{ padding: 10 }}></div>{requestSubTable(() => dropDownInput(requestTemplate, "rule"))}</td></tr>
          </tbody></table>
      </div>
    </div>
  ) : null;

  function dropDownInput(requestTemplate: APIRequestTemplate, type: string)
    {
    //Steve requested that these be spaced out in the request form
    //TODO: make this a field of all request attributes in the datadictionary
    let spaced_rows = ["Search_name", "Processing", "Exigent_OK", "DNS", "Sensitivity", "Logging"];
    //if a request attribute is in the spaced_rows array, add space above it
    return props.dataDictionary.request_attributes.map((attr, index) =>
      {
      const requestAttr = requestTemplate.attributes.filter(a => a.attribute_id === attr.attribute.id)[0];
      if (attr.type === type)
        {
        if (spaced_rows.includes(attr.attribute.name))
          {
          return (<><tr key={`spacer_${index}`}><td>&nbsp;</td><td></td></tr>
            <tr key={`editable_${index}`} className="editable-request-field"><td>{attr.attribute.name}</td><td><select id={`${attr.attribute.id}`} value={requestAttr?.value || "-1"} onChange={e => {updateAttribute(requestTemplate, attr.attribute.id, e.target.value, setRequestTemplate);}}><option key={attr.attribute.id} value="-1"></option>{attr.attribute.values?.map((a) => {return <option key={a.name} value={a.value}>{a.name}</option>})}</select></td></tr></>);
          }
        else
          {
          return (<tr key={`editable_${index}`} className="editable-request-field"><td>{attr.attribute.name}</td><td><select id={`${attr.attribute.id}`} value={requestAttr?.value || "-1"} onChange={e => {updateAttribute(requestTemplate, attr.attribute.id, e.target.value, setRequestTemplate); setIsDirty(true);}}><option value="-1"></option> {attr.attribute.values?.map((a) => {return <option key={a.name} value={a.value}>{a.name}</option>})}</select></td></tr>);
          }
        }
    return null;
      });
    }

  function textInput(requestTemplate: APIRequestTemplate, type: string)
    {
    return props.dataDictionary.request_attributes.map((attr, index) => {
      const requestAttr = requestTemplate.attributes.filter(a => a.attribute_id === attr.attribute.id)[0];
      if (attr.type === type)
        {
        return (<tr key={`editable_${index}`} className="editable-request-field"><td>{attr.attribute.name}</td><td><input id={`${attr.attribute.id}`} type="text" value={requestAttr?.value || ""} onChange={(e) => {updateAttribute(requestTemplate, attr.attribute.id, e.target.value, setRequestTemplate); setIsDirty(true); }}/></td></tr>);
        }
    return null;})
    }
}

function getRequestTemplate(requestID: number): Promise<APIRequestTemplate> {
  return new Promise((resolve, reject) => {
    fetch(`/api/request_template/${requestID}`, {
      headers: getAPITokenHeaders()
    })
      .then(async rawResponse => {
        const response = await rawResponse.json() as unknown as GetRequestTemplate;
        if (response.status === 'success') {
          return resolve(response.request_template);
        } else {
          return reject(response.error);
        }
      })
      .catch(error => reject(error));
  });
}

function saveRequestTemplate(requestTemplate: APIRequestTemplate): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/request_template/${requestTemplate.id}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestTemplate)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

function saveNewRequestTemplate(requestTemplate: APIRequestTemplate): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/request_template_new`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestTemplate)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

async function deleteRequest(rfid: number): Promise<boolean> {
  const rawResponse = await fetch(`/api/request_template/${rfid}`,
    {
      method: 'DELETE',
      headers: getAPITokenHeaders()
    });
  const response = await rawResponse.json() as unknown as { status: "success" } | APIError;
  if (response.status === 'success') {
    return true;
  } else {
    throw new Error(response.error);
  }
}
