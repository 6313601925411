import _ from 'lodash';
import React from 'react';
import { APIDictionary, APIRequestTemplate, APIRequestTemplateItem, GetRequestTemplate, GetMatchedRegistrations, APIRegistration, APITypedAttribute, APIMatchedRegistration, /*NamedItem, PolicyListItem,*/ APIPolicy, APIRegistrantFactItem, APIRule, APIRegistrantFacts } from './common/api';
import LoadingSpinner from './LoadingSpinner';
import navHeader from './NavHeader';
import { getAPITokenHeaders } from './password';
import { getPolicy } from './PolicyEditor';
import { getRegistrantFacts } from './RegistrantFactsEditor';
import { getAttributeFromRule, getRuleForElement } from './common/util';
import { dropDownDisplay, requestSubTable, selectedInput, textDisplay, updateAttribute } from './common/requestUtils';
import { exportRequestTemplateToPDF } from './PDfDownload';
import CollapsibleBox from './CollapsibleBox';

interface RequestProps {
  selectedRequestTemplateID: number | null;
  dataDictionary: APIDictionary;
}

export default function Request(props: RequestProps) {
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false);
  const [ requestTemplate, setRequestTemplate ] = React.useState<APIRequestTemplate | null>(null);
  const [ pdfStartPage, setPdfStartPage ] = React.useState<number | null>(null);
  const [ pdfAnnotation, setPdfAnnotation ] = React.useState<string | null>(null);
  const [ registrations, setRegistrations ] = React.useState<APIMatchedRegistration[] | null>(null);
  const [ registrantFactsList, setRegistrantFactsList ] = React.useState<APIRegistrantFacts[] | null>(null);
  const [ policyList, setPolicyList ] = React.useState<APIPolicy[] | null>(null);

  const searchFields = new Map<string, number[]>([['domain', [1]], ['contact', [27, 42, 56, 70]], ['email', [24, 38, 53, 67]]]); //TODO move these to datadict

  React.useEffect(() => {
    Promise.all(registrations?.map(reg => getPolicy(reg.registration.policy_id)) ?? []).then(setPolicyList);
    Promise.all(registrations?.map(reg => getRegistrantFacts(reg.registration.registrant_facts_id)) ?? []).then(setRegistrantFactsList);
  }, [registrations]);

  if (isLoading) {
    return (<LoadingSpinner/>);
  }

  if (!requestTemplate || (
        requestTemplate.id !== props.selectedRequestTemplateID && requestTemplate.id !== 0 && props.selectedRequestTemplateID !== null
    )) {
    if (props.selectedRequestTemplateID) {
      console.log("Initiating load of request templates");
      setIsLoading(true);
      // TODO: handle error case (shouldn't endlessly retry)
      getRequestTemplate(props.selectedRequestTemplateID).then(setRequestTemplate).finally(() => setIsLoading(false));
    } else {
      console.log("Setting request template to empty", requestTemplate);
      setRequestTemplate({
        id: props.selectedRequestTemplateID || 0,
        name: "Untitled Request Template",
        attributes: []
      });

    }
    return null;
  }

  return (
    <div> {navHeader()}
    <div className="Request">
      <input type="text" value={requestTemplate?.name || ""}
        onChange={(e) => {
          const newValue = e.target.value;
          const newRequestObject: APIRequestTemplate = _.clone(requestTemplate);
          newRequestObject.name = newValue;
          setRequestTemplate(newRequestObject);
        }}
      readOnly/>
    <button onClick={() => {
        setRegistrations([]);
        const searchFields = [34, 35, 36]; //TODO move these to datadict
        const searchVals = searchFields.map(field =>
            requestTemplate.attributes.filter(att => att.attribute_id === field).at(0)?.value ?? ""
        );
        getRegistrations(searchVals[0], searchVals[1], searchVals[2]).then(setRegistrations);
    }}>
      <span className="material-icons-outlined" aria-hidden={true}>search</span>
      Execute Request
    </button>
    <button onClick={() => {
      window.location.hash = "/request_template/"+props.selectedRequestTemplateID;
    }}>
      <span className="material-icons-outlined" aria-hidden={true}>exit_to_app</span>
      Go to Template
    </button>
    <div></div>
    <label htmlFor="starting_page">Starting Page Number:</label>
    <input
      name="starting_page"
      type="number"
      className="wrapper-pdf-meta"
      onChange={(e) => {
        setPdfStartPage(parseInt(e.target.value));
      }
    }/>
    <label className="wrapper-pdf-meta-label" htmlFor="annotation">Annotation:</label>
    <input
      name="annotation"
      type="string"
      className="wrapper-pdf-meta"
      onChange={(e) => {
        setPdfAnnotation(e.target.value);
      }
    }/>
    <button onClick={() => {
        exportRequestTemplateToPDF(
            requestTemplate,
            pdfStartPage??0,
            pdfAnnotation??"",
            getResultsTableList(requestTemplate, (x: JSX.Element) => x)?.map(table => table ?? (<></>)) ?? [],
            requestSubTable(() => selectedInput(requestTemplate, "fill", props.dataDictionary.request_attributes)),
            requestSubTable(() => selectedInput(requestTemplate, "wrapper", props.dataDictionary.request_attributes)),
            requestSubTable(() => selectedInput(requestTemplate, "header", props.dataDictionary.request_attributes)),
            requestSubTable(() => selectedInput(requestTemplate, "rule", props.dataDictionary.request_attributes)),
        );
    }}>
        <span className="material-icons-outlined" aria-hidden={true}>download</span>
        Download as PDF
    </button>
    <div style={{padding: 10}}></div>
    <table cellPadding={100} className="noBorder">
        <tbody>
            <tr>
                <td>
                    {requestSubTable(() => textInput(requestTemplate, "fill"))}
                    <div style={{padding: 10}}></div>
                    {requestSubTable(() => textDisplay(requestTemplate, "wrapper", props.dataDictionary.request_attributes))}
                </td>
                <td>
                <div style={{padding: 10}}></div>
                    {requestSubTable(() => textDisplay(requestTemplate, "header", props.dataDictionary.request_attributes))}
                    <div style={{padding: 10}}></div>
                    {requestSubTable(() => dropDownDisplay(requestTemplate, "rule", props.dataDictionary.request_attributes))}
                </td>
            </tr>
        </tbody>
    </table>
    <table>
        <thead>
            <th>Results ({registrations?.length ?? 0})</th>
        </thead>
    </table>
        {
            getResultsTableList(requestTemplate, (x: JSX.Element) => <div>{x} <br /></div>)
        }
    </div>
    </div>
  );

  function getResultsTableList(requestTemplate: APIRequestTemplate, wrapperElement: (x: JSX.Element) => any) {
    return (registrations == null || registrantFactsList == null || policyList == null) ?
      null :
      registrations.map((reg, i) => {
        const regFact = registrantFactsList.at(i);
        const policy = policyList.at(i);
        if (!regFact || !policy) { return null; }
        console.log(wrapperElement(getResultsTable(requestTemplate, regFact, policy, reg.registration, new Set(reg.matches))));
        return (
          wrapperElement(getResultsTable(requestTemplate, regFact, policy, reg.registration, new Set(reg.matches)))
        );
      });
  }

  function getResultsTable(requestTemplate : APIRequestTemplate, regFact: APIRegistrantFacts, policy: APIPolicy, registration: APIRegistration, matchedElIds: Set<number>) {
    return (
    <CollapsibleBox
      title={
        <span>
          {registration.name}
          <p style={{ float: "right", marginRight: "10px"}}>
            Matched on {['domain', 'contact', 'email'].filter(field => searchFields.get(field)?.some(id => matchedElIds.has(id))).join(", ")}
          </p>
        </span>
      }
      startCollapsed={true}>
      <button onClick={() => {
        window.location.hash = "/registration/"+registration.id
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>exit_to_app</span>
        <a href={`/#/registration/${registration.id}`}>Go To Registration</a>
      </button>
      <table>
        <thead>
          <th>Group</th>
          <th>Element Name</th>
          <th>Element Value</th>
          <th>Validation</th>
          <th>Sensitivity</th>
        </thead>
        <tbody>
          {
            props.dataDictionary.element_groups.map(eg => (
              eg.elements.map((el, i) => {
                let coll: number = 0;
                let validation: string = "";
                let sensitivity: string = "";
                let V3RQ: number = 0;
                let DG: number = 0;
                let reg_sens_id: number = 0;
                let templ_sens_id: number = 0;
                // need to search through list of facts to find the right fact for this element
                let fact = regFact.facts.find(f => f.element_id === el.id);
                if (policy) {
                  const rule = getRuleForElement(policy, el.id);
                  if (rule) {
                    coll = getAttribute(1, rule, props.dataDictionary)?.value || 0;
                    validation = getAttribute(2, rule, props.dataDictionary)?.name || "";
                    sensitivity = getAttribute(4, rule, props.dataDictionary)?.name || "";
                    V3RQ = getAttribute(3, rule, props.dataDictionary)?.value || 0;
                    DG = getAttribute(5, rule, props.dataDictionary)?.value || 0;
                    reg_sens_id = getAttribute(4, rule, props.dataDictionary)?.value ?? 0;
                    reg_sens_id = fact?.DG === "DG please" && DG === 2 ? 2 : reg_sens_id;
                    templ_sens_id = parseInt(requestTemplate.attributes.filter(a => a.attribute_id === 15).at(0)?.value ?? "0");
                  }
                }
                let groupCell: React.ReactNode = null;
                if (i === 0) {
                  groupCell = (
                    <td rowSpan={eg.elements.length}>{eg.name}</td>
                  );
                }
                if (templ_sens_id >= reg_sens_id) {
                  return (
                    <tr>
                      {groupCell}
                      <td style={{ backgroundColor: matchedElIds.has(el.id) ? "#afa" : undefined}}>{el.name}</td>
                      <td style={{ backgroundColor: matchedElIds.has(el.id) ? "#afa" : undefined}}>
                        {filterFact(fact, coll)}
                      </td>
                      <td>
                        {fact?.V3RQ === "V3 please" && V3RQ === 2 ? "V3" : validation}
                      </td>
                      <td>
                        {fact?.DG === "DG please" && DG === 2 ? "S1" : sensitivity}
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr>
                      {groupCell}
                      <td>{el.name}</td>
                      <td>
                      </td>
                      <td>
                      </td>
                      <td>
                      </td>
                    </tr>
                  );
                }
              }
              )))
          }
        </tbody>
      </table>
    </CollapsibleBox> );
  }

  function getAttribute(id: number, rule: APIRule, dataDictionary: APIDictionary) {
        const attribute_validation = getAttributeFromRule(rule, id);
        if (attribute_validation) {
            const attribute_value = dataDictionary.rule_attributes.filter(a => a.id === attribute_validation.attribute_id).at(0)?.values.at(attribute_validation.value);
            return attribute_value ? attribute_value : null;
        }
        return null;
    }

    // 0: {value: 0, color: 'ff0000', name: 'ø'}
    // 1: {value: 1, color: 'ffff00', name: 'Collect'}
    // 2: {value: 2, color: '3165ff', name: "Don't Collect"}
    // 3: {value: 3, color: 'ffc000', name: "Coll or Don't"}
    // 4: {value: 4, color: 'b8ff82', name: 'Optional'}
    // 5: {value: 5, color: '00efd2', name: 'Coll or Opt'}
    // 6: {value: 6, color: 'ff5cff', name: "Opt or Don't"}
    // 7: {value: 7, color: 'ffffff', name: 'Any'}
    function filterFact(fact: APIRegistrantFactItem | undefined, attributeVal: number) {
        if (!fact) return "";
        switch (attributeVal) {
            case 0: return "ø";
            case 1: return fact.value;
            case 2: return "";
            //TODO build other cases
            default: return "";
        }
    }

  function textInput(requestTemplate: APIRequestTemplate, type: string) {
    return props.dataDictionary.request_attributes.map(attr => {
        const requestAttr = requestTemplate.attributes.filter(a => a.attribute_id === attr.attribute.id)[0];
        if (attr.type === type) {
            if (attr.attribute.id === 35) {
                return potentialDisplay(requestTemplate, requestAttr, attr, requestTemplate.attributes.filter(a => a.attribute_id === 2).at(0)?.value === "0");
            }
            if (attr.attribute.id === 36) {
                return potentialDisplay(requestTemplate, requestAttr, attr, requestTemplate.attributes.filter(a => a.attribute_id === 3).at(0)?.value === "0");
            }
            return potentialDisplay(requestTemplate, requestAttr, attr, true);
        }
    return null;
    })
  }

  function potentialDisplay(requestTemplate: APIRequestTemplate, requestAttr: APIRequestTemplateItem, attr: APITypedAttribute, display: boolean) {
    return display ? (
        <tr className="editable-request-field">
        <td>{attr.attribute.name}</td>
            <td>
            <input type="text" value={requestAttr?.value || ""}
                onChange={(e) => {
                    updateAttribute(requestTemplate, attr.attribute.id, e.target.value, setRequestTemplate);
                }}
            />
            </td>
        </tr>
    ) : (
        <tr className="forbidden-request-field">
            <td>{attr.attribute.name}</td>
            <td>
            <input type="text" value={requestAttr?.value || ""} readOnly/>
            </td>
        </tr>
    );
  }
}

export function getRequestTemplate(requestID: number): Promise<APIRequestTemplate> {
  return new Promise((resolve, reject) => {
    fetch(`/api/request_template/${requestID}`, {
      headers: getAPITokenHeaders()
    })
      .then(async rawResponse => {
        const response = await rawResponse.json() as unknown as GetRequestTemplate;
        if (response.status === 'success') {
          return resolve(response.request_template);
        } else {
          return reject(response.error);
        }
      })
      .catch(error => reject(error));
  });
}

export async function getRegistrations(domain: string, contact: string, email: string) {
    const rawResponse = await fetch(`/api/request_query_execute?domain=${domain}&contact=${contact}&email=${email}`,
      {
        headers: getAPITokenHeaders()
      }
    );
    const response = await rawResponse.json() as unknown as GetMatchedRegistrations;
    if (response.status === 'success') {
      return response.registrations;
    }
    else {
      throw new Error(response.error);
    }
  }
