import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './stylesheet.css'

const container = document.getElementById('root');

if (container)
  {
  const root = createRoot(container);
  root.render(<React.StrictMode><App /></React.StrictMode>);
  reportWebVitals(); /* To meansure app performance, pass a function to log results. Example: reportWebVitals(console.log()); To send to an analytics endpoint, see: https://bit.ly/CRA-vitals */
  }
else
  {
  console.error('Root element with id "root" not found.');
  }
