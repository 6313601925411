import React from 'react';
import _ from 'lodash';
import ReactModal from 'react-modal';
import { APIAttribute, APIDictionary, APIElement, APIElementGroup, APIError, APIPolicy, APIRule, APIRuleAttribute, APISuccess, APITLDList, GetPolicy, NewPolicy, PolicyListItem } from './common/api';
import { /*getURLHash,*/ getAttributeFromRule, getRuleForElement, ORG_TYPE_ABBR, restoreOldHashChange } from './common/util';
import EditableLabel from './EditableLabel2';
import LoadingSpinner from './LoadingSpinner';
import MultiSelectPolicyList from './MultiSelectPolicyList';
import Scope from './Scope';
import AttributeSelect from './AttributeSelect';
import CollapsibleBox from './CollapsibleBox';
import { getAPITokenHeaders } from './password';
import Note from './Note';
import { getPolicyMetadata } from './policyData';
import ImportPolicyModal from './ImportPolicyModal'
import { exportPolicyToPDF } from './PDfDownload';
import HideGroupAttributesModal from './HideGroupsAtrributesModal';
import fileDownload from 'js-file-download';
import { checkUnsavedBeforeUnload } from './common/util';


type ComparisonResult = "unknown" | "equal" | "subset" | "superset" | "incompatible";

interface PolicyEditorProps
  {
  policyID: number;
  dataDictionary: APIDictionary;
  }

export interface PolicyEditorState
  {
  policy: APIPolicy | null;
  isLoadingPolicy: boolean;
  isDirty: boolean;
  isSaving: boolean;
  selectPolicyModal: boolean;
  comparisonPolicy: APIPolicy | null;
  comparisonList: number [];
  policyLoadingProgress: number | null | "unknown";
  editSubjectToModal: boolean;
  ignoredSubjectToPolicies: number[]; // rather than keep a list of which subject-to policies are selected for comparison, keep a list of policies that are DEselected so that they default to selected.
  policyMetadataCache: { [key: number]: PolicyListItem }; // TODO: is it safe to use number keys
  rowSelectRange: [number, number] | null;
  copiedData: APIRule[] | null;
  pasteSpecialModal: boolean;
  pasteOnly: number[]; // attribute IDs to paste
  importPolicyModal: boolean;
  hideGroupsAttributesModal: boolean;
  hiddenGroupsAttributes: (APIElementGroup | APIAttribute)[];
  pdfStartPage: number;
  pdfAnnotation: string;
  pdfIncludeLegend: boolean;
  }

/* const WRAPPER_FIELDS: keyof APIPolicy = ["org_name", "org_type", "prime_poc", "prime_email", "alt_poc", "alt_email", "effective_date"]; */

export default class PolicyEditor extends React.Component<PolicyEditorProps, PolicyEditorState>
  {
  private oldOnhashchange?: typeof window.onhashchange; // intercept hash change to give user a chance to save their work
  _isMounted = false;

  constructor(props: PolicyEditorProps)
    {
    super(props);
    this.state =
      {
      policy: null,
      isLoadingPolicy: true,
      isDirty: false,
      isSaving: false,
      selectPolicyModal: false,
      editSubjectToModal: false,
      comparisonPolicy: null,
      comparisonList: [],
      policyLoadingProgress: null,
      ignoredSubjectToPolicies: [],
      policyMetadataCache: {},
      rowSelectRange: null,
      copiedData: null,
      pasteSpecialModal: false,
      pasteOnly: this.props.dataDictionary.rule_attributes.map(att => att.id),
      importPolicyModal: false,
      hideGroupsAttributesModal: false,
      hiddenGroupsAttributes: [],
      pdfStartPage: 0,
      pdfAnnotation: "",
      pdfIncludeLegend: true,
      };

    //checkUnsavedBeforeUnload()(() => this.state.isDirty);
    // to handle "navigating away" where the hash changes but onbeforeunload is not triggered, we intercept the original onhashchange handler to first ask the user if they want to save their work and restore the current hash if the user elects to stay on the current page. in development mode, React (StrictMode) renders twice, so don't do this work the first time
    // @ts-ignore
    // if (!console.log.__reactDisabledLog)
    //   {
    //   // first store the old onhashchange so that we can restore it when this component unmounts
    //   //console.log("Storing ", window.onhashchange?.toString(), " as oldOnhashchange");
    //   this.oldOnhashchange = window.onhashchange;
    //   //console.log("Setting policyEditor's onhashchange handler")
    //   // @ts-ignore
    //   window.onhashchange = (e: HashChangeEvent) =>
    //     {
    //     // if the new url is the same as the current policy, then do nothing
    //     if (this.state.policy && getURLHash(e.newURL) === `/policy/${this.state.policy.id}`)
    //       {
    //       return;
    //       }
    //     if (!this.state.isDirty || window.confirm("You may have unsaved changes. Are you sure you want to leave?"))
    //       {
    //       this.oldOnhashchange?.call(window, e);
    //       }
    //     else
    //       {
    //       window.location.hash = getURLHash(e.oldURL);
    //       e.preventDefault();
    //       }
    //     };
    //   }
    }

  componentDidMount()
    {
    this._isMounted = true;
    this.fetchPolicy();
    checkUnsavedBeforeUnload()(() => this.state.isDirty);
    }

  componentWillUnmount()
    {
    this._isMounted = false;
    restoreOldHashChange(this.oldOnhashchange);
    }

  fetchPolicy()
    {
    if (this._isMounted)
      {
      this.setState({ isLoadingPolicy: true });
      getPolicy(this.props.policyID).then((newPolicy) => {this.setState({ policy: newPolicy });
      getPolicyMetadata(newPolicy.subject_to).then((newMetadata) => this.setState({ policyMetadataCache: mapPolicyIDsToName(newMetadata) }));}).finally(() => {this.setState({ isLoadingPolicy: false, isDirty: false });});
      }
    }

  componentDidUpdate(prevProps: PolicyEditorProps, prevState: PolicyEditorState)
    {
    if (this.props.policyID !== prevProps.policyID)
      {
      this.fetchPolicy();
      }
    if (this.state.comparisonPolicy)
      {
      console.log("Comparison Policy ID: ", this.state.comparisonPolicy);
      }
    }

  render()
    {
    const props = this.props; // for compatibility with old functional component code
    //const [policy, setPolicy] = React.useState<APIPolicy | null>(null);
    const policy = this.state.policy;
    //const [isLoadingPolicy, setIsLoadingPolicy] = React.useState<boolean>(false);
    //const isLoadingPolicy = this.state.isLoadingPolicy;
    //const [isDirty, setIsDirty] = React.useState<boolean>(false);
    const isDirty = this.state.isDirty;
    //const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const isSaving = this.state.isSaving;
    //const [selectPolicyModal, setSelectPolicyModal] = React.useState<boolean>(false);
    //const selectPolicyModal = this.state.selectPolicyModal;
    //const [comparisonPolicy, setComparisonPolicy] = React.useState<APIPolicy | null>(null);
    const comparisonPolicy = this.state.comparisonPolicy;
    //const [policyLoadingProgress, setPolicyLoadingProgress] = React.useState<number | null | "unknown">(null);
    //const policyLoadingProgress = this.state.policyLoadingProgress;
    //const [rowSelectRange, setRowSelectRange] = React.useState<[number, number] | null>(null);
    const rowSelectRange = this.state.rowSelectRange;
    const updateHiddenGroupsAttributes = (newHidden: (APIElementGroup | APIAttribute)[]) => {this.setState({ hiddenGroupsAttributes: newHidden })};

    // the copied data is a list of APIRule, but for pasting attribute values we can just ignore the id and element_id properties
    //const [copiedData, setCopiedData] = React.useState<APIRule[] | null>(null);

    // TODO: this doesn't work if you call it twice in a row because the value of `policy` isn't updated until the next render, so the last write wins. Avoid using it and use updatePolicy instead.
    const that = this;
    function updatePolicyField<T extends keyof APIPolicy>(field: T, value: APIPolicy[T])
      {
      const newPolicy = Object.assign({}, policy);
      newPolicy[field] = value;
      that.setState({ policy: newPolicy, isDirty: true });
      }

    const dict = props.dataDictionary;

    // function mapPolicyIDsToName(policies: PolicyListItem[]) {
    //   const mapping: { [key: number]: PolicyListItem } = {};
    //   policies.forEach(p => mapping[p.id] = p);
    //   return mapping;
    // }

    if (policy === null) return (<LoadingSpinner />);

    // flatten the list of element groups into a list of [element_group, element] pairs where element_group is null if the element is not the first in the group
    const flattenedElements: [APIElementGroup | null, APIElement][] = flattenDictElements(dict);
    return (<div className={this.state.isLoadingPolicy ? "PolicyEditor loading" : "PolicyEditor"}>{this.state.isLoadingPolicy ? (<LoadingSpinner />) : null /* show loading spinner but don't get rid of the table so we don't have to redraw everything from scratch when changing policies */}
      <div className="toolbar" style={{ marginBottom: "1em" }}>
        <button type="button" onClick={() => {window.location.hash = "";}}><span className="material-icons-outlined" aria-hidden={true}>arrow_back</span>Home</button>
        <button disabled={!isDirty || isSaving} onClick={() => {this.setState({ isSaving: true }); savePolicy(policy).then(() => this.setState({ isDirty: false })).finally(() => this.setState({ isSaving: false }));}}><span className="material-icons-outlined" aria-hidden={true}>save</span>{isSaving ? "Saving..." : "Save changes"}</button>
        <button onClick={() => this.setState({hideGroupsAttributesModal: true})}><span className="material-icons-outlined" aria-hidden={true}>settings</span>Change format</button>
        <button onClick={this.handleEditSubjectToModalButtonClick}><span className="material-icons-outlined" aria-hidden={true}>edit</span>Edit Compare List</button>
        <button onClick={() => {exportPolicyToPDF(this.state, this.props.dataDictionary);}}><span className="material-icons-outlined" aria-hidden={true}>download</span>Download as PDF</button>
        <button onClick={() => {this.setState({ isLoadingPolicy: true }); copyPolicy(policy.id).then((newPolicyID) => window.location.hash = `/policy/${newPolicyID}`).finally(() => this.setState({ isLoadingPolicy: false }));}}><span className="material-icons-outlined" aria-hidden={true}>file_copy</span>Make copy of this policy</button>
        <button onClick={() => {if (window.confirm('Are you sure you wish to delete this item?')) {this.setState({ isLoadingPolicy: true }); deletePolicy(policy.id).then(() => window.location.hash = '/').finally(() => this.setState({ isLoadingPolicy: false }));}}}><span className="material-icons-outlined" aria-hidden={true}>delete</span>Delete this policy</button>
        <button onClick={() => {this.setState({ importPolicyModal: true })}}><span className="material-icons-outlined" aria-hidden={true}>file_download</span>Import</button>
        <button onClick={() => {exportPolicy(policy)}}><span className="material-icons-outlined" aria-hidden={true}>file_upload</span>Export</button>
        </div>

      <div className="toolbar" style={{position: "fixed", bottom: "1em", left: "1em", zIndex: 1000, display: rowSelectRange ? "block" : "none",}}>
        <button onClick={() => this.copyData()}><span className="material-icons-outlined" aria-hidden={true}>content_copy</span>Copy row values</button>
        <button disabled={this.state.copiedData === null} onClick={() => this.pasteData()}><span className="material-icons-outlined" aria-hidden={true}>content_paste</span>{this.state.copiedData === null ? "Paste row values" : this.state.copiedData.length === 1 ? "Duplicate 1 row across selection" : `Paste ${this.state.copiedData.length} rows of values`}</button>
        <button disabled={this.state.copiedData === null} onClick={() => this.setState({ pasteSpecialModal: true })}><span className="material-icons-outlined" aria-hidden={true}>content_paste</span>Paste only...</button>
        </div>

      <table className="PolicyEditor-table" style={{marginLeft: "auto", marginRight: "auto"}}>
        <thead>
        <tr><td colSpan={4 + dict.rule_attributes.length - this.state.hiddenGroupsAttributes.filter(thing => dict.rule_attributes.some(att => att === thing)).length /* Number of columns to span is number of different attributes plus: * element group name * element name * notes column * category column */}><h1><EditableLabel allowNewline={false} value={policy.name} onValueChange={(value) => {if (value.trim() === '') {value = "";} this.updatePolicy({"name": value});}}/></h1></td>{comparisonPolicy ? (<><td className="spacercol"></td><td rowSpan={2} colSpan={dict.rule_attributes.length} style={{ width: "min-content" }}><h1><ul>{comparisonPolicy.name.split(" ∩ ").map(pn => <li key={pn}>{pn}</li>)}</ul></h1></td></>) : null}</tr>
        <tr><td colSpan={4 + dict.rule_attributes.length - this.state.hiddenGroupsAttributes.filter(thing => dict.rule_attributes.some(att => att === thing)).length}><div className="policy-header-container" style={{display: "flex", flexDirection: "row"}}>
          <CollapsibleBox title="Wrapper" style={{ flex: "1 1", height: "max-content" }}>
            <table className="PolicyEditor-wrapper" id="wrapper-table" style={{ width: "100%" }}>
            <tbody>
            <tr><td className="wrapper-pdf-header">Organization Name</td><td><input id="policy_org_name" value={policy.org_name} type="text" className="wrapper-pdf-body" onChange={(e) => this.updatePolicy({org_name: e.target.value})} /></td></tr>
            <tr><td className="wrapper-pdf-header">Organization Type</td><td><select id="policy_org_type" value={policy.org_type} onChange={(e) => {const newValue = e.target.value; if (newValue !== "registry" && newValue !== "registrar" && newValue !== "policy_authority") {return;} this.updatePolicy({org_type: newValue})}}><option value="policy_authority">Policy Authority</option><option value="registry">Registry</option><option value="registrar">Registrar</option></select></td></tr>
            <tr><td className="wrapper-pdf-header">Prime PoC</td><td><input id="policy_prime_poc" value={policy.prime_poc} type="text" onChange={(e) => updatePolicyField("prime_poc", e.target.value)} /></td></tr>
            <tr><td className="wrapper-pdf-header">Prime email</td><td><input id="policy_prime_email" value={policy.prime_email} type="text" onChange={(e) => updatePolicyField("prime_email", e.target.value)}/></td></tr>
            <tr><td className="wrapper-pdf-header">Alternate PoC</td><td><input id="policy_alt_poc" value={policy.alt_poc} type="text" onChange={(e) => updatePolicyField("alt_poc", e.target.value)} /></td></tr>
            <tr><td className="wrapper-pdf-header">Alternate email</td><td><input id="policy_alt_email" type="text" value={policy.alt_email} onChange={(e) => updatePolicyField("alt_email", e.target.value)}/></td></tr>
            <tr><td className="spacerrow"></td></tr>
            <tr><td className="wrapper-pdf-header">Intended Use</td><td><table><tbody><tr><td><select id="policy_status" value={policy.status} onChange={(e) =>
            // @ts-ignore
            updatePolicyField("status", e.target.value)}><option value="proposed">Proposed</option><option value="planned">Planned</option><option value="actual">Actual</option>{/* <option value="certified">Certified</option> */}</select></td><td><span>Effective Date: </span><input value={policy.effective_date} type="date" onChange={(e) => updatePolicyField("effective_date", e.target.value)} /></td></tr></tbody></table></td></tr>
            <tr><td className="spacerrow"></td></tr>
            <tr><td className="wrapper-pdf-header">Completion</td><td><select id="policy_completion" value={policy.completion} onChange={(e) => updatePolicyField("completion", e.target.value === "final" ? "final" : "draft")}><option value="draft">Draft</option><option value="final">Final</option></select></td></tr>
            <tr><td className="wrapper-pdf-header">Version</td><td><table><tbody><tr><td><span className="media-print">{policy.version}</span><select id="policy_version" value={policy.id} className="media-screen" onChange={(e) => {const selectedPolicyID = parseInt(e.target.value); if (selectedPolicyID !== policy.id) {window.location.hash = `/policy/${selectedPolicyID}`;}}}>{policy.versions.map(v => (<option key={v.id} value={v.id}>{v.version}</option>))}</select></td><td><span>Updated Date: </span><input id="policy_updated_date" value={policy.updated_date} type="datetime-global" readOnly/></td><td><button onClick={() => {this.setState({ isLoadingPolicy: true }); newPolicyVersion(policy.id).then(async (newPolicyID) => {/* Save the user's unsaved changes to the new version to prevent losing unsaved work */ updatePolicyField("version", policy.version + 1); updatePolicyField("id", newPolicyID); if (isDirty) {await savePolicy(Object.assign({}, policy, { id: newPolicyID }));} return newPolicyID;}).then((newPolicyID) => window.location.hash = `/policy/${newPolicyID}`).finally(() => this.setState({ isLoadingPolicy: false }));}} style={{ float: "right" }}><span className="material-icons-outlined">file_copy</span>Copy policy as new version</button></td></tr></tbody></table></td></tr>
            <tr><td className="spacerrow"></td></tr>
            <tr><td className="wrapper-pdf-header">Distribution</td><td><select id="policy_distribution" value={policy.distribution} style={policy.distribution === "ø" ? { backgroundColor: "red", color: "white" } : undefined} onChange={(e) => updatePolicyField("distribution", e.target.value as typeof policy.distribution)}><option value="ø">ø</option><option value="Internal">Internal</option><option value="BBQ">BBQ</option><option value="No attrib">No attrib</option><option value="Public">Public</option></select></td></tr>
            <tr><td className="wrapper-pdf-header">Notes</td><td><textarea id="policy_notes" style={{ width: "95%", height: "6.2em" }} value={policy.notes || "Lorem ipsum dolor sit amet, consectetur adip"} onChange={(e) => updatePolicyField("notes", e.target.value)} /></td></tr>
            </tbody>
            </table>
            </CollapsibleBox>

            <div><CollapsibleBox title="Scope" style={{ flex: "1 1", height: "max-content" }}>{scopeElement(policy.scope_attributes, policy.tlds, props.dataDictionary.scope_attributes, (newScopeAtts: APIRuleAttribute[]) => this.updatePolicy({scope_attributes: newScopeAtts}), (newTLDList: APITLDList) => this.updatePolicy({tlds: newTLDList, tld_list_id: newTLDList.id}), 1)} </CollapsibleBox>

            <CollapsibleBox title="Compare List" style={{ flex: "1 1", height: "max-content" }}>
              <table><tbody>
              <tr><td>
              {/* Display the list of policies to compare */}
              {policy.subject_to.length === 0 ? (<div><em>No other policies are specified for this policy to compare to.</em></div>) : (<ul className="compare-list">{/* Map through each policy to generate list items; Check if the policy is marked as deleted; display deleted policy with strike-through and remove button; Otherwise, display active policy with checkbox, link, and remove button */ policy.subject_to.map(pID => (this.state.policyMetadataCache[pID]?.deleted === true ? (<li key={pID}><s>{formatPolicyListItemName(this.state.policyMetadataCache[pID])}</s><button className="small-unlabeled" onClick={() => this.removeCompareListItem(pID)}><span className="material-icons-outlined" aria-label="Remove">close</span></button></li>) : (<li key={pID} style={{display: 'block'}}><input id={`policy-${pID}`} type="checkbox" checked={!this.state.ignoredSubjectToPolicies.includes(pID)} onChange={this.handleCompareListCheckbox(pID)} className="media-screen" /><a style={{width: 'auto'}}href={`#/policy/${pID}`}>{this.state.policyMetadataCache[pID] ? formatPolicyListItemName(this.state.policyMetadataCache[pID]) : `<policy ${pID}>`}</a><button className="small-unlabeled" onClick={() => this.removeCompareListItem(pID)}><span className="material-icons-outlined" aria-label="Remove">close</span></button></li>)))}</ul>)}
              {/* Button to trigger the edit subject_to modal */}
              <button onClick={this.handleEditSubjectToModalButtonClick}><span className="material-icons-outlined" aria-hidden={true}>edit</span>Edit List</button>
              {/* Button to trigger the comparison of policies */}
              <button onClick={this.triggerComparisonMode}><span className="material-icons-outlined" aria-hidden={true}>compare</span>Compare</button>
              {/* Button to switch the current state and a single comparison policy */}
              <button disabled={this.isSwitchButtonDisabled()} onClick={this.handleSwitchButtonClick}><span className="material-icons-outlined" aria-hidden={true}>flip</span>Switch</button>
              {/* ReactModal for editing subject_to */}
              <ReactModal isOpen={this.state.editSubjectToModal} onRequestClose={() => this.setState({ editSubjectToModal: false })} appElement={document.getElementById('root') ?? undefined}><p>Compare <strong>{policy.name}</strong> with:</p><MultiSelectPolicyList onSelectPolicy={(newPolicy) => this.updateComparisonList(newPolicy)} selectedPolicies={policy.subject_to} /><div style={{ marginTop: "1em", textAlign: "right" }}><button onClick={() => this.setState({ editSubjectToModal: false })}>Done</button></div></ReactModal>
              {/* HideGroupAttributesModal */}
              <HideGroupAttributesModal isOpen={this.state.hideGroupsAttributesModal} hiddenGroupsAttributes={this.state.hiddenGroupsAttributes} updateHidden={updateHiddenGroupsAttributes} dict={dict} close={() => this.setState({ hideGroupsAttributesModal: false })} />
              </td></tr>
              </tbody></table>
              </CollapsibleBox>

            <CollapsibleBox title="PDF Details" style={{ flex: "1 1", height: "max-content" }}>
              <table className="PolicyEditor-PDF" id="pdf-table" style={{ width: "100%" }}><tbody>
              <tr><td>Starting Page Number</td><td><input id="pdf-meta-page-number" type="number" className="wrapper-pdf-meta" onChange={(e) => {this.setState( {pdfStartPage: parseInt(e.target.value)} );}} /></td></tr>
              <tr><td>Annotation Value</td><td><input id="pdf-meta-annotation-value" type="string" className="wrapper-pdf-meta" onChange={(e) => {this.setState( {pdfAnnotation: e.target.value} );}} /></td></tr>
              <tr><td>Include Legend</td><td><input id="pdf-meta-include-legend" type="checkbox" className="swtich" onChange={(e) => {this.setState( {pdfIncludeLegend: e.target.checked} ); console.log(e.target.checked);}} checked={this.state.pdfIncludeLegend} /></td></tr>
              </tbody></table>
              </CollapsibleBox>

        </div></div></td></tr>
        <tr><th>Group</th><th>Element</th><th>Category</th>{dict.rule_attributes.map(att => (isGroupOrAttributeHidden(this.state.hiddenGroupsAttributes, att) ? null : <th key={att.id} colSpan={1}>{att.name}</th>))}<th><span className="material-icons-outlined" title="Notes">sticky_note_2</span></th>{comparisonPolicy ? (<><th style={{ backgroundColor: "white" }} className="spacercol">&rarr;</th>{dict.rule_attributes.map(att => (isGroupOrAttributeHidden(this.state.hiddenGroupsAttributes, att) ? null : <th key={`comp-${att.id}`} colSpan={1}>{att.name}</th>))}</>) : null /* if we are comparing against another policy, show it side by side*/}</tr></thead>
        <tbody>{flattenedElements.map((item, i) => {const eg = item[0]; const el = item[1]; let rule = getRuleForElement(policy, el.id); if (!rule) {rule = {id: 0, element_id: el.id, attributes: []};} /* undefined means we're not comparing, null means we're comparing but we're missing a rule */ const comparisonRule = comparisonPolicy ? getRuleForElement(comparisonPolicy, el.id) ?? null : undefined; let group = eg !== null ? { rows: eg.elements.length, name: eg.name } : undefined; return (<PolicyRule key={el.id} element={el} dictionary={dict} rule={rule} comparisonRule={comparisonRule} group={group} hidden={isGroupOrAttributeHidden(this.state.hiddenGroupsAttributes, getElementsGroup(dict, el))} hiddenGroupsAttributes={this.state.hiddenGroupsAttributes} selected={rowSelectRange !== null && isWithinRange(i, rowSelectRange[0], rowSelectRange[1])} onSelectBegin={() => this.setState({ rowSelectRange: [i, i] })} onSelectEnd={() => {if (!rowSelectRange) { return; } this.setState({ rowSelectRange: [rowSelectRange[0], i] });}} onSelectClear={() => this.setState({ rowSelectRange: null })} onChange={(elementID, attributeID, newVal) => {this.setState({policy: updatePolicyRuleAttributeValue(policy, elementID, attributeID, newVal), isDirty: true,});}} onNoteChange={(newNote) => this.setState({policy: updatePolicyRuleField(policy, el.id, "notes", newNote), isDirty: true})} />);})}
        </tbody>
        </table>

        <div className="media-print" style={{maxWidth:"100vw"}}>{this.props.dataDictionary.element_groups.map(eg => eg.elements.map((el, i) => {let rule = getRuleForElement(policy, el.id); if (rule && rule.notes) {return (<p key={el.id}><strong>{eg.name}&mdash;{el.name}:</strong> {rule.notes}</p>)} else {return null;}}))}</div>

      <ReactModal isOpen={this.state.pasteSpecialModal} onRequestClose={() => this.setState({ pasteSpecialModal: false })} appElement={document.getElementById('root') ?? undefined}><p>Paste only: <ul>{this.props.dataDictionary.rule_attributes.map(att => (<li key={att.id}><input id="pasteSpecialOption" type="checkbox" checked={this.state.pasteOnly.includes(att.id)} onChange={(e) => {if (e.target.checked) {this.setState({ pasteOnly: this.state.pasteOnly.concat([att.id])});} else {this.setState({ pasteOnly: this.state.pasteOnly.filter(attID => attID !== att.id)});}}}/>{att.name}</li>))}</ul></p><p style={{textAlign: "right"}}><button onClick={() => {this.pasteSpecial(); this.setState({ pasteSpecialModal: false })}}><span className="material-icons-outlined" aria-hidden={true}>content_paste</span>Paste</button></p></ReactModal>

      <ImportPolicyModal policyID={this.props.policyID} open={this.state.importPolicyModal} closeFunc={() => this.setState({importPolicyModal: false})} />
      </div>);
    }

  isSwitchButtonDisabled = () =>
    {
    const policy = this.state.policy;
    const comparison = this.state.comparisonPolicy;
    const comparisonsList = this.state.comparisonList;
    //return (!policy || !comparison || (comparisonsList.length !== 1) || !policy.subject_to.includes(comparison.id) || !comparison.subject_to.includes(policy.id)) ? true : false;
    return (!policy || !comparison || (comparisonsList.length !== 1)) ? true : false;
    }

  handleSwitchButtonClick = async () =>
    {
    const policy = this.state.policy;
    const comparison = this.state.comparisonPolicy;
    const dataDictionary = this.props.dataDictionary;

    try
      {
      if (policy && comparison && dataDictionary)
        {
        downloadComparisonPolicies([policy.id], (progress) => null, dataDictionary)
          .then((updateComparison) =>
            {
            this.setState({ comparisonPolicy: updateComparison });
            this.setState({ comparisonList: [policy.id]});
            })
          .finally(() => {console.log("Policy Toggle: ", policy.id, " --> ", comparison.id); window.location.hash = `/policy/${comparison.id}`});
        }
      }
    catch
      {
      console.error("Switch Error", policy, comparison, dataDictionary);
      }
    }

  handleEditSubjectToModalButtonClick = () =>
    {
    this.setState({ editSubjectToModal: true });
    }

  getComparisonListCheckboxes = ():number[] =>
    {
    const policy = this.state.policy;
    //const dataDictionary = this.props.dataDictionary;
    return (policy) ? policy.subject_to.filter(p => !this.state.ignoredSubjectToPolicies.includes(p) && this.state.policyMetadataCache[p]?.deleted !== true) : [];
    }

  triggerComparisonMode = async () =>
    {
    const policy = this.state.policy;
    const dataDictionary = this.props.dataDictionary;
    const comparisonPoliciesList = this.getComparisonListCheckboxes();
    // Download and set the comparison policy based on selected policies
    if (policy)
      {
      const comparisonPolicy = await downloadComparisonPolicies(comparisonPoliciesList, (progress) => null, dataDictionary);
      this.setState({ comparisonPolicy: comparisonPolicy });
      this.setState({ comparisonList: comparisonPoliciesList});
      }
    }

  handleCompareListCheckbox = (pID: number) => (e: React.ChangeEvent<HTMLInputElement>) =>
    {
    if (e.target.checked)
      {
      // Add policy to the ignored list
      this.setState({ ignoredSubjectToPolicies: this.state.ignoredSubjectToPolicies.filter(i => i !== pID) });
      }
    else
      {
      // Remove policy from the ignored list
      this.setState({ ignoredSubjectToPolicies: this.state.ignoredSubjectToPolicies.concat([pID]) });
      }
    }

  updateComparisonList = (newPolicy:PolicyListItem) =>
    {
    const pID = newPolicy.id;
    const policy = this.state.policy;
    // Update the policy metadata cache if not present
    if (!this.state.policyMetadataCache[pID])
      {
      const newPolicyMetadataCache = Object.assign({}, this.state.policyMetadataCache);
      newPolicyMetadataCache[pID] = newPolicy;
      this.setState({ policyMetadataCache: newPolicyMetadataCache });
      }
    // Remove the policy from subject_to list if already present; otherwise, add the policy to the subject_to list
    if (policy)
      {
      if (policy.subject_to.includes(pID))
        {
        this.removeCompareListItem(pID);
        }
      else
        {
        this.updatePolicy({subject_to: policy.subject_to.concat([pID]),});
        }
      }
    }

  removeCompareListItem(policyID: number)
    {
    if (this.state.policy === null) { return; }
    this.updatePolicy({subject_to: this.state.policy.subject_to.filter(i => i !== policyID),});
    }

  updatePolicy(newValues: Partial<APIPolicy>)
    {
    if (this.state.policy === null) { return; }
    const newPolicy = Object.assign({}, this.state.policy, newValues);
    this.setState({ policy: newPolicy, isDirty: true });
    }

  copyData()
    {
    const selectRange = this.state.rowSelectRange;
    const policy = this.state.policy;
    if (!selectRange || !policy) { return; }
    let min: number, max: number;
    if (selectRange[0] > selectRange[1])
      {
      min = selectRange[1];
      max = selectRange[0];
      }
    else
      {
      min = selectRange[0];
      max = selectRange[1];
      }

    // the table is laid out based on the same flattened dictionary, so the row indexes should match up
    const flattenedElements = flattenDictElements(this.props.dataDictionary);
    const selectedElements = flattenedElements.slice(min, max + 1);
    const copiedRules = selectedElements.map(item => getRuleForElement(policy, item[1].id) ?? {id: 0, element_id: item[1].id, attributes: []});
    this.setState({ copiedData: copiedRules });
    }

  pasteData()
    {
    const selectRange = this.state.rowSelectRange;
    let policy = this.state.policy;
    const copiedData = this.state.copiedData;
    if (!selectRange || !policy || !copiedData) { return; }
    const flattenedElements = flattenDictElements(this.props.dataDictionary);

    // two cases: we are repeating one row many times, or copying many rows starting from a particular index
    // 1) repeat one row many times
    if (copiedData.length === 1)
      {
      let min: number, max: number;
      if (selectRange[0] > selectRange[1])
        {
        min = selectRange[1];
        max = selectRange[0];
        }
      else
        {
        min = selectRange[0];
        max = selectRange[1];
        }

      const copyElement = copiedData[0];
      for (let i = min; i <= max; i++)
        {
        const element = flattenedElements[i][1];
        policy = updatePolicyRuleAttributes(policy, element.id, copyElement.attributes);
        }
      }
    // 2) copy a block of data beginning at specific element
    else
      {
      const min = Math.min(selectRange[0], selectRange[1]);
      const targetElements = flattenedElements.slice(min, min + copiedData.length);
      for (let i = 0; i < copiedData.length; i++)
        {
        if (i >= targetElements.length) { break; } // don't go past the end of the table
        policy = updatePolicyRuleAttributes(policy, targetElements[i][1].id, copiedData[i].attributes);
        }
      }
    this.setState({ policy });
    }

  // pasteSpecial lets the user choose which columns to paste instead of pasting all of them
  pasteSpecial()
    {
    const selectRange = this.state.rowSelectRange;
    let policy = this.state.policy;
    const copiedData = this.state.copiedData;
    if (!selectRange || !policy || !copiedData) { return; }
    const flattenedElements = flattenDictElements(this.props.dataDictionary);

    // two cases: we are repeating one row many times, or copying many rows starting from a particular index
    // 1) repeat one row many times
    if (copiedData.length === 1)
      {
      let min: number, max: number;
      if (selectRange[0] > selectRange[1])
        {
        min = selectRange[1];
        max = selectRange[0];
        }
      else
        {
        min = selectRange[0];
        max = selectRange[1];
        }
      const copyElement = copiedData[0];
      for (let i = min; i <= max; i++)
        {
        const element = flattenedElements[i][1];
        const existingData = getRuleForElement(policy, element.id);
        policy = updatePolicyRuleAttributes(policy, element.id, mergeAttributes(existingData?.attributes ?? [], copyElement.attributes, this.state.pasteOnly));
        }
      }
    // 2) copy a block of data beginning at specific element
    else
      {
      const min = Math.min(selectRange[0], selectRange[1]);
      const targetElements = flattenedElements.slice(min, min + copiedData.length);
      for (let i = 0; i < copiedData.length; i++)
        {
        if (i >= targetElements.length) { break; } // don't go past the end of the table
        const existingData = getRuleForElement(policy, targetElements[i][1].id);
        policy = updatePolicyRuleAttributes(policy, targetElements[i][1].id, mergeAttributes(existingData?.attributes ?? [], copiedData[i].attributes, this.state.pasteOnly));
        }
      }
    this.setState({ policy });
    }
  }

// Helper function for handling scope attribute changes.
// @param selectedScopeAttributes - Currently selected scope attributes.
// @param attID - Attribute ID to be updated.
// @param newVal - New value for the attribute.
// @param onAttributeChange - Callback function when scope attributes change.
function handleAttributeChange(selectedScopeAttributes: APIRuleAttribute[], attID: number, newVal: number, onAttributeChange: (newScopeAtts: APIRuleAttribute[]) => void)
  {
  const newScopeAtts = selectedScopeAttributes.slice();
  const existingAttIndex = newScopeAtts.findIndex(att => att.attribute_id === attID);
  if (existingAttIndex === -1) newScopeAtts.push({ attribute_id: attID, value: newVal });
  else newScopeAtts[existingAttIndex] = Object.assign({}, newScopeAtts[existingAttIndex], { value: newVal });
  onAttributeChange(newScopeAtts);
  }

// Scope Element Component: Renders a component for selecting and managing scope attributes and TLD lists.
// @param selectedScopeAttributes - Currently selected scope attributes.
// @param tlds - Current TLD list.
// @param scopeAttributes - List of available scope attributes.
// @param onAttributeChange - Callback function when scope attributes change.
// @param onTLDListChange - Callback function when the TLD list changes.
// @param defaultDropdown - Default dropdown value (optional, defaults to 0).
// @returns JSX representing the Scope component.
export function scopeElement(selectedScopeAttributes: APIRuleAttribute[], tlds: APITLDList | null, scopeAttributes: APIAttribute[], onAttributeChange: (newScopeAtts: APIRuleAttribute[]) => void, onTLDListChange: (newTLDList: APITLDList) => void, defaultDropdown: number = 0)
  {
  return (<Scope scopeAttributes={scopeAttributes} selectedScopeAttributes={selectedScopeAttributes} tldList={tlds} defaultDropdown={defaultDropdown} onAttributeChange={(attID, newVal) => handleAttributeChange(selectedScopeAttributes, attID, newVal, onAttributeChange)} onTLDListChange={(newTLDList) => onTLDListChange(newTLDList)} />);
  }


// Flatten Dictionary Elements: Flattens the element groups in the dictionary.
// @param dict - The API dictionary.
// @returns Array containing pairs of element group and element, or null and element if not in a group.
export function flattenDictElements(dict: APIDictionary)
  {
  const flattenedElements: [APIElementGroup | null, APIElement][] = [];
  dict.element_groups.forEach(eg => eg.elements.forEach((e, i) => {(i === 0) ? flattenedElements.push([eg, e]) : flattenedElements.push([null, e]);}));
  return flattenedElements;
  }

// Get Elements Group: Retrieve the element group to which an element belongs.
// @param dict - The API dictionary.
// @param element - The API element.
// @returns The element group that contains the given element, or the first group if not found.
function getElementsGroup(dict: APIDictionary, element: APIElement)
  {
  let desiredGroup = dict.element_groups.find(eg => eg.elements.some(el => el === element));
  if (desiredGroup === undefined) desiredGroup = dict.element_groups[0]; // Default if no group is found
  return desiredGroup;
  }

// Download Comparison Policies: Downloads and computes the intersection of policies for comparison.
// @param policies - Array of policy IDs to be compared.
// @param setPolicyLoadingProgress - Callback function to update the loading progress.
// @param dataDictionary - The API dictionary containing element groups and rule attributes.
// @returns A promise that resolves to the computed APIPolicy representing the intersection of the input policies.
async function downloadComparisonPolicies(policies: number[], setPolicyLoadingProgress: (progress: number) => void, dataDictionary: APIDictionary): Promise<APIPolicy | null>
  {
  if (policies.length === 0) return null; // Takes the user out of comparison mode
  let policyIntersection: APIPolicy | null = null;
  setPolicyLoadingProgress(0);

  for (let i = 0; i < policies.length; i++)
    {
    const newPolicy = await getPolicy(policies[i]);
    policyIntersection = (policyIntersection === null) ? newPolicy : policyAnd(dataDictionary, policyIntersection, newPolicy);
    setPolicyLoadingProgress((i + 1) / policies.length * 100);
    }

  setPolicyLoadingProgress(100);
  return policyIntersection as APIPolicy;
  }

// Check if a given value is within a specified numerical range (inclusive).
// @param value - The value to be checked.
// @param min - The minimum value of the range.
// @param max - The maximum value of the range.
// @returns True if the value is within the range, false otherwise.
function isWithinRange(value: number, min: number, max: number)
  {
  // ensure min is the smaller value and max is the larger value
  const [rangeMin, rangeMax] = min < max ? [min, max] : [max, min];
  return value >= rangeMin && value <= rangeMax;
  }


// Compute the intersection of two policies, creating a new policy with rules that represent the bitwise AND of corresponding rules.
// - if a rule is missing in either policy, the attributes are treated as all zeros.
// @param dict - The API dictionary containing element groups and rule attributes.
// @param pa - The first APIPolicy.
// @param pb - The second APIPolicy.
// @returns A new APIPolicy representing the intersection of the input policies.
// TODO: Consider returning an array so that .split() is not needed to format the list of names
function policyAnd(dict: APIDictionary, pa: APIPolicy, pb: APIPolicy): APIPolicy
  {
  // Concatenate rules for each element in each element group
  const newRules = ([] as APIRule[]).concat(...dict.element_groups.map(eg =>
    {
    return eg.elements.map(el =>
      {
      const newRule: APIRule = { id: 0, element_id: el.id, attributes: [] }; // Initialize a new rule for the current element
      const paRule = getRuleForElement(pa, el.id); // Get rules for the current element from each input policy
      const pbRule = getRuleForElement(pb, el.id); // Get rules for the current element from each input policy

      // Check if both rules exist; if an attribute is missing in either rule, treat it as 0; compute the bitwise AND of attributes from both rules
      if (paRule && pbRule)
        {
        newRule.attributes = dict.rule_attributes.map(att =>
          {
          const paAtt = getAttributeFromRule(paRule, att.id);
          const pbAtt = getAttributeFromRule(pbRule, att.id);
          if (!paAtt || !pbAtt) return { attribute_id: att.id, value: 0 };
          return { attribute_id: att.id, value: paAtt.value & pbAtt.value };
          });
        }

      return newRule;
      });
    }));

  // Create a new APIPolicy object with the computed rules
  return Object.assign(pa, { name: pa.name + " ∩ " + pb.name, rules: newRules });
  }

// Define an anonymous function variable for formatting the name of a PolicyListItem.
// Returns JSX representing the formatted policy list item name:
// - Display the organization type badge using the abbreviation.
// - Display the policy name and version.
const formatPolicyListItemName = (policy: PolicyListItem) => (<><div className="org-type-badge">{ORG_TYPE_ABBR[policy.org_type]}</div>{policy.name + " (v" + policy.version + ")"}</>);

// Update the value of an attribute in a policy rule within the given policy; if the rule or attribute does not exist, it will be created.
// @param policy - The original APIPolicy object to be updated.
// @param elementID - The identifier of the rule element to be updated.
// @param attributeID - The identifier of the attribute to be updated.
// @param newVal - The new value to be assigned to the specified attribute.
// @returns A new APIPolicy object with the updated rule and attribute values.
export function updatePolicyRuleAttributeValue(policy: APIPolicy, elementID: number, attributeID: number, newVal: number): APIPolicy
  {
  policy = Object.assign({}, policy); // Create a shallow copy of the policy object to avoid mutations
  policy.rules = policy.rules.slice(); // Create a shallow copy of the rules array to avoid mutations
  const ruleIndex = policy.rules.findIndex(r => r.element_id === elementID); // Find the index of the rule with the specified elementID in the policy rules array
  let rule: APIRule; // Declare a variable to hold the rule object

  // Check if the rule with the specified elementID exists
  // - if the rule does not exist, create a new rule object; add the new rule to the policy rules array
  // - if the rule exists, clone the existing rule object; replace the existing rule in the policy rules array with the cloned rule
  if (ruleIndex === -1)
    {
    rule = { id: 0, element_id: elementID, attributes: [] };
    policy.rules.push(rule);
    }
  else
    {
    rule = Object.assign({}, policy.rules[ruleIndex]);
    policy.rules[ruleIndex] = rule;
    }

  rule.attributes = rule.attributes.slice(); // Create a shallow copy of the attributes array within the rule to avoid mutations
  const attributeIndex = rule.attributes.findIndex(a => a.attribute_id === attributeID); // Find the index of the attribute with the specified attributeID in the rule attributes array
  let attribute: APIRuleAttribute; // Declare a variable to hold the attribute object

  // Check if the attribute with the specified attributeID exists;
  // - if the attribute does not exist, create a new attribute object; add the new attribute to the rule attributes array
  // - if the attribute exists, clone the existing attribute object; replace the existing attribute in the rule attributes array with the cloned attribute
  if (attributeIndex === -1)
    {
    attribute = { attribute_id: attributeID, value: newVal };
    rule.attributes.push(attribute);
    }
  else
    {
    attribute = Object.assign({}, rule.attributes[attributeIndex]);
    rule.attributes[attributeIndex] = attribute;
    }

  attribute.value = newVal; // Update the value of the specified attribute with the provided new value
  return policy; // Return a new APIPolicy object with the updated rules and attribute values
  }

// Update the attributes of a policy rule by calling the updatePolicyRuleField function
const updatePolicyRuleAttributes = (policy: APIPolicy, elementID: number, newAttributes: APIRuleAttribute[]) => updatePolicyRuleField(policy, elementID, "attributes", newAttributes);

// Update a specific field of a policy rule in the given policy; if the rule with the specified elementID does not exist, a new rule is created.
// @param policy - The original APIPolicy object to be updated.
// @param elementID - The identifier of the rule element to be updated.
// @param fieldName - The name of the field to be updated in the rule.
// @param value - The new value to be assigned to the specified field.
// @returns A new APIPolicy object with the updated rule field.
function updatePolicyRuleField<T extends keyof APIRule>(policy: APIPolicy, elementID: number, fieldName: T, value: APIRule[T])
  {
  policy = Object.assign({}, policy); // Create a shallow copy of the policy object to avoid mutations
  policy.rules = policy.rules.slice(); // Create a shallow copy of the rules array to avoid mutations
  const ruleIndex = policy.rules.findIndex(r => r.element_id === elementID); // Find the index of the rule with the specified elementID in the policy rules array
  let rule: APIRule; // Declare a variable to hold the rule object

  // Check if the rule with the specified elementID exists;
  // If the rule does not exist, create a new rule object and add it to the policy rules array;
  // Otherwise, clone the existing rule object, Replace the existing rule in the policy rules array with the cloned rule
  if (ruleIndex === -1)
    {
    rule = { id: 0, element_id: elementID, attributes: [] };
    policy.rules.push(rule);
    }
  else
    {
    rule = Object.assign({}, policy.rules[ruleIndex]);
    policy.rules[ruleIndex] = rule;
    }

  // Update the specified field in the rule with the provided value
  rule[fieldName] = value;

  // Return a new APIPolicy object with the updated rules array
  return policy;
  }

// Check if a given group or attribute is hidden based on a list of hidden groups and attributes
const isGroupOrAttributeHidden = (hiddenGroupsAttributes: (APIElementGroup | APIAttribute)[], groupOrAttribute: APIElementGroup | APIAttribute) => hiddenGroupsAttributes.some((hiddenThing) => {return hiddenThing === groupOrAttribute});

interface PolicyRuleProps
  {
  dictionary: APIDictionary;
  rule: APIRule;
  // undefined - we are not currently comparing policies
  // APIRule - we are comparing against this rule
  // null - we are comparing, but the target policy is missing this rule
  comparisonRule?: APIRule | null;
  element: APIElement;
  group?: { name: string; rows: number };
  hidden: boolean;
  hiddenGroupsAttributes: (APIElementGroup | APIAttribute)[];
  selected: boolean;
  onSelectBegin?: () => void;
  onSelectEnd?: () => void;
  onSelectClear?: () => void;
  onCopyClick?: () => void;
  onPasteClick?: () => void;
  onChange: (elementID: number, attributeID: number, newVal: number) => void;
  onNoteChange: (newNote: string) => void;
  }

// these need to be in sync with the values in the database
export const RULE_ATTRIBUTES =
  {
  COLL: 1,
  VAL: 2,
  V3RQ: 3,
  SENS: 4,
  DG: 5,
  STORE: 6
  };

const COLL_DONT_COLLECT = 2;
//const VAL_V3 = 8;
//const SENS_S0 = 1;

export class PolicyRule extends React.Component<PolicyRuleProps>
  {
  render()
    {
    const columns = this.props.dictionary.rule_attributes.map(att => (!isGroupOrAttributeHidden(this.props.hiddenGroupsAttributes, att) ? <PolicyRuleCell key={att.id} rule={this.props.rule} comparisonRule={this.props.comparisonRule} attribute={att} onChange={(newVal) => this.props.onChange(this.props.rule.element_id, att.id, newVal)} /> : null));
    let comparisonColumns: React.ReactNode[] | null = null;
    // if the comparison rule is null (missing), then use a blank rule so that we render the default 0 value
    if (this.props.comparisonRule !== undefined)
      {
      const comparisonRule: APIRule = this.props.comparisonRule ?? {id: 0, element_id: this.props.rule.element_id, attributes: []};
      comparisonColumns = this.props.dictionary.rule_attributes.map(att => (!isGroupOrAttributeHidden(this.props.hiddenGroupsAttributes, att) ? <PolicyRuleCell key={att.id} rule={comparisonRule} attribute={att} onChange={(newVal) => null} /> : null));
      }

    return (<tr key={this.props.rule.id}>
      {this.props.group ? (<td align={!this.props.hidden ? "left" : "center"} className="element_group_name" rowSpan={!this.props.hidden ? this.props.group.rows : 1} colSpan={!this.props.hidden ? 1 : 4 + this.props.dictionary.rule_attributes.length - this.props.hiddenGroupsAttributes.filter(thing => this.props.dictionary.rule_attributes.some(att => att === thing)).length}><span>{this.props.group.name}</span></td>) : null}
      {!this.props.hidden ? (<td style={{backgroundColor: this.props.selected ? "lightblue" : "unset", userSelect: "none", cursor: "vertical-text", fontStyle: this.props.element.rr_controlled ? "italic" : "normal",}} onMouseDown={(e) => this.props.onSelectBegin?.()} onMouseUp={(e) => this.props.onSelectEnd?.()} onMouseMove={(e) => {if (e.buttons === 1) {console.log("mouse move"); this.props.onSelectEnd?.();}
        }}>{(this.props.element.rr_controlled ? "*" : "") + this.props.element.name}</td> ) : null }
      {!this.props.hidden ? <td>{this.props.dictionary.element_groups.map(eg => eg.elements.find(el => el.id === this.props.rule.element_id)?.element_category_name)}</td> : null}
      {!this.props.hidden ? columns : null}
      {!this.props.hidden ? <td><Note value={this.props.rule.notes ?? null} onChange={(note) => this.props.onNoteChange(note)} /></td> : null}
      {comparisonColumns && (!this.props.hidden || this.props.group)? (<td className="spacercol"></td>) : null}
      {!this.props.hidden ? comparisonColumns : null}
      {this.props.hidden && comparisonColumns && this.props.group ? <td align='center' className='element-group-name' colSpan={this.props.dictionary.rule_attributes.length - this.props.hiddenGroupsAttributes.filter(thing => this.props.dictionary.rule_attributes.some(att => att === thing)).length}><span>{this.props.group.name}</span></td> : null}
      </tr>);
    }
  // need this to prevent entire policy table from rendering when only one rule is changed
  shouldComponentUpdate(nextProps: PolicyRuleProps)
    {
    return !_.isEqual(this.props.rule, nextProps.rule) || (this.props.comparisonRule !== nextProps.comparisonRule) || (this.props.selected !== nextProps.selected) || (this.props.hiddenGroupsAttributes !== nextProps.hiddenGroupsAttributes);
    }
  }

interface PolicyRuleCellProps {
  rule: APIRule;
  comparisonRule?: APIRule | null;
  attribute: APIAttribute;
  onChange: (newVal: number) => void;
}

// split this logic out of PolicyRule so that it's easier to reuse when
// displaying two policies side by side
export function PolicyRuleCell(props: PolicyRuleCellProps) {
  const att = props.attribute;
  // if attribute value is missing, assume 0
  const ruleAtt = getAttributeFromRule(props.rule, att.id);
  const attValue = ruleAtt?.value ?? 0;
  let comparison: ComparisonResult = 'unknown';
  //let comparisonValueDebug: number = 0;
  // undefined = we are not currently comparing against anything
  // null = comparing, but missing rule (assume 0)
  if (props.comparisonRule !== undefined) {
    let comparisonValue: number = 0;
    // comparisonRule might be null, in which case assume all 0
    if (props.comparisonRule) {
      const comparisonRuleAtt = getAttributeFromRule(props.comparisonRule, att.id);
      comparisonValue = comparisonRuleAtt?.value ?? 0;
    }
    if (attValue === comparisonValue) {
      comparison = 'equal';
    } else if ((attValue | comparisonValue) === comparisonValue) {
      // rule's value is a subset of the value of the intersection of the rules we're comparing against
      comparison = 'subset';
    } else if ((attValue | comparisonValue) === attValue) {
      comparison = 'superset';
    } else {
      comparison = 'incompatible';
    }
    //comparisonValueDebug = comparisonValue;
  }
  //const attOption = att.values.find(v => v.value === attValue);
  // if this attribute is not collected, then don't bother showing the values of the other attributes
  if (att.id !== RULE_ATTRIBUTES.COLL) {
    const collAtt = getAttributeFromRule(props.rule, RULE_ATTRIBUTES.COLL);
    if (collAtt && collAtt.value === COLL_DONT_COLLECT) {
      return (<td className={comparison !== 'unknown' ? 'emptyAtt' : 'emptyComparisonAtt'} key={att.id} colSpan={1}></td>);
    }
  }
  return (
    <React.Fragment key={att.id}>
      <td className={comparison !== 'unknown' ? 'att' : 'comparisonAtt'}>
        <AttributeSelect
          value={attValue}
          onChange={props.onChange}
          attribute={att}
        />
        {comparison !== 'unknown' ? comparisonToIcon(comparison) : null}
      </td>
    </React.Fragment>
  );
}

function comparisonToIcon(cr: ComparisonResult): React.ReactNode {
  switch (cr) {
    case "equal":
      /*
      Steve's spreadsheet shows no icon if they're equal
      */
      return null;
    case "subset":
      return (
        <span
          style={{ color: "green", verticalAlign: "middle" }}
          className="material-icons-outlined"
          title="subset"
        >chevron_left</span>
      );
    case "superset":
      return (
        <span
          style={{ color: "red", verticalAlign: "middle" }}
          className="material-icons-outlined"
          title="superset"
        >chevron_right</span>
      );
    case "incompatible":
      return (
        <span
          style={{ color: "red", verticalAlign: "middle" }}
          className="material-icons-outlined"
          title="incompatible disjoint set"
        >close</span>
      );
    default:
      return null;
  }
}

// make a XHR to /api/policies
export async function getPolicy(policyID: number): Promise<APIPolicy> {
  const rawResponse = await fetch(`/api/policy/${policyID}`, {
    headers: getAPITokenHeaders()
  });
  const response = await rawResponse.json() as unknown as GetPolicy;
  if (response.status === 'success') {
    return response.policy;
  } else {
    throw new Error(response.error);
  }
}

// post policy JSON to /api/policy/:policyID
export function savePolicy(policy: APIPolicy): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/policy/${policy.id}`, {
      method: 'POST',
      headers: headers,
      // don't send the whole list of TLDs, just the ID of the list
      body: JSON.stringify(_.omit(policy, ['tlds']))
    })
      .then(async rawResponse => {
        const response = await rawResponse.json() as unknown as APISuccess | APIError;
        if (response.status === 'success') {
          return resolve(true);
        } else {
          return reject(false);
        }
      })
      .catch(error => reject(error));
  });
}

async function copyPolicy(policyID: number): Promise<number> {
  const rawResponse = await fetch(`/api/policy/copy/${policyID}`,
    {
      method: 'POST',
      headers: getAPITokenHeaders()
    });
  const response = await rawResponse.json() as unknown as NewPolicy | APIError;
  if (response.status === 'success') {
    return response.id;
  } else {
    throw new Error(response.error);
  }
}

async function deletePolicy(policyID: number): Promise<boolean> {
  const rawResponse = await fetch(`/api/policy/${policyID}`,
    {
      method: 'DELETE',
      headers: getAPITokenHeaders()
    });
  const response = await rawResponse.json() as unknown as { status: "success" } | APIError;
  if (response.status === 'success') {
    return true;
  } else {
    throw new Error(response.error);
  }
}

async function newPolicyVersion(policyID: number): Promise<number> {
  const rawResponse = await fetch(`/api/policy/new_version/${policyID}`,
    {
      method: 'POST',
      headers: getAPITokenHeaders(),
    });
  const response = await rawResponse.json() as unknown as NewPolicy | APIError;
  if (response.status === 'success') {
    return response.id;
  } else {
    throw new Error(response.error);
  }
}

function exportPolicy(policy: APIPolicy){
  let jsonString = JSON.stringify(policy);
  fileDownload(jsonString, `${policy.name}.policy`);
}

function mapPolicyIDsToName(policies: PolicyListItem[]) {
  const mapping: { [key: number]: PolicyListItem } = {};
  policies.forEach(p => mapping[p.id] = p);
  return mapping;
}

// given an orig list and an overlay list, return a new list that is orig
// overwritten with the selectedAtts from overlay. Used for pasting specific
// columns.
export function mergeAttributes(orig: APIRuleAttribute[], overlay: APIRuleAttribute[], selectedAtts: number[]) {
  return orig.filter(att => !selectedAtts.includes(att.attribute_id))
    .concat(overlay.filter(att => selectedAtts.includes(att.attribute_id)));
}
