import React from 'react';
import PolicyEditor from './PolicyEditor';
import LegendComponent from './LegendComponent';
import { APIDictionary, GetDataDictionary} from './common/api';
import LoadingSpinner from './LoadingSpinner';
import PolicyList from './PolicyList';
import RegistrantFactsEditor from './RegistrantFactsEditor';
import RegistrantFactsList from './RegistrantFactsList';
import RegistrationEditor from './RegistrationEditor';
import _ from 'lodash';
import ReactModal from 'react-modal';
import { clearPassword, getAPITokenHeaders, getPassword, storePassword } from './password';
import RequestTemplateEditor from './RequestTemplateEditor';
import Request from './Request';
import RequestTemplateList from './RequestTemplateList';
import RegistrationList from './RegistrationList';
//import RegistrationEditorOld from './RegistrationEditorOld';
import RegistrarGroupMembersList from './RegistrarGroupMembersList';
import RequesterEditor from './RequesterEditor';
import RequesterGroupEditor from './RequesterGroupEditor';
import RegistrarEditor from './RegistrarEditor';
import RegistrarGroupEditor from './RegistrarGroupEditor';

// Define application modes: mode includes the selected object ID so that we can set the state once and have a guarantee that we won't e.g. set the selected object ID without changing the mode
type AppMode =
  | { mode: "policy"; policyID: number }
  | { mode: "registrant_facts_list" }
  | { mode: "request_template_list" }
  | { mode: "registration_list" }
  | { mode: "registrar_group_list" }
  | { mode: "registrant_facts"; registrantFactsID: number }
  | { mode: "registration" | "registration_old"; registrationID: number }
  | { mode: "request_template" | "request"; requestTemplateID: number }
  | { mode: "requester"; requesterID: number }
  | { mode: "requester_group"; rqGID: number }
  | { mode: "registrar"; regID: number }
  | { mode: "registrar_group"; rrGID: number }
  | { mode: "default" };

// Define regex mappings for hash patterns to AppMode
const regexMappings: Array<[RegExp, (match: RegExpMatchArray) => AppMode]> =
  [
  [/(\/policy\/(\d+))$/, (match) => ({ mode: "policy", policyID: parseInt(match[2], 10) })],
  [/(\/registrant_facts\/)$/, () => ({ mode: "registrant_facts_list" })],
  [/(\/request_template_list\/)$/, () => ({ mode: "request_template_list" })],
  [/(\/registration_list\/)$/, () => ({ mode: "registration_list" })],
  [/(\/registrar_group_list\/)$/, () => ({ mode: "registrar_group_list" })],
  [/(\/registrant_facts\/(\d+))$/, (match) => ({ mode: "registrant_facts", registrantFactsID: parseInt(match[2], 10) })],
  //[/(\/registration_old\/(\d+))$/, (match) => ({ mode: "registration_old", registrationID: parseInt(match[2], 10) })],
  [/(\/registration\/(\d+))$/, (match) => ({ mode: "registration", registrationID: parseInt(match[2], 10) })],
  [/(\/request_template\/(\d+))$/, (match) => ({ mode: "request_template", requestTemplateID: parseInt(match[2], 10) })],
  [/(\/request\/(\d+))$/, (match) => ({ mode: "request", requestTemplateID: parseInt(match[2], 10) })],
  [/(\/requester\/(\d+))$/, (match) => ({ mode: "requester", requesterID: parseInt(match[2], 10) })],
  [/(\/requester_group\/(\d+))$/, (match) => ({ mode: "requester_group", rqGID: parseInt(match[2], 10) })],
  [/(\/registrar\/(\d+))$/, (match) => ({ mode: "registrar", regID: parseInt(match[2], 10) })],
  [/(\/registrar_group\/(\d+))$/, (match) => ({ mode: "registrar_group", rrGID: parseInt(match[2], 10) })],
  ];

// Function to get AppMode from the current hash
const getAppModeFromHash = ():AppMode =>
  {
  const hash = window.location.hash;
  for (const [regex, modeFn] of regexMappings) {const match = hash.match(regex); if (match) {return modeFn(match);}}
  return { mode: "default" };
  };

// Define the main App component
function App ()
  {
  // State variables for managing mode, data dictionary, and loading state
  const [mode, setMode] = React.useState<AppMode>({ mode: "default" });
  const [dataDictionary, setDataDictionary] = React.useState<APIDictionary | null>(null);
  const [isLoadingDD, setIsLoadingDD] = React.useState<boolean>(false);
  //const [forceRender, setForceRender] = React.useState<boolean>(false); // New state for forcing re-render; example: setForceRender(forceRender => !forceRender); // Toggle forceRender flag

  //UseEffect Hook: update app mode automatically when hash changes
  React.useEffect(() =>
    {
    // Function to handle hash change events
    const handleHashChange = () =>
      {
      const newMode = getAppModeFromHash();
      if (_.isEqual(newMode.mode, mode.mode))
        {
        for (const key in newMode)
          {
          if (newMode.hasOwnProperty(key) && mode.hasOwnProperty(key) && ((newMode as any)[key] !== (mode as any)[key]))
            {
            console.log("Window Hash unchanged, but property changed.");
            setMode(newMode);
            break;
            }
          }
        }
      else
        {
        setMode(newMode);
        }
      };

    // Add event listener for hash changes and clean up on component unmount
    window.addEventListener('hashchange', handleHashChange);
    return () => {window.removeEventListener('hashchange', handleHashChange);}; /* Cleans up event listener on component unmount */
    }, [mode]); // dependency variables

    React.useEffect(() =>
      {
      // Check if a hash is present in the URL
      const mode = getAppModeFromHash();
      console.log(mode, "UseEffect: Page refreshed or reloaded. App component mounted. App Mode set to current window.location.hash.");
      // Update app mode state
      setMode(mode);
      }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount


  // Password check modal if the password is not stored
  if (getPassword() === null)
    {
    return (<ReactModal isOpen={true} overlayClassName="passwordModalOverlay" className="passwordModalContent"><div style={{ textAlign: "center" }}> Please enter password:<br /><input type="password" id="api-password-input" autoFocus={true} onKeyUp={(e) => {if (e.key === "Enter") {const password = (document.getElementById("api-password-input") as HTMLInputElement).value; storePassword(password); window.location.reload(); /* TODO: tell the user if the password is wrong */ }}} /></div></ReactModal>);
    }



  // Fetch data dictionary and display loading spinner while loading
  if (dataDictionary === null && !isLoadingDD)
    {
    setIsLoadingDD(true);
    getDataDictionary().then(setDataDictionary).catch(e => alert(e)).finally(() => setIsLoadingDD(false));
    return (<LoadingSpinner />);
    }

  // render component based on app mode
  function renderContent()
    {
    if (dataDictionary === null) return <LoadingSpinner />;

    switch (mode.mode)
      {
      case 'policy': return <PolicyEditor policyID={mode.policyID} dataDictionary={dataDictionary} />;
      case 'registrant_facts': return <RegistrantFactsEditor dataDictionary={dataDictionary} selectedRegistrantFactsID={mode.registrantFactsID} />;
      case 'registrant_facts_list': return <RegistrantFactsList onSelectRFID={(newRegistrantFactsID) => {window.location.hash = `/registrant_facts/${newRegistrantFactsID}`;}} />;
      case 'registration': return <RegistrationEditor dataDictionary={dataDictionary} selectedRegistrationID={mode.registrationID} />;
      //case 'registration_old': return <RegistrationEditorOld dataDictionary={dataDictionary} selectedRegistrationID={mode.registrationID} />;
      case 'request_template': return <RequestTemplateEditor dataDictionary={dataDictionary} selectedRequestTemplateID={mode.requestTemplateID} />;
      case 'request': return (<Request dataDictionary={dataDictionary} selectedRequestTemplateID={mode.requestTemplateID} />);
      case 'registrar_group_list': return (<RegistrarGroupMembersList onSelectRequester = {(id) => {window.location.hash = `/requester/${id}`;}} onSelectRqG = {(id) => {window.location.hash = `/requester_group/${id}`;}} onSelectRegistrar = {(id) => {window.location.hash = `/registrar/${id}`;}} onSelectRrG = {(id) => {window.location.hash = `/registrar_group/${id}`;}} />);
      case 'requester': return (<RequesterEditor selectedRequesterID={mode.requesterID} />);
      case 'requester_group': return (<RequesterGroupEditor selectedRqGID={mode.rqGID} />);
      case 'registrar': return (<RegistrarEditor selectedRegID={mode.regID} />);
      case 'registrar_group': return (<RegistrarGroupEditor selectedRegistrarGroupID={mode.rrGID} />);
      case 'registration_list': return (<div className="grid-wrapper"><RegistrationList onSelectID={(id) => {window.location.hash = `/registration/${id}`;}} /><div className="sideNote"><LegendComponent /></div></div>);
      case 'request_template_list': return (<div className="grid-wrapper"><RequestTemplateList onSelectID={(id) => {window.location.hash = `/request_template/${id}`;}} /><div className="sideNote"><LegendComponent /></div></div>);
      default: return (<div className="grid-wrapper"><PolicyList onSelectPolicy={(newPolicyID) => {window.location.hash = `/policy/${newPolicyID}`;}} /><div className="sideNote"><LegendComponent /></div></div>);
      }
    }

  return <div className="App">{renderContent()}</div>;
  }

// make a XHR to /api/data_dictionary
async function getDataDictionary(): Promise<APIDictionary>
  {
  const rawResponse = await fetch('/api/data_dictionary', { headers: getAPITokenHeaders() });
  if (rawResponse.status === 401) {clearPassword(); throw new Error("Incorrect password");}
  const response = await rawResponse.json() as GetDataDictionary;
  return response.status === 'success' ? response.data : (() => {throw new Error(response.error);})();
  }

export default App;
