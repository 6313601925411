import React from 'react';
import { APIRegistrantFacts, GetRegistrantFactsList, NamedItem, NewPolicy } from './common/api';
import LoadingSpinner from './LoadingSpinner';
import navHeader from './NavHeader';
import { getAPITokenHeaders } from './password';

interface RegistrantFactsListProps {
  onSelectRFID: (newPolicyID: number) => void;
}

export default function RegistrantFactsList(props: RegistrantFactsListProps) {

  const [registrantFactsList, setRegistrantFactsList] = React.useState<NamedItem[] | null>(null);
  const [isLoadingList, setIsLoadingList] = React.useState<boolean>(false);

  if (registrantFactsList === null && !isLoadingList) {
    setIsLoadingList(true);
    getRegistrantFactsList().then(setRegistrantFactsList).finally(() => setIsLoadingList(false));
  }

  if (registrantFactsList === null) {
    return (<LoadingSpinner />);
  }

  return (
    <div> {navHeader()}
    <div className="registrant-facts-list">
      <h2>Registrant Facts Set List (RFSL)</h2>
      <button
        onClick={
          () => {
            newRegistrantFacts().then((newRFID) => window.location.hash = `/registrant_facts/${newRFID}`);
          }
        }>Create New Registrant Facts Set</button>
      <table style={{ width: "100%" }} className="registrant-facts-list-table">
        <tbody>
          <tr>
            <td style={{ textAlign: "left" }}>
              <b>Registrant Facts Set Name</b>
            </td>
            <td style={{ textAlign: "right" }}>
              <b>ID</b>
            </td>
          </tr>
          {
            registrantFactsList.sort((a, b) => a.id - b.id).map(fact => (
              <tr key={fact.id}>
                <td style={{ textAlign: "left" }}>
                  <a href={`#/registrant_facts/${fact.id}`}
                    onClick={() => props.onSelectRFID(fact.id)}
                  >
                    {fact.name}
                  </a>
                </td>
                <td style={{ textAlign: "right" }}>
                  {fact.id}
                </td>
              </tr>
            ))
          }
          {
            registrantFactsList.length === 0 ? (
              <tr><td colSpan={2}><em>No registrant facts</em></td></tr>
            ) : null
          }
        </tbody>
      </table>
    </div>
    </div>
  );
}

// make a XHR to /api/policies
export function getRegistrantFactsList(): Promise<NamedItem[]> {
  return new Promise((resolve, reject) => {
    fetch('/api/registrant_facts_index', {
      headers: getAPITokenHeaders()
    })
      .then(async rawResponse => {
        const response = await rawResponse.json() as unknown as GetRegistrantFactsList
        if (response.status === 'success') {
          return resolve(response.registrants);
        } else {
          return reject(response.error);
        }
      })
      .catch(error => reject(error));
  });
}

function newRegistrantFacts(): Promise<number> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/registrant_facts_new`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        id: 0,
        name: "Untitled Registrant Facts Set",
        facts: []
      } as APIRegistrantFacts)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as NewPolicy;
      console.log(response);
      if (response.status === 'success') {
        return resolve(response.id);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}
