import _ from 'lodash';
import React from 'react';
import { APIDictionary, APIRegistrantFactItem, APIRegistrantFacts, GetRegistrantFacts } from './common/api';
import LoadingSpinner from './LoadingSpinner';
import { getAPITokenHeaders } from './password';
import { APISuccess, APIError } from './common/api';
//import AttributeSelect from './AttributeSelect';

interface RegistrantFactsEditorProps {
  selectedRegistrantFactsID: number | null;
  dataDictionary: APIDictionary;
}

export default function RegistrantFactsEditor(props: RegistrantFactsEditorProps) {
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false);
  const [ registrantFacts, setRegistrantFacts ] = React.useState<APIRegistrantFacts | null>(null);

  if (isLoading) {
    return (<LoadingSpinner/>);
  }

  if (!registrantFacts || (
      registrantFacts.id !== props.selectedRegistrantFactsID && registrantFacts.id !== 0 && props.selectedRegistrantFactsID !== null
    )) {
    if (props.selectedRegistrantFactsID) {
      console.log("Initiating load of registrant facts");
      setIsLoading(true);
      // TODO: handle error case (shouldn't endlessly retry)
      getRegistrantFacts(props.selectedRegistrantFactsID).then(setRegistrantFacts).finally(() => setIsLoading(false));
    } else {
      console.log("Setting registrant facts to empty", registrantFacts);
      setRegistrantFacts({
        id: 0,
        name: "Untitled Registrant Facts Set",
        facts: []
      });
    }
    return null;
  }

  return (
    <div className="RegistrantFactsEditor">
      <input type="text" value={registrantFacts?.name || ""}
        onChange={(e) => {
          const newValue = e.target.value;
          const newFactObject: APIRegistrantFacts = _.clone(registrantFacts);
          newFactObject.name = newValue;
          setRegistrantFacts(newFactObject);
        }}
      />
      <button onClick={() => {
        saveRegistrantFacts(registrantFacts);
      }}>Save</button>
      <table>
        <thead>
          <tr>
            <th>Category</th>
            <th>Element</th>
            <th>Value</th>
            <th>V3RQ</th>
            <th>DG</th>
          </tr>
        </thead>
        <tbody>
          {
            props.dataDictionary.element_groups.map(eg => (
              eg.elements.map((el, i) => {
                const regFact = registrantFacts.facts.find(rf => rf.element_id === el.id);
                let groupCell: React.ReactNode = null;
                if (i === 0) {
                  groupCell = (
                    <td rowSpan={eg.elements.length}>{eg.name}</td>
                  );
                }
                return (
                  <tr key={"row" + i}>
                    {groupCell}
                    <td>{el.name}</td>
                    <td>
                      <input type="text" value={regFact?.value || ""}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          // empty string removes the value
                          if (newValue.trim() === '') {
                            return setRegistrantFacts(
                              Object.assign({},
                                registrantFacts,
                                { facts: registrantFacts.facts.filter(rf => rf.element_id !== el.id) }
                              )
                            );
                          } else {
                            const existingFactIndex = registrantFacts.facts.findIndex(rf => rf.element_id === el.id);
                            const newFactObject: APIRegistrantFacts = _.clone(registrantFacts);
                            newFactObject.facts = newFactObject.facts.slice();
                            // if modifying an existing fact, update it
                            if (existingFactIndex !== -1) {
                              const newFact = Object.assign({}, registrantFacts.facts[existingFactIndex], { value: newValue });
                              newFactObject.facts[existingFactIndex] = newFact;
                            } else { // otherwise, add it to the set of facts
                              const newFact: APIRegistrantFactItem = {
                                element_id: el.id,
                                value: newValue,
                                V3RQ: "default",
                                DG: "no dg"
                              };
                              newFactObject.facts.push(newFact);
                            }
                            setRegistrantFacts(newFactObject);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <select defaultValue={registrantFacts.facts[registrantFacts.facts.findIndex(rf => rf.element_id === el.id)]?.V3RQ || "default"}
                        onChange={(e) => {
                          const V3RQ_value = e.target.value;
                          const existingFactIndex = registrantFacts.facts.findIndex(rf => rf.element_id === el.id);
                          const newFactObject: APIRegistrantFacts = _.clone(registrantFacts);
                          newFactObject.facts = newFactObject.facts.slice();
                          // if modifying an existing fact, update it
                          if (existingFactIndex !== -1) {
                            const newFact = Object.assign({}, registrantFacts.facts[existingFactIndex], { V3RQ: V3RQ_value });
                            newFactObject.facts[existingFactIndex] = newFact;
                          }
                          setRegistrantFacts(newFactObject);
                        }}
                        >
                          <option value="default">Default</option>
                          <option value="V3 please">V3 Please</option>
                          <option value="ø">ø</option>
                      </select>
                    </td>
                    <td>
                      <select defaultValue={registrantFacts.facts[registrantFacts.facts.findIndex(rf => rf.element_id === el.id)]?.DG || "NODG"}
                        onChange={(e) => {
                          const DG_value = e.target.value;
                          const existingFactIndex = registrantFacts.facts.findIndex(rf => rf.element_id === el.id);
                          const newFactObject: APIRegistrantFacts = _.clone(registrantFacts);
                          newFactObject.facts = newFactObject.facts.slice();
                          // if modifying an existing fact, update it
                          if (existingFactIndex !== -1) {
                            const newFact = Object.assign({}, registrantFacts.facts[existingFactIndex], { DG: DG_value });
                            newFactObject.facts[existingFactIndex] = newFact;
                          }
                          setRegistrantFacts(newFactObject);
                        }}
                        >
                          <option value="no DG">No DG</option>
                          <option value="DG please">DG Please</option>
                          <option value="ø">ø</option>
                      </select>
                    </td>
                  </tr>
                );
              }
            )))
          }
        </tbody>
      </table>
    </div>
  );
}


export function getRegistrantFacts(rfID: number): Promise<APIRegistrantFacts> {
  return new Promise((resolve, reject) => {
    fetch(`/api/registrant_facts/${rfID}`, {
      headers: getAPITokenHeaders()
    })
      .then(async rawResponse => {
        const response = await rawResponse.json() as unknown as GetRegistrantFacts;
        if (response.status === 'success') {
          return resolve(response.registrant_facts);
        } else {
          return reject(response.error);
        }
      })
      .catch(error => reject(error));
  });
}

export function saveRegistrantFacts(facts: APIRegistrantFacts): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/registrant_facts/${facts.id}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(facts)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}
