import React from 'react';
import { PolicyListItem } from './common/api';
import { ORG_TYPE_ABBR, timeDifference } from './common/util';
import LoadingSpinner from './LoadingSpinner';
import { getPolicyList } from './policyData';

// Props for the MultiSelectPolicyList component
interface MultiSelectPolicyListProps
  {
  onSelectPolicy: (policy: PolicyListItem) => void;
  selectedPolicies?: number[];
  }

// Functional component for a multi-select policy list
export default function MultiSelectPolicyList(props: MultiSelectPolicyListProps)
  {
  // State for storing the policy list and loading status
  const [policyList, setMultiSelectPolicyList] = React.useState<PolicyListItem[] | null>(null);
  const [isLoadingList, setIsLoadingList] = React.useState<boolean>(false);

  // If the policy list is not loaded and no loading is in progress, initiate loading
  if (policyList === null && !isLoadingList)
    {
    setIsLoadingList(true);
    getPolicyList().then(setMultiSelectPolicyList).finally(() => setIsLoadingList(false));
    }

  // If the policy list is still loading, display a loading spinner
  if (policyList === null) return (<LoadingSpinner />);

  // Render the policy list as a table
  return (<div className="policy-list"><table style={{ width: "100%" }} className="policy-list-table"><tbody>{policyList.map(policy => (<tr key={policy.id} onClick={() => props.onSelectPolicy(policy)} style={{cursor: "pointer"}}>{props.selectedPolicies ? (<td><input type="checkbox" checked={props.selectedPolicies.includes(policy.id)} readOnly /></td>) : null}<td><span className="org-type-badge">{ORG_TYPE_ABBR[policy.org_type]}</span>{policy.name} (v{policy.version})</td><td style={{ textAlign: "right" }}> Edited {timeDifference(Date.now() / 1000, policy.modified)}</td></tr>))}</tbody></table></div>);
  }
