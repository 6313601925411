import React from 'react';
import ReactModal from 'react-modal';
import AttributeSelect from './AttributeSelect';

import { APIAttribute, APIRuleAttribute, APITLDList } from "./common/api";
import TLDListSelector from './TLDListSelector';


interface ScopeProps {
  scopeAttributes: APIAttribute[];
  selectedScopeAttributes: APIRuleAttribute[];
  tldList: APITLDList | null;
  defaultDropdown: number;
  onAttributeChange: (attributeID: number, newValue: number) => void;
  onTLDListChange?: (tldList: APITLDList) => void;
}

export default function Scope(props: ScopeProps) {
  const attributeRows = props.scopeAttributes.map((att) => {
    const attValue = props.selectedScopeAttributes.find(sa => sa.attribute_id === att.id);
    if (!attValue) {
      props.onAttributeChange(att.id, props.defaultDropdown);
    }
    return (
      <tr key={att.id}>
        <td>{att.name}</td>
        <td>
          <AttributeSelect
            attribute={att}
            value={attValue?.value ?? props.defaultDropdown}
            onChange={(newVal) => props.onAttributeChange(att.id, newVal)}
          />
        </td>
      </tr>
    );
  }
  );
  return (
    <table
      className="PolicyEditor-scope"
      // we'll leave it up to the container to constrain the width of this table
      style={{ width: "100%", maxWidth: "100%" }}
    >
      <tbody>
        <tr>
          <td>PSLs</td>
          <td>
            <TLDItem tldList={props.tldList} onTLDListChange={props.onTLDListChange} />
          </td>
        </tr>
        {attributeRows}
      </tbody>
    </table>
  );
}

interface TLDItemProps {
  tldList: APITLDList | null;
  onTLDListChange?: (tldList: APITLDList) => void;
  isButtonDisplayed?: boolean;
}

export function TLDItem(props: TLDItemProps): React.ReactElement {
  const [isSelectingTLDList, setIsSelectingTLDList] = React.useState(false);
  return (
    <div>
      {(props.isButtonDisplayed ?? true) && (
        <button onClick={() => setIsSelectingTLDList(true)}
        style={{ float: "right" }}>
        Choose...
      </button>
      )}
      {
        props.tldList && props.tldList.tlds.length > 0 ?
          (
            <>
              <strong>{props.tldList.name}</strong>
              <br />
              <ElidedList list={props.tldList.tlds} />
            </>
          ) :
          <em>None</em>
      }
      <ReactModal
        isOpen={isSelectingTLDList}
        onRequestClose={() => setIsSelectingTLDList(false)}
        ariaHideApp={false}
      >
      <TLDListSelector
        value={props.tldList?.id ?? null}
        onSelect={(newTLDList) => {
          props.onTLDListChange?.(newTLDList);
          setIsSelectingTLDList(false);
        }}
      />
      </ReactModal>
    </div>
  );
}

interface ElidedListProps {
  list: string[];
  maxItems?: number;
}

function ElidedList(props: ElidedListProps): React.ReactElement | null {
  const [ collapsed, setCollapsed ] = React.useState(true);

  const maxItems = props.maxItems || 10;

  if (props.list.length > maxItems) {
    if (collapsed) {
    const subList = props.list.slice(0, maxItems);
      return (
        <>
          {subList.join(", ")},&nbsp;
          <a href="/#"
            style={{ fontStyle: "italic" }}
            onClick={(e) => {
              e.preventDefault();
              setCollapsed(false);
            }}>... ({props.list.length - maxItems} more)</a>
        </>
      );
    } else {
      return (
        <>
          {props.list.join(", ")}&nbsp;
          <a href="/#"
            style={{ fontStyle: "italic" }}
            onClick={(e) => {
              e.preventDefault();
              setCollapsed(true);
            }}>(less)</a>
        </>
      );
    }
  } else {
    return <>{props.list.join(", ")}</>;
  }
}
