import React from 'react';
import { APIRegistrantFacts, APIRegistration, GetRegistrations, NewPolicy } from './common/api';
import LoadingSpinner from './LoadingSpinner';
import navHeader from './NavHeader';
import { clearPassword, getAPITokenHeaders } from './password';

interface RegistrationListProps {
    onSelectID: (id: number) => void;
}

export default function RegistrationList(props: RegistrationListProps) {
    const [registrationList, setRegistrationList] = React.useState<APIRegistration[] | null>(null);
    const [isLoadingList, setIsLoadingList] = React.useState<boolean>(false);
  
    if (registrationList === null && !isLoadingList) {
      setIsLoadingList(true);
      getRegistrations().then(setRegistrationList).finally(() => setIsLoadingList(false));
    }
  
    if (registrationList === null) {
      return (<LoadingSpinner />);
    }
  

    return (
        <div> {navHeader()}
        <div>
            <h2>Registration List</h2>
            <button
            onClick={
              () => {
                newRegistrantFacts().then(facts_id => newRegistration(facts_id).then((id) => window.location.hash = `/registration/${id}`));
              }
            }>Create New Registration</button>
            <table style={{ width: "100%" }} className="policy-list-table">
            <tbody>
            {
                registrationList.map(registration => (
                <tr key={registration.id}
                    onClick={() => props.onSelectID(registration.id)}
                >
                    <td>
                    <a href={`#/registration/${registration.id}`}
                        onClick={() => props.onSelectID(registration.id)}
                    >
                        {registration.name}
                    </a>
                    <br />
                    </td>
                </tr>
                ))
            }
            </tbody>
        </table>
      </div>
      </div>
    );
}

async function getRegistrations(): Promise<APIRegistration[]> {
  const rawResponse = await fetch('/api/request_query_all', {
    headers: getAPITokenHeaders()
  });
  if (rawResponse.status === 401) {
    clearPassword();
    throw new Error("Incorrect password");
  }
  const response = await rawResponse.json() as unknown as GetRegistrations;
  if (response.status === 'success') {
    return response.registrations;
  } else {
    throw new Error(response.error);
  }
}

function newRegistration(facts_id: number): Promise<number> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/registration_new`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        id: 0,
        name: "Untitled Registration",
        registrant_facts_id: facts_id,
        policy_id: -1
      } as APIRegistration)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as NewPolicy;
      console.log(response);
      if (response.status === 'success') {
        return resolve(response.id);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

function newRegistrantFacts(): Promise<number> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/registrant_facts_new`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        id: 0,
        name: "Untitled Registrant Facts Set",
        facts: []
      } as APIRegistrantFacts)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as NewPolicy;
      console.log(response);
      if (response.status === 'success') {
        return resolve(response.id);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}
