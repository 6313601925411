import React from 'react';
import { APIRequester, NewPolicy} from './common/api';
import { /*clearPassword,*/ getAPITokenHeaders } from './password';

interface RequesterListProps {
  requesters: APIRequester[],
  onSelectRequester: (newRequesterID: number) => void
}

export default function RequesterList(props: RequesterListProps) {
  return (
    <div>
      <h2>Requesters</h2>
      <button
        onClick={
          () => {
            newRequester().then((id) => window.location.hash = `/requester/${id}`);
          }
        }>Create New Requester</button>
      <table style={{ width: "100%" }} className="policy-list-table">
          <tbody>
          {
              props.requesters.map(req => (
              <tr key={req.id}
                  onClick={() => props.onSelectRequester(req.id)}>
                  <td>
                  <a href={`#/requester/${req.id}`}
                      onClick={() => props.onSelectRequester(req.id)}>
                      {req.name}
                  </a>
                  <br />
                  </td>
              </tr>
              ))
          }
          </tbody>
      </table>
    </div>
  );
}

function newRequester(): Promise<number> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/requester`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify({
        id: 0,
        name: "Unnamed Requester"
      } as APIRequester)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as NewPolicy;
      if (response.status === 'success') {
        return resolve(response.id);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}
