import React from "react";
import ReactModal from "react-modal";
import CloseButton from "./CloseButton";
import {APIElementGroup, APIAttribute, APIDictionary} from "./common/api"

// Define the props interface for the hideGroupsAttributesModal component
interface hideGroupsAtrributesProps
  {
  hiddenGroupsAttributes: (APIElementGroup | APIAttribute)[];
  updateHidden: (newHidden: (APIElementGroup | APIAttribute)[]) => void;
  dict: APIDictionary;
  isOpen: boolean;
  close: () => void;
  }

// Define the state interface for the hideGroupsAttributesModal component
interface hideGroupsAttributesState
  {
  newHidden: (APIElementGroup | APIAttribute)[];
  }

// Function to update the list of hidden groups/attributes based on whether they are being hidden or not
function updateHiddenGroupsAttributes(hiddenThings: (APIElementGroup | APIAttribute)[], thing: APIElementGroup | APIAttribute, isHidden: boolean)
  {
  let newHiddenGroupsColumns = hiddenThings.filter((groupOrAttribute) => {return groupOrAttribute !== thing});
  if (isHidden) { newHiddenGroupsColumns.push(thing); }
  return newHiddenGroupsColumns;
  }

// Function to check if a group/attribute is hidden
function isGroupOrAttributeHidden(hiddenGroupsAttributes: (APIElementGroup | APIAttribute)[], groupOrAttribute: APIElementGroup | APIAttribute)
  {
  return hiddenGroupsAttributes.some((hiddenThing) => hiddenThing === groupOrAttribute);
  }

// Define and export the hideGroupsAttributesModal component
export default class hideGroupsAttributesModal extends React.Component<hideGroupsAtrributesProps, hideGroupsAttributesState>
  {
  // Initialize state with the provided hidden groups/attributes
  constructor(props: hideGroupsAtrributesProps)
    {
    super(props);
    this.state = {newHidden: props.hiddenGroupsAttributes,};
    };

  // Function to set the new hidden groups/attributes in the component state
  setHidden = (newNewHidden: (APIElementGroup | APIAttribute)[]) => {this.setState({ newHidden: newNewHidden })};

  // Render the component
  render()
    {
    return(<ReactModal isOpen={this.props.isOpen} onRequestClose={() => {this.props.updateHidden(this.state.newHidden); this.props.close();}} appElement={document.getElementById('root') ?? undefined}><CloseButton closeFunc={ () => {this.props.updateHidden(this.state.newHidden); this.props.close();}}/><table><tbody><tr><td style={{ border: 'none' }}><p style={{ textAlign: 'center' }}>Select Attributes to Show:</p><table className='PolicyEditor-table'><tbody>{this.props.dict.rule_attributes.map(att => (<tr key={att.id}><td className='checkbox'><input type='checkbox' onChange={(e) => this.setHidden(updateHiddenGroupsAttributes(this.state.newHidden, att, !e.target.checked))} checked={!isGroupOrAttributeHidden(this.state.newHidden, att)}/></td><td>{att.name}</td></tr>))}</tbody></table></td><td style={{ border: 'none' }}><p style={{ textAlign: 'center' }}>Select Groups to Show:</p><table className='PolicyEditor-table'><tbody>{this.props.dict.element_groups.map(eg => (<tr key={eg.id}><td className='checkbox'><input type='checkbox' onChange={(e) => this.setHidden(updateHiddenGroupsAttributes(this.state.newHidden, eg, !e.target.checked))} checked={!isGroupOrAttributeHidden(this.state.newHidden, eg)}/></td><td>{eg.name}</td></tr>))}</tbody></table></td></tr></tbody></table></ReactModal>)
    }
  }
