import React from 'react';
import { APIAttribute, APIAttributeOption } from './common/api';
import { contrastColor } from './webutils';

// Props for AttributeSelect Component
interface AttributeSelectProps
  {
  attribute: APIAttribute | undefined;
  value: number;
  onChange: (newValue: number) => void;
  alternateDropdown?: APIAttributeOption[];
  }

// Component for rendering an attribute select dropdown
export default function AttributeSelect(props: AttributeSelectProps)
  {
  // State to track whether the dropdown is active
  const [isActive, setIsActive] = React.useState(false);
  // Check if the attribute is defined, log a message if not
  if (!props.attribute) {return <></>;}
  // Extract attribute information and values
  const att = props.attribute;
  const attOption = att.values.find((v) => v.value === props.value) ?? att.values[0];
  const dropDownVals = props.alternateDropdown ?? att.values;

  /* Container with tabindex for focus handling */
  return (<><div tabIndex={0} onBlur={() => setIsActive(false)} style={{ display: 'inline-block' }}>
    {/* Display the selected attribute value */}
    <span className="attribute-value" style={{backgroundColor: `#${attOption?.color}`, color: attOption ? contrastColor(attOption.color) : 'red', cursor: 'cell', display: 'inline-block', padding: '4px', textAlign: 'center', minWidth: '16px', borderRadius: '4px', outline: '1px solid #000000', outlineOffset: '-1px',}} onClick={() => setIsActive(!isActive)}>{attOption?.name}</span>
    {/* Render the dropdown if it is active */}
    {isActive ? (<div className="drop-down" style={{ position: 'absolute' }}><ul style={{background: 'white', border: '1px solid #ccc', cursor: 'default', listStyle: 'none', padding: 0, boxShadow: '0 0 10px rgba(0, 0, 0, 0.4)', borderRadius: '4px',}}>{/* Render dropdown items */ dropDownVals.map((opt) => (<li key={opt.value} className="drop-down-item" style={{backgroundColor: `#${opt.color}`, color: contrastColor(opt.color), padding: '4px', userSelect: 'none',}} onClick={() => {props.onChange(opt.value); setIsActive(false);}}>{opt.name}</li>))}</ul></div>) : null}
    </div></>);
  }
