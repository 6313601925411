//import _ from "lodash";
import React from "react";
import { APIRegistrar, APIRegistrarGroup, APIRequestTemplate, APIRequesterGroup } from "./common/api";
import LoadingSpinner from "./LoadingSpinner";
import { getRegistrarGroupMembers } from "./RegistrarGroupMembersList";
import { getRequestTemplates } from "./RequestTemplateList";

interface RrGListSelectorProps {
  rrGID: number;
  membersAlreadyJoined: APIRegistrarGroup;
  addingOrRemoving: "adding" | "removing";
  onSubmit: (newRqG: APIRegistrarGroup) => void;
}

interface RrGListSelectorState {
  changedMembers: APIRegistrarGroup;
  allRqGs: APIRequesterGroup[] | null;
  allRrs: APIRegistrar[] | null;
  allReqTemps: APIRequestTemplate[] | null;
}

export default class RrGListSelector extends React.Component<RrGListSelectorProps, RrGListSelectorState> {
  constructor(props: RrGListSelectorProps) {
    super(props);
    this.state = {
      changedMembers: {
        id: this.props.rrGID,
        description: this.props.membersAlreadyJoined?.description,
        obligations: this.props.membersAlreadyJoined?.obligations,
        requester_group_ids: [],
        registrar_ids: [],
        request_template_ids: [],
      },
      allRqGs: null,
      allRrs: null,
      allReqTemps: null,
    };
    getRegistrarGroupMembers().then(registrarGroupMembers => this.setState({
      allRqGs: registrarGroupMembers.requester_groups,
      allRrs: registrarGroupMembers.registrars
    }));
    getRequestTemplates().then(reqTemps => this.setState({
      allReqTemps: reqTemps
    }));
  }

  render() {

    return (
      <div className="TLDListSelector">
        <h2>Select members to {this.props.addingOrRemoving==="adding" ? "add to" : "remove from"} this registrar group</h2>
        {
          this.state.allRqGs && this.state.allRrs && this.state.allReqTemps ? (
            <div>
              <table>
                  {this.props.addingOrRemoving === "adding" ?
                    <div>
                      <div style={{width: "33%", float: "left"}}>
                        <p>Requester Groups</p>
                        <table style={{ width: "95%" }}>
                          <tbody>
                            {
                              this.state.allRqGs.map(rqG =>
                                this.props.membersAlreadyJoined.requester_group_ids.some(rqGJoined =>
                                  rqGJoined === rqG.id) ? null : ( //Show possible group members that have not joined on joining
                                <tr tabIndex={0}
                                  style={{backgroundColor: this.state.changedMembers.requester_group_ids.some(sRqG => sRqG === rqG.id) ? "#afa" : undefined }}
                                >
                                  <td style={{ borderWidth: ".5px 0" }}>
                                    <label>
                                      <input type="checkbox"
                                        onClick={(e) => {
                                          const rqGPosInSelected = this.state.changedMembers.requester_group_ids.indexOf(rqG.id);
                                          if (rqGPosInSelected === -1){
                                            this.state.changedMembers.requester_group_ids.push(rqG.id);
                                          } else {
                                            this.state.changedMembers.requester_group_ids.splice(rqGPosInSelected, 1);
                                          }
                                          this.setState({
                                            changedMembers: this.state.changedMembers
                                          });
                                        }}/>
                                      {rqG.name}
                                    </label>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                      <div style={{width: "33%", float: "left"}}>
                        <p>Registrars</p>
                        <table style={{ width: "95%" }}>
                          <tbody>
                            {
                              this.state.allRrs.map(rr =>
                                this.props.membersAlreadyJoined.registrar_ids.some(rrJoined =>
                                  rrJoined === rr.id) ? null : (
                                <tr tabIndex={0}
                                  style={{backgroundColor: this.state.changedMembers.registrar_ids.some(sRr => sRr === rr.id) ? "#afa" : undefined }}
                                >
                                  <td style={{ borderWidth: ".5px 0" }}>
                                    <label>
                                      <input type="checkbox"
                                        onClick={(e) => {
                                          const rrPosInSelected = this.state.changedMembers.registrar_ids.indexOf(rr.id);
                                          if (rrPosInSelected === -1){
                                            this.state.changedMembers.registrar_ids.push(rr.id);
                                          } else {
                                            this.state.changedMembers.registrar_ids.splice(rrPosInSelected, 1);
                                          }
                                          this.setState({
                                            changedMembers: this.state.changedMembers
                                          });
                                        }}/>
                                      {rr.name}
                                    </label>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                      <div style={{width: "33%", float: "left"}}>
                        <p>Request Templates</p>
                        <table style={{ width: "95%" }}>
                          <tbody>
                            {
                              this.state.allReqTemps.map(reqTemp =>
                                this.props.membersAlreadyJoined.request_template_ids.some(reqTempJoined =>
                                  reqTempJoined === reqTemp.id) ? null : (
                                <tr tabIndex={0}
                                  style={{backgroundColor: this.state.changedMembers.request_template_ids.some(sReqTemp => sReqTemp === reqTemp.id) ? "#afa" : undefined }}
                                >
                                  <td style={{ borderWidth: ".5px 0" }}>
                                    <label>
                                      <input type="checkbox"
                                        onClick={(e) => {
                                          const reqTempPosInSelected = this.state.changedMembers.request_template_ids.indexOf(reqTemp.id);
                                          if (reqTempPosInSelected === -1){
                                            this.state.changedMembers.request_template_ids.push(reqTemp.id);
                                          } else {
                                            this.state.changedMembers.request_template_ids.splice(reqTempPosInSelected, 1);
                                          }
                                          this.setState({
                                            changedMembers: this.state.changedMembers
                                          });
                                        }}/>
                                      {reqTemp.name}
                                    </label>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </div> :
                    <div>
                      <div style={{width: "33%", float: "left"}}>
                        <p>Requester Groups</p>
                        <table style={{ width: "95%" }}>
                          <tbody>
                            {
                              this.state.allRqGs.map(rqG =>
                                this.props.membersAlreadyJoined.requester_group_ids.some(rqGJoined =>
                                  rqGJoined === rqG.id) ? ( //Show registrar group members that have already joined on leaving
                                <tr tabIndex={0}
                                  style={{backgroundColor: this.state.changedMembers.requester_group_ids.some(sRqG => sRqG === rqG.id) ? "#afa" : undefined }}
                                >
                                  <td style={{ borderWidth: ".5px 0" }}>
                                    <label>
                                      <input type="checkbox"
                                        onClick={(e) => {
                                          const rqGPosInSelected = this.state.changedMembers.requester_group_ids.indexOf(rqG.id);
                                          if (rqGPosInSelected === -1){
                                            this.state.changedMembers.requester_group_ids.push(rqG.id);
                                          } else {
                                            this.state.changedMembers.requester_group_ids.splice(rqGPosInSelected, 1);
                                          }
                                          this.setState({
                                            changedMembers: this.state.changedMembers
                                          });
                                        }}/>
                                      {rqG.name}
                                    </label>
                                  </td>
                                </tr>
                              ) : null)
                            }
                          </tbody>
                        </table>
                      </div>
                      <div style={{width: "33%", float: "left"}}>
                        <p>Registrars</p>
                        <table style={{ width: "95%" }}>
                          <tbody>
                            {
                              this.state.allRrs.map(rr =>
                                this.props.membersAlreadyJoined.registrar_ids.some(rrJoined =>
                                  rrJoined === rr.id) ? (
                                <tr tabIndex={0}
                                  style={{backgroundColor: this.state.changedMembers.registrar_ids.some(sRr => sRr === rr.id) ? "#afa" : undefined }}
                                >
                                  <td style={{ borderWidth: ".5px 0" }}>
                                    <label>
                                      <input type="checkbox"
                                        onClick={(e) => {
                                          const rrPosInSelected = this.state.changedMembers.registrar_ids.indexOf(rr.id);
                                          if (rrPosInSelected === -1){
                                            this.state.changedMembers.registrar_ids.push(rr.id);
                                          } else {
                                            this.state.changedMembers.registrar_ids.splice(rrPosInSelected, 1);
                                          }
                                          this.setState({
                                            changedMembers: this.state.changedMembers
                                          });
                                        }}/>
                                      {rr.name}
                                    </label>
                                  </td>
                                </tr>
                              ) : null)
                            }
                          </tbody>
                        </table>
                      </div>
                      <div style={{width: "33%", float: "left"}}>
                        <p>Request Templates</p>
                        <table style={{ width: "95%" }}>
                          <tbody>
                            {
                              this.state.allReqTemps.map(reqTemp =>
                                this.props.membersAlreadyJoined.request_template_ids.some(reqTempJoined =>
                                  reqTempJoined === reqTemp.id) ? (
                                <tr tabIndex={0}
                                  style={{backgroundColor: this.state.changedMembers.request_template_ids.some(sReqTemp => sReqTemp === reqTemp.id) ? "#afa" : undefined }}
                                >
                                  <td style={{ borderWidth: ".5px 0" }}>
                                    <label>
                                      <input type="checkbox"
                                        onClick={(e) => {
                                          const reqTempPosInSelected = this.state.changedMembers.request_template_ids.indexOf(reqTemp.id);
                                          if (reqTempPosInSelected === -1){
                                            this.state.changedMembers.request_template_ids.push(reqTemp.id);
                                          } else {
                                            this.state.changedMembers.request_template_ids.splice(reqTempPosInSelected, 1);
                                          }
                                          this.setState({
                                            changedMembers: this.state.changedMembers
                                          });
                                        }}/>
                                      {reqTemp.name}
                                    </label>
                                  </td>
                                </tr>
                              ) : null)
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    }
              </table>
              <button
              onClick={() => {
                this.props.onSubmit(this.state.changedMembers);
                window.location.reload();
              }}>
              Update Registrar Group!
            </button>
            </div>
          ) : <LoadingSpinner />
        }
      </div>
    );
  }
}
