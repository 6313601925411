import React, { useState, useEffect, useRef } from "react";

export interface EditableLabelProps {
  value: string;
  onValueChange: (newValue: string) => void;
  allowNewline?: boolean;
  className?: string;
  style?: React.CSSProperties;
  width?: string;
}

const EditableLabel: React.FC<EditableLabelProps> = (props) =>
  {
  const [text, setText] = useState(props.value);
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setText(props.value);
    if (spanRef.current) {
      spanRef.current.textContent = props.value;
    }
  }, [props.value]);

  const textEdited = (newText: string) => {
    const cleanedText = props.allowNewline ? newText : newText.replace(/\r?\n|\r/g, '');
    setText(cleanedText);
  };

  const handleInput = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    const newText = e.currentTarget.textContent || "";
    textEdited(newText);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (!props.allowNewline && e.key === 'Enter') {
      e.preventDefault();
      e.currentTarget.blur(); // Trigger blur when Enter is pressed
    }
  };

  const handleBlur = () => {
    props.onValueChange(text);
  };

  return (
    <span
      ref={spanRef}
      onInput={handleInput}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      className={props.className}
      contentEditable={true}
      style={props.style}
    />
    );
  };

export default EditableLabel;
