//import _ from "lodash";
import React from "react";
import { APIRequester /*, APIRequesterGroupMembership*/ } from "./common/api";
import LoadingSpinner from "./LoadingSpinner";
//import { getRegistrarGroupMembers } from "./RegistrarGroupMembersList";

interface RequesterListSelectorProps {
  rqGID: number | null;
  members: APIRequester[] | null;
  onSelect: (requester: APIRequester) => void;
}

interface RequesterListSelectorState {
  requesters: APIRequester[] | null;
}

export default class RequesterListSelector extends React.Component<RequesterListSelectorProps, RequesterListSelectorState> {
  constructor(props: RequesterListSelectorProps) {
    super(props);
    this.state = {
      requesters: this.props.members
    };
  }

  render() {

    return (
      <div className="TLDListSelector">
        <h2>Choose a requester to remove from this requester group</h2>
        {
          this.state.requesters !== null ?
            (
              <table className="TLDListSelector-table">
                <tbody>
                  {this.state.requesters.map(req =>
                      <tr tabIndex={0}
                        onClick={() => {
                          this.props.onSelect(req);
                          window.location.reload();
                        }}
                      >
                        <td>{req.name}</td>
                      </tr>
                  )}
                </tbody>
              </table>
            )
            :
            <LoadingSpinner />
        }
      </div>
    );
  }
}
