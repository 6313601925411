//import _ from "lodash";
import React from "react";
import { APIRequesterGroup, APIRegistrar, APIRequestTemplate, APISuccess, APIError } from "./common/api";
import LoadingSpinner from "./LoadingSpinner";
import { getRegistrarGroupMembers } from "./RegistrarGroupMembersList";
import { getRequestTemplates } from "./RequestTemplateList";
import { getAPITokenHeaders } from "./password";

interface RegistrarGroupCreatorProps {
  currentObjID: number;
  currentObjType: "RqG" | "Rr" //Either create a registrar group from a requester group or a registrar
}

interface RegistrarGroupCreatorState {
  description: string;
  requesterGroups: APIRequesterGroup[] | null;
  registrars: APIRegistrar[] | null;
  requestTemplates: APIRequestTemplate[] | null;
  selectedRqGs: number[];
  selectedRrs: number[];
  selectedReqTemps: number[];
}

export default class RegistrarGroupCreator extends React.Component<RegistrarGroupCreatorProps, RegistrarGroupCreatorState> {
  constructor(props: RegistrarGroupCreatorProps) {
    super(props);
    this.state = {
      description: "",
      requesterGroups: null,
      registrars: null,
      requestTemplates: null,
      selectedRqGs: this.props.currentObjType === "RqG" ? [this.props.currentObjID] : [],
      selectedRrs: this.props.currentObjType === "Rr" ? [this.props.currentObjID] : [],
      selectedReqTemps: [],
    };
    getRegistrarGroupMembers().then(rrGMembers => this.setState({
      requesterGroups: rrGMembers.requester_groups,
      registrars: rrGMembers.registrars,
    }));
    getRequestTemplates().then(reqTemps => this.setState({
      requestTemplates: reqTemps,
    }));
  }

  render() {

    return (
      <div className="TLDListSelector">
        <h2>Create Registrar Group</h2>
        <label>Name</label>
        <input type="text" style={{width: "95%"}}
          value={this.state.description}
          placeholder="Name for registrar group..."
          onChange={(e) => this.setState({description: e.target.value})}/>
        { this.state.registrars && this.state.requestTemplates && this.state.requesterGroups ?
          <div>
            <table>
              <div style={{width: "33%", float: "left"}}>
                <p>Requester Groups</p>
                <table style={{ width: "95%" }} >
                  <tbody>
                  {
                    this.state.requesterGroups.map(rqG => (
                      <tr key={rqG.id}
                        style={{backgroundColor: this.state.selectedRqGs.some(sRqG => sRqG === rqG.id) ? "#afa" : undefined }}
                        >
                        <td style={{ borderWidth: ".5px 0" }}>
                          <label>
                            <input type="checkbox"
                              checked={this.state.selectedRqGs.some(sRqG => sRqG === rqG.id)}
                              onClick={(e) => {
                                const rqGPosInSelected = this.state.selectedRqGs.indexOf(rqG.id);
                                if (rqGPosInSelected === -1){
                                  this.state.selectedRqGs.push(rqG.id);
                                } else {
                                  this.state.selectedRqGs.splice(rqGPosInSelected, 1);
                                }
                                this.setState({
                                  selectedRqGs: this.state.selectedRqGs
                                });
                              }}
                            />
                            {rqG.name}
                          </label>
                        </td>
                      </tr>
                      ))
                  }
                  </tbody>
                </table>
              </div>
              <div style={{width: "33%", float: "left"}}>
                <p>Registrars</p>
                <table style={{ width: "95%" }}>
                  <tbody>
                  {
                    this.state.registrars.map(rr => (
                      <tr key={rr.id}
                        style={{backgroundColor: this.state.selectedRrs.some(sRr => sRr === rr.id) ? "#afa" : undefined}}
                        >
                        <td style={{ borderWidth: ".5px 0" }}>
                          <label>
                            <input type="checkbox"
                              checked={this.state.selectedRrs.some(sRr => sRr === rr.id)}
                              onClick={(e) => {
                                const rrPosInSelected = this.state.selectedRrs.indexOf(rr.id);
                                if (rrPosInSelected === -1){
                                  this.state.selectedRrs.push(rr.id);
                                } else {
                                  this.state.selectedRrs.splice(rrPosInSelected, 1);
                                }
                                this.setState({
                                  selectedRrs: this.state.selectedRrs
                                });
                              }}
                            />
                            {rr.name}
                          </label>
                        </td>
                      </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <div style={{width: "33%", float: "left"}}>
                <p>Request Templates</p>
                <table style={{ width: "95%" }}>
                  <tbody>
                  {
                    this.state.requestTemplates.map(reqTemp => (
                      <tr key={reqTemp.id}
                        style={{backgroundColor: this.state.selectedReqTemps.some(sReqTemp => sReqTemp === reqTemp.id) ? "#afa" : undefined}}
                        >
                        <td style={{ borderWidth: ".5px 0" }}>
                          <label>
                            <input type="checkbox"
                              checked={this.state.selectedReqTemps.some(sReqTemp => sReqTemp === reqTemp.id)}
                              onClick={(e) => {
                                const reqTempPosInSelected = this.state.selectedReqTemps.indexOf(reqTemp.id);
                                if (reqTempPosInSelected === -1){
                                  this.state.selectedReqTemps.push(reqTemp.id);
                                } else {
                                  this.state.selectedReqTemps.splice(reqTempPosInSelected, 1);
                                }
                                this.setState({
                                  selectedReqTemps: this.state.selectedReqTemps
                                });
                              }}
                            />
                              {reqTemp.name}
                          </label>
                        </td>
                      </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </table>
            {
              this.state.selectedRqGs.length && this.state.selectedRrs.length && this.state.selectedReqTemps.length ?
                <br />
              : <p style={{ textAlign: "center", color: "#FF0000" }}>
                Please select at least one requester group, registrar, and request template
              </p>
            }
            <button
              disabled={this.state.selectedRqGs.length===0 || this.state.selectedRrs.length===0 || this.state.selectedReqTemps.length===0}
              onClick={() => {
              if (this.state.selectedRqGs.length && this.state.selectedRrs.length && this.state.selectedReqTemps.length) {
                createRegistrarGroup(this.state.description, this.state.selectedRqGs, this.state.selectedRrs, this.state.selectedReqTemps).then(
                () => window.location.reload());
              }
              }}>
              Create Registrar Group!
            </button>
          </div>
            : <LoadingSpinner />
        }
      </div>
    );
  }
}

async function createRegistrarGroup(description: string, requesterGroupIDs: number[], registrarIDs: number[], requestTemplateIDs: number[]): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/registrar_group`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify({
        description: description ?? "",
        rqGIDs: requesterGroupIDs,
        rrIDs: registrarIDs,
        reqTempIDs: requestTemplateIDs
      }),
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}
