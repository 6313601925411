// Given a background color in hex form (e.g. "FF00FF"), choose the foreground color with the highest contrast
// Cache for storing calculated foreground colors based on background color
const calculatedColors: { [key: string]: "white" | "black" } = {};

// Function to determine the contrast color for a given background color
export function contrastColor(input: string): "white" | "black"
  {
  // Check if the calculated color for the input exists in the cache
  if (calculatedColors.hasOwnProperty(input)) return calculatedColors[input];
  try
    {
    // Parse the input hex color to RGB components
    let [r, g, b] = parseHexColor(input);
    // Calculate the luminance using a weighted average formula
    let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    // Determine the contrast color based on luminance
    const result = luma > 127 ? "black" : "white";
    // Cache the result for future use
    calculatedColors[input] = result;
    return result;
    }
  catch (e)
    {
    console.log(e);
    return "black";
    }
  }

// Function to parse a hex color string and return RGB components
function parseHexColor(input: string): [number, number, number]
  {
  // In three-character format, each value is multiplied by 0x11 for an even scale from 0x00 to 0xff
  let m = input.match(/^#?([0-9a-f]{3})$/i)?.[1];
  if (m) return [parseInt(m.charAt(0), 16) * 0x11, parseInt(m.charAt(1), 16) * 0x11, parseInt(m.charAt(2), 16) * 0x11,];

  // In six-character format, extract the RGB components from the hex color string; parse and convert each pair of characters to decimal values
  m = input.match(/^#?([0-9a-f]{6})$/i)?.[1];
  if (m) return [parseInt(m.substring(0, 2), 16), parseInt(m.substring(2, 4), 16), parseInt(m.substring(4, 6), 16),];

  // Throw an error for invalid hex color
  throw new Error(`Invalid hex color: '${input}'`);
  }

// Backoff algorithm for retrying HTTP requests
let backoffCounter: { [key: string]: number } = {};

// Function to limit the rate of HTTP requests using a backoff algorithm
export async function retryLimiter(key: string, max: number): Promise<void>
  {
  let currCount;

  // Check if the backoff counter exists for the given key; Checks if the maximum retry limit is exceeded
  if (backoffCounter.hasOwnProperty(key))
    {
    backoffCounter[key]++;
    if (backoffCounter[key] > max) throw new Error("Maximum retry limit exceeded for key '" + key + "'");
    currCount = backoffCounter[key];
    }
  else
    {
    currCount = backoffCounter[key] = 1;
    }

  // Wait if this isn't the first try using an exponential backoff delay
  if (currCount > 1) await delay(300 * (2 ^ (currCount - 1)));
  }

// Function to reset the backoff counter for retries
export const resetRetries = () => backoffCounter = {};

// Function to introduce a delay in milliseconds using a Promise
const delay = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

