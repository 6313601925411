import _ from "lodash";
import React from "react";
import ReactModal from 'react-modal';
import { APIRequester, APIRequesterGroup, APIRequesterGroupMembership, APISuccess, APIError, GetRequesterGroupMembershipList } from "./common/api";
import LoadingSpinner from "./LoadingSpinner";
import navHeader from "./NavHeader";
import { checkUnsavedBeforeUnload } from "./common/util";
import { getRegistrarGroupMembers } from "./RegistrarGroupMembersList";
import { getAPITokenHeaders, clearPassword } from "./password";
import RqGListSelector from "./RequesterGroupListSelector";

interface RequesterEditorProps {
  selectedRequesterID: number | null;
}

export default function RequesterEditor(props: RequesterEditorProps){
  const [isLoadingRequester, setIsLoadingRequester] = React.useState<boolean>(false);
  const [requester, setRequester] = React.useState<APIRequester | null>(null);
  const [isLoadingRqGList, setIsLoadingRqGList] = React.useState<boolean>(false);
  const [requesterGroupsJoined, setRequesterGroupsJoined] = React.useState<APIRequesterGroupMembership[] | null>(null);
  const [isLoadingAllRqGs, setIsLoadingAllRqGs] = React.useState<boolean>(false);
  const [allRqGs, setAllRqGs] = React.useState<APIRequesterGroup[] | null>(null);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [isSelectingRqG, setIsSelectingRqG] = React.useState<boolean>(false);
  const [isJoiningRqG, setIsJoiningRqG] = React.useState<boolean | null>(null);

  if (isLoadingRequester) {
    return (<LoadingSpinner />);
  }

  if (!requester || (
    requester.id !== props.selectedRequesterID && requester.id !== 0 && props.selectedRequesterID !== null
  )) {

    if (props.selectedRequesterID) {
      console.log("Initiating load of requester information");
      setIsLoadingRequester(true);
      // TODO: handle error case (shouldn't endlessly retry)
      getRequester(props.selectedRequesterID).then(setRequester).finally(() => {
          setIsLoadingRequester(false);
      });
    }
    if (requester) {
        saveNewRequester(requester);
    }
    return null;
  }

  if (!requesterGroupsJoined && !isLoadingRqGList) {
    setIsLoadingRqGList(true);
    getRqGJoined(requester.id).then(setRequesterGroupsJoined).finally(() => setIsLoadingRqGList(false));
  }

  if (!allRqGs && !isLoadingAllRqGs) {
    setIsLoadingAllRqGs(true);
    getAllRqGs().then(setAllRqGs).finally(() => setIsLoadingAllRqGs(false));
  }

  checkUnsavedBeforeUnload()(() => isDirty);

  return requester ? (
    <div> {navHeader()}
      <input type="text" value={requester.name || ""}
          onChange={(e) => {
            const newValue = e.target.value;
            const newRequestObject: APIRequester = _.clone(requester);
            newRequestObject.name = newValue;
            setRequester(newRequestObject);
            setIsDirty(true);
          }}
        />
      <button
        disabled={!isDirty}
        onClick={() => {
          saveRequester(requester);
          setIsDirty(false);
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>save</span>
        Save
      </button>
      <button onClick={() => {
        if (window.confirm('Are you sure you wish to delete this item?')) {
          deleteRequester(requester.id).then(() =>
          window.location.hash = '/registrar_group_list/');
        }
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>delete</span>
        Delete Requester
      </button>
      <br></br>

      <button onClick={() => {
        setIsSelectingRqG(true);
        setIsJoiningRqG(true);
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>group</span>
        Join Requester Group
      </button>
      <button onClick={() => {
        setIsSelectingRqG(true);
        setIsJoiningRqG(false);
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>group_remove</span>
        Leave Requester Group
      </button>

      <ReactModal
        isOpen={isSelectingRqG && isJoiningRqG !== null}
        onRequestClose={() => {
          setIsSelectingRqG(false);
          setIsJoiningRqG(null);
        }}
        ariaHideApp={false}
      >
      <RqGListSelector
        reqID={requester.id}
        reqRequesterGroupsAlreadyJoined={requesterGroupsJoined?.filter(rqG => !rqG.deleted) ?? null}
        joiningOrLeavingRqG= {isJoiningRqG ? "joining" : "leaving"}
        onSelect={(newRqG) => {
          isJoiningRqG ? joinRqG(requester.id, newRqG.id, requesterGroupsJoined ?? []) : leaveRqG(requester.id, newRqG.id);
          setIsSelectingRqG(false);
          setIsJoiningRqG(null);
        }}
      />
      </ReactModal>

      <table>
        <thead>
          <tr>
            <th>
              Requester Groups Joined
            </th>
          </tr>
        </thead>
        <tbody>
          {
            requesterGroupsJoined && allRqGs ? requesterGroupsJoined.map( rqGJoined => {
              const rqG = allRqGs.filter(rqG => rqG.id === rqGJoined.requester_group_id && !rqGJoined.deleted)[0];
              return rqG ? ( //Don't list requester groups that are deleted
                <tr>
                  <td>{rqG.name}</td>
                </tr>
              ) : null
            }) : null
          }
        </tbody>
      </table>
    </div>
  ) : null
}

async function getRequester(requesterID: number): Promise<APIRequester> {
  return (await getRegistrarGroupMembers()).requesters.filter(req => req.id === requesterID)[0];
}

async function getRqGJoined(requesterID: number): Promise<APIRequesterGroupMembership[]> {
  const rawResponse = await fetch(`/api/requester/${requesterID}/memberships`, {
      headers: getAPITokenHeaders()
    });
    if (rawResponse.status === 401) {
      clearPassword();
      throw new Error("Incorrect password");
    }
    const response = await rawResponse.json() as unknown as GetRequesterGroupMembershipList;
    if (response.status === 'success') {
      return response.requester_group_memberships;
    } else {
      throw new Error(response.error);
    }
}

async function joinRqG(requesterID: number, rqGID: number, rqGJoined: APIRequesterGroupMembership[]): Promise<boolean> {
  if (rqGJoined.some(rqG => rqG.requester_group_id === rqGID)) {
    return rejoinRqG(requesterID, rqGID);
  } else {
    return joinNewRqG(requesterID, rqGID);
  }
}

async function joinNewRqG(requesterID: number, rqGID: number): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/requester_group_membership/${requesterID}/${rqGID}`, {
      method: 'PUT',
      headers: headers
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

async function rejoinRqG(requesterID: number, rqGID: number): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/requester_group_membership/${requesterID}/${rqGID}`, {
      method: 'POST',
      headers: headers
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

export async function leaveRqG(requesterID: number, rqGID: number): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/requester_group_membership/${requesterID}/${rqGID}`, {
      method: 'DELETE',
      headers: headers
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

export async function getAllRqGs(): Promise<APIRequesterGroup[]> {
  return (await getRegistrarGroupMembers()).requester_groups;
}

function saveNewRequester(requester: APIRequester): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/requester`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(requester)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

function saveRequester(requester: APIRequester): Promise<boolean>{
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/requester/${requester.id}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requester)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

async function deleteRequester(requesterID: Number): Promise<boolean> {
  const rawResponse = await fetch(`/api/requester/${requesterID}`,
    {
      method: 'DELETE',
      headers: getAPITokenHeaders()
    });
  const response = await rawResponse.json() as unknown as APISuccess | APIError;
  if (response.status === 'success') {
    return true;
  } else {
    throw new Error(response.error);
  }
}
