import _ from "lodash";
import React from "react";
import { APITLDList, GetTLDLists, PostTLDList } from "./common/api";
import LoadingSpinner from "./LoadingSpinner";
import { getAPITokenHeaders } from "./password";

interface TLDListSelectorProps
  {
  value: number | null;
  onSelect: (newTLDList: APITLDList) => void;
  }

interface TLDListSelectorState
  {
  mode: "choosing" | "new" | "editing";
  tldLists: APITLDList[] | null;
  newTLDListText: string;
  newTLDListName: string;
  isSaving: boolean;
  }

// Class component definition for TLDListSelector
export default class TLDListSelector extends React.Component<TLDListSelectorProps, TLDListSelectorState>
  {
  constructor(props: TLDListSelectorProps)
    {
    super(props);
    this.state =
      {
      mode: "choosing",
      tldLists: null,
      newTLDListText: "",
      newTLDListName: "Untitled PSL",
      isSaving: false,
      };

    // Fetch TLD lists from the server
    getTLDLists().then(tldLists => this.setState({tldLists: tldLists}));
    }

  // UI for creating a new TLD list
  renderNewUI(): React.ReactNode
    {
    if (this.state.isSaving) return <LoadingSpinner />;

    return (<div className="TLDListSelector"><h2>Create new PSL</h2><label>Name:&#32; <input value={this.state.newTLDListName} onChange={e => this.setState({ newTLDListName: e.target.value })} /></label><br /><textarea style={{width: "90%"}} value={this.state.newTLDListText} placeholder=".com, .net, .org" onChange={(e) => this.setState({newTLDListText: e.target.value})}></textarea><br /><button onClick={() => this.saveNewTLDList()}>Save</button></div>);
    }
  // Save the new list to the server and then call the onSelect handler
  saveNewTLDList()
    {
    const newTLDList: APITLDList | null = {id: 0, name: this.state.newTLDListName, tlds: parseTextList(this.state.newTLDListText)};
    this.setState({ isSaving: true });
    postTLDList(newTLDList)
      .then((newID) => this.props.onSelect(Object.assign(newTLDList, { id: newID })))
      .finally(() => this.setState({ isSaving: false }));
    }

  render()
    {
    if (this.state.mode === 'new') return this.renderNewUI();
    return (<div className="TLDListSelector"><h2>Choose a PSL</h2><div className="toolbar" style={{textAlign: "center"}}><button onClick={() => this.setState({ mode: "new"})}><span className="material-icons-outlined" aria-hidden={true}>add</span>Create new PSL</button></div>{this.state.tldLists !== null ? (<table className="TLDListSelector-table"><tbody>{this.state.tldLists.map(tldList => (<tr tabIndex={0} onClick={() => this.props.onSelect(tldList)}><td>{tldList.name}</td><td>{tldList.tlds.join(", ")}</td></tr>))}</tbody></table>) : <LoadingSpinner />}</div>);
    }
  }

// Parse the raw text into a list of unique TLDs
function parseTextList(rawText: string): string[]
  {
  const splitList = rawText.split(/[\s,]+/).filter(t => t.length > 0);
  return _.uniq(splitList);
  }

// Fetch TLD lists from the server
async function getTLDLists(): Promise<APITLDList[]>
  {
  return new Promise((resolve, reject) => {fetch('/api/tld_lists', {headers: getAPITokenHeaders()}).then(async rawResponse => {const response = await rawResponse.json() as unknown as GetTLDLists; return (response.status === 'success') ? resolve(response.tld_lists) : reject(response.error);}).catch(error => reject(error));});
  }

// Post the TLD list to the server and return the ID number of the list
async function postTLDList(tldList: APITLDList): Promise<number>
  {
  const headers = getAPITokenHeaders();
  headers.set('Content-Type', 'application/json');
  const res = await fetch('/api/tld_lists', {method: 'POST', body: JSON.stringify(tldList), headers: headers,});

  if (res.status === 200)
    {
    const response = await res.json() as unknown as PostTLDList;
    if (response.status === 'success') return response.tld_list_id;
    else throw new Error(response.error);
    }

  throw new Error("Received status code " + res.status + " from server");
  }
