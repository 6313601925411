import React from 'react';
import { APIRequestTemplate, GetRequestTemplates, NewPolicy } from './common/api';
import LoadingSpinner from './LoadingSpinner';
import navHeader from './NavHeader';
import { clearPassword, getAPITokenHeaders } from './password';

// Defines the props for the RequestTemplateList component.
interface RequestTemplateListProps 
  {
  onSelectID: (id: number) => void;
  }

// React component that fetches and displays a list of request templates, handles loading states and errors, and provides a button to create new templates. The code also includes functions for making API requests to retrieve and create request templates.
export default function RequestTemplateList(props: RequestTemplateListProps) 
  {
  const [requestTemplateList, setRequestTemplateList] = React.useState<APIRequestTemplate[] | null>(null);
  const [isLoadingList, setIsLoadingList] = React.useState<boolean>(false);
  const [serverError, setServerError] = React.useState<string | null>(null);

  if (!requestTemplateList && !isLoadingList && !serverError) 
    {
    setIsLoadingList(true);
    getRequestTemplates().then(setRequestTemplateList).catch((e: any) => setServerError(e.message)).finally(() => setIsLoadingList(false));
    }

  return (serverError !== null) ? <strong>{serverError}</strong> : (requestTemplateList === null) ? <LoadingSpinner /> : (<div>{navHeader()}
    <h2>Request Template List</h2>
    <button onClick={() => {newRequestTemplate().then((id) => window.location.hash = `/request_template/${id}`);}}>Create New Request Template</button>
    <table style={{ width: "100%" }} className="policy-list-table">
    <tbody>{requestTemplateList.map(requestTemplate => (<tr key={requestTemplate.id} onClick={() => props.onSelectID(requestTemplate.id)}>
    <td><a href={`#/request_template/${requestTemplate.id}`} onClick={() => props.onSelectID(requestTemplate.id)}>{requestTemplate.name}</a></td>
    </tr>))}</tbody>
    </table>
    </div>);
  }

// `getRequestTemplates` retrieves API request templates, setting headers with `getAPITokenHeaders`. On a 401 status, it clears the password, throws an error. It parses the JSON response, returning templates on success; otherwise, it throws an error with the response message or a default one. Errors are logged and rethrown.
export function getRequestTemplates(): Promise<APIRequestTemplate[]> 
  {
  return fetch('/api/request_templates', { headers: getAPITokenHeaders() }).then((rawResponse) => {if (rawResponse.status === 401) {clearPassword(); throw new Error("Incorrect password");} return rawResponse.json() as Promise<GetRequestTemplates>;}).then((response) => {return (response.status === 'success') ? response.request_templates : (() => {throw new Error(response.error || 'Failed to create a new request template.');})();}).catch((error) => {console.error(error); throw error;});
  }

// `newRequestTemplate` asynchronously creates a request template by sending a POST request to '/api/request_template_new'. It includes headers from `getAPITokenHeaders`, sets 'Content-Type' to 'application/json', and sends a JSON representation of a template. The function logs and returns the new template's ID if the API request is successful; otherwise, it rejects the promise with an error.
const newRequestTemplate = async (): Promise<number> => 
  {
  return fetch(`/api/request_template_new`, {method: 'POST', headers: {...getAPITokenHeaders(), 'Content-Type': 'application/json',}, body: JSON.stringify({ id: 0, name: "Untitled Request Template", attributes: [] } as APIRequestTemplate)}).then(async rawResponse => {const response = await rawResponse.json() as unknown as NewPolicy; console.log(response); return response.status === 'success' ? response.id : Promise.reject(new Error('API request failed'));});
  };
