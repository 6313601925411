import React from 'react';
import { APIRegistrarGroup, NewPolicy} from './common/api';
//import RegistrarGroupCreator from './RegistrarGroupCreator';
import { getAPITokenHeaders } from './password';

interface RegistrarGroupListProps
  {
  registrarGroups: APIRegistrarGroup[],
  onSelectRegistrarGroup: (newRegistrarGroupID: number) => void
  }

export default function RegistrarGroupList(props: RegistrarGroupListProps) {
  return (
    <div>
      <h2>Registrar Groups</h2>
      <button
        onClick={
          () => {
            newRegistrarGroup().then((id) => window.location.hash = `/registrar_group/${id}`);
          }
        }>Create New Registrar Group</button>
      <table style={{ width: "100%" }} className="policy-list-table">
          <tbody>
          {
              props.registrarGroups.map(rrG => (
              <tr key={rrG.id}
                  onClick={() => props.onSelectRegistrarGroup(rrG.id)}>
                  <td>
                  <a href={`#/registrar_group/${rrG.id}`}
                      onClick={() => props.onSelectRegistrarGroup(rrG.id)}>
                      {rrG.description}
                  </a>
                  <br />
                  </td>
              </tr>
              ))
          }
          </tbody>
      </table>
    </div>
  );
}

function newRegistrarGroup(): Promise<number> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/registrar_group`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify({
        description: "Unnamed Registrar Group",
      } as APIRegistrarGroup)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as NewPolicy;
      if (response.status === 'success') {
        return resolve(response.id);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}
