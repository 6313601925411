import _ from "lodash";
import React from "react";
import ReactModal from 'react-modal';
import { APIRequester, APIRequesterGroup, APIRequesterGroupMembership, GetRequesterGroupMembershipList, APISuccess, APIError } from "./common/api";
import LoadingSpinner from "./LoadingSpinner";
import navHeader from "./NavHeader";
import { checkUnsavedBeforeUnload } from "./common/util";
import { getRegistrarGroupMembers } from "./RegistrarGroupMembersList";
import { getAPITokenHeaders, clearPassword } from "./password";
import { leaveRqG } from "./RequesterEditor";
import RequesterListSelector from "./RequesterListSelector";
import RegistrarGroupCreator from "./RegistrarGroupCreator";
import RegistrarGroupTable from "./RegistrarGroupTable";

interface RequesterGroupEditorProps {
  selectedRqGID: number | null;
}

export default function RequesterGroupEditor(props: RequesterGroupEditorProps){
  const [isLoadingRqG, setIsLoadingRqG] = React.useState<boolean>(false);
  const [requesterGroup, setRequesterGroup] = React.useState<APIRequesterGroup | null>(null);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [isLoadingMembershipList, setIsLoadingMembershipList] = React.useState<boolean>(false);
  const [membershipList, setMembershipList] = React.useState<APIRequesterGroupMembership[] | null>(null);
  const [isSelectingRequester, setIsSelectingRequester] = React.useState<boolean>(false);
  const [isMakingRrG, setIsMakingRrG] = React.useState<boolean>(false);

  if (isLoadingRqG) {
    return (<LoadingSpinner />);
  }

  if (!requesterGroup || (
    requesterGroup.id !== props.selectedRqGID && requesterGroup.id !== 0 && props.selectedRqGID !== null
  )) {

    if (props.selectedRqGID) {
      console.log("Initiating load of requester group information");
      setIsLoadingRqG(true);
      // TODO: handle error case (shouldn't endlessly retry)
      getRqG(props.selectedRqGID).then(setRequesterGroup).finally(() => {
          setIsLoadingRqG(false);
      });
    }
    if (requesterGroup) {
        saveNewRqG(requesterGroup);
    }
    return null;
  }

  if (!membershipList && !isLoadingMembershipList) {
    setIsLoadingMembershipList(true);
    getRqGMembers(requesterGroup.id).then(setMembershipList).finally(() => setIsLoadingMembershipList(false));
  }

  checkUnsavedBeforeUnload()(() => isDirty);

  return requesterGroup ? (
    <div> {navHeader()}
      <input type="text" value={requesterGroup.name || ""}
          onChange={(e) => {
            const newValue = e.target.value;
            const newRqGObject: APIRequesterGroup = _.clone(requesterGroup);
            newRqGObject.name = newValue;
            setRequesterGroup(newRqGObject);
            setIsDirty(true);
          }}
        />
      <button
        disabled={!isDirty}
        onClick={() => {
          saveRqG(requesterGroup);
          setIsDirty(false);
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>save</span>
        Save
      </button>
      <button onClick={() => {
        if (window.confirm('Are you sure you wish to delete this item?')) {
          deleteRqG(requesterGroup.id).then(() =>
          window.location.hash = '/registrar_group_list/');
        }
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>delete</span>
        Delete Requester Group
      </button>
      <button onClick={() => {
        setIsSelectingRequester(true);
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>group_remove</span>
        Remove Member From Requester Group
      </button>
      <ReactModal
        isOpen={isSelectingRequester}
        onRequestClose={() => {
          setIsSelectingRequester(false)
        }}
        ariaHideApp={false}
      >
      <RequesterListSelector
        rqGID={requesterGroup.id}
        members={requesterGroup.members}
        onSelect={(requester: APIRequester) => {
          leaveRqG(requester.id, requesterGroup.id);
          setIsSelectingRequester(false);
        }}
      />
      </ReactModal>

      <br></br>
      <button onClick={() => {
        setIsMakingRrG(true);
      }}>
        <span className="material-icons-outlined" aria-hidden={true}>article</span>
        Create Registrar Group
      </button>
      <ReactModal
        isOpen={isMakingRrG}
        onRequestClose={() => {
          setIsMakingRrG(false)
        }}
        ariaHideApp={false}
      >
      <RegistrarGroupCreator
        currentObjID={requesterGroup.id}
        currentObjType="RqG"
      />
      </ReactModal>

      <h2>Obligations document:</h2>
      <textarea cols={75} rows={5} name="obligationsDoc" value={requesterGroup.obligations || ""}
        onChange={(e) => {
          const newObligations = e.target.value;
          const newRqGObject: APIRequesterGroup = _.clone(requesterGroup);
          newRqGObject.obligations = newObligations;
          setRequesterGroup(newRqGObject);
          setIsDirty(true);
        }} />
        <table>
          <tr>
            <th>Members</th>
          </tr>
          <tbody>
            {
              membershipList ? requesterGroup.members.map( member => {
                return (
                  <tr>
                    <td>{member.name}</td>
                  </tr>
                )
              }) : null
            }
          </tbody>
        </table>
        <br />

        <RegistrarGroupTable
        currentObjID={requesterGroup.id}
        currentObjType="RqG"
      />
    </div>
  ) : null
}

async function getRqG(rqGID: number): Promise<APIRequesterGroup> {
  return (await getRegistrarGroupMembers()).requester_groups.filter(rqG => rqG.id === rqGID)[0];
}

async function getRqGMembers(rqGID: number): Promise<APIRequesterGroupMembership[]> {
  const rawResponse = await fetch(`/api/requester_group/${rqGID}/memberships`, {
      headers: getAPITokenHeaders()
    });
    if (rawResponse.status === 401) {
      clearPassword();
      throw new Error("Incorrect password");
    }
    const response = await rawResponse.json() as unknown as GetRequesterGroupMembershipList;
    if (response.status === 'success') {
      return response.requester_group_memberships;
    } else {
      throw new Error(response.error);
    }
}

function saveNewRqG(requesterGroup: APIRequesterGroup): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/requester_group`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(requesterGroup)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

function saveRqG(requesterGroup: APIRequesterGroup): Promise<boolean>{
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/requester_group/${requesterGroup.id}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requesterGroup)
    }).then(async rawResponse => {
      const response = await rawResponse.json() as unknown as APISuccess | APIError;
      if (response.status === 'success') {
        return resolve(true);
      } else {
        return reject(false);
      }
    }).catch(error => reject(error));
  });
}

async function deleteRqG(rqGID: Number): Promise<boolean> {
  const rawResponse = await fetch(`/api/requester_group/${rqGID}`,
    {
      method: 'DELETE',
      headers: getAPITokenHeaders()
    });
  const response = await rawResponse.json() as unknown as APISuccess | APIError;
  if (response.status === 'success') {
    return true;
  } else {
    throw new Error(response.error);
  }
}
