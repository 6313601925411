import React from "react";
import { APIRegistrar, APIRegistrarGroup, APIRequestTemplate, APIRequesterGroup, GetRegistrarGroups } from "./common/api";
import { getRequestTemplates } from "./RequestTemplateList";
import { getAllRqGs } from "./RequesterEditor";
import { clearPassword, getAPITokenHeaders } from "./password";
import { getRegistrarGroupMembers } from "./RegistrarGroupMembersList";

interface RegistrarGroupTableProps {
  currentObjID: number;
  currentObjType: "RqG" | "Rr" //Either viewing from a requester group or a registrar
}

interface RegistrarGroupTableState {
  allRqGs: APIRequesterGroup[] | null;
  allRrs: APIRegistrar[] | null;
  allReqTemps: APIRequestTemplate[] | null;
  registrarGroups: APIRegistrarGroup[] | null;
}

export default class RegistrarGroupTable extends React.Component<RegistrarGroupTableProps, RegistrarGroupTableState> {
  constructor(props: RegistrarGroupTableProps){
    super(props);
    this.state = {
      allRqGs: null,
      allRrs: null,
      allReqTemps: null,
      registrarGroups: null,
    };

    getAllRqGs().then(rqGs => this.setState({
      allRqGs: rqGs,
    }));
    getRegistrarGroupMembers().then(rrGMembers => this.setState({
      allRrs: rrGMembers.registrars,
    }));
    getRequestTemplates().then(reqTemps => this.setState({
      allReqTemps: reqTemps,
    }));
    getRegistrarGroups(this.props.currentObjID, this.props.currentObjType).then(rrGs => this.setState({
      registrarGroups: rrGs,
    }));
  }
    
  render() {
    return (
      <table>
        <tr>
          <th colSpan={3}>Registrar Groups Joined</th>
        </tr>
        <tr>
          <th>Name</th>
          <th>{this.props.currentObjType === "Rr" ? "Requester Group(s)" : "Registrar(s)"}</th>
          <th>Request template(s)</th>
        </tr>
        <tbody>
          {
            this.props.currentObjType === "Rr" ? (
              this.state.registrarGroups && this.state.allRqGs && this.state.allReqTemps ? this.state.registrarGroups.map(rrG => {
              return (
                <tr>
                  <td>
                    {rrG.description}
                  </td>
                  <td>
                    {rrG.requester_group_ids.length ?
                      rrG.requester_group_ids.map(rqGID =>
                        this.state.allRqGs?.filter(rqG => rqG.id === rqGID)[0].name
                      ).join(", ") : "[None]" }
                  </td>
                  <td>
                  {rrG.request_template_ids.length ?
                    rrG.request_template_ids.map(reqTempID =>
                      this.state.allReqTemps?.filter(reqTemp => reqTemp.id === reqTempID)[0].name
                    ).join(", ") : "[None]" }
                  </td>
                </tr>
              );
            }) : null)
            : (this.state.registrarGroups && this.state.allRrs && this.state.allReqTemps ? this.state.registrarGroups.map(rrG => {
              return (
                <tr>
                  <td>
                    {rrG.description}
                  </td>
                  <td>
                    {rrG.registrar_ids.length ?
                      rrG.registrar_ids.map(rrID =>
                        this.state.allRrs?.filter(rr => rr.id === rrID)[0].name
                      ).join(", ") : "[None]" }
                  </td>
                  <td>
                    {rrG.request_template_ids.length ?
                      rrG.request_template_ids.map(reqTempID =>
                        this.state.allReqTemps?.filter(reqTemp => reqTemp.id === reqTempID)[0].name
                      ).join(", ") : "[None]" }
                  </td>
                </tr>
              );
            }) : null)
          }
        </tbody>
      </table>
    );
  }
}

async function getRegistrarGroups(objID: number, objType: "RqG" | "Rr"): Promise<APIRegistrarGroup[]> {
  let rawResponse;
  if(objType === "Rr") {
    rawResponse = await fetch(`/api/registrar/${objID}/registrar_groups`, {
      headers: getAPITokenHeaders()
    });
  } else { //objType === "RqG"
    rawResponse = await fetch(`/api/requester_group/${objID}/registrar_groups`, {
      headers: getAPITokenHeaders()
    });
  }
  if (rawResponse.status === 401) {
    clearPassword();
    throw new Error("Incorrect password");
  }
  const response = await rawResponse.json() as unknown as GetRegistrarGroups;
  if (response.status === 'success') {
    return response.registrar_groups;
  } else {
    throw new Error(response.error);
  }
}
