import React from 'react';

// CollapsibleBoxProps interface defines the expected props for the CollapsibleBox component.
interface CollapsibleBoxProps
  {
  style?: React.CSSProperties;
  title: React.ReactNode;
  children: React.ReactNode;
  startCollapsed?: boolean;
  }

// CollapsibleBox component provides a collapsible container with a title and content.
export default function CollapsibleBox(props: CollapsibleBoxProps)
  {
  // State to track whether the box is collapsed or not.
  const [collapsed, setCollapsed] = React.useState(props.startCollapsed ?? false);
  // Default styles for the CollapsibleBox container.
  const DEFAULT_STYLES: React.CSSProperties = {border: '1px solid black',};

  return (<div style={Object.assign(DEFAULT_STYLES, props.style)}>
    <h3 className="collapsible-box-title" style={{cursor: 'pointer',}} onClick={() => setCollapsed(!collapsed)}><span className="material-icons-outlined" title={ collapsed ? "Collapsed" : "Expanded" } style={{ verticalAlign: "middle" }}>{ collapsed ? "expand_more" : "expand_less" }</span>{props.title}</h3>
    <div style={{ display: collapsed ? 'none' : 'block' }}>{props.children}</div>
    </div>);
  }
