import _, { parseInt } from 'lodash';
import React /*, {useRef, useEffect}*/ from 'react';
import { NamedItem, APIDictionary, APIRegistration, APIRule, GetRegistration, PolicyListItem, APIRegistrantFactItem, APIRegistrantFacts, APIPolicy, PostRegistration, APIRuleAttribute, APITLDList } from './common/api';
import LoadingSpinner from './LoadingSpinner';
import { getAPITokenHeaders } from './password';
import { APISuccess, APIError } from './common/api';
import { getRegistrantFactsList } from './RegistrantFactsList';
import { getRegistrantFacts, saveRegistrantFacts } from './RegistrantFactsEditor';
import { getPolicyList } from './policyData';
import { getPolicy, updatePolicyRuleAttributeValue, PolicyRuleCell, savePolicy, RULE_ATTRIBUTES, scopeElement } from './PolicyEditor';
import { checkUnsavedBeforeUnload, getAttributeFromRule, getRuleForElement } from './common/util';
import navHeader from './NavHeader';
import { exportRegistrationToPDF } from './PDfDownload';
import AttributeSelect from './AttributeSelect';
import { TLDItem } from './Scope';

interface RegistrationEditorProps {
  selectedRegistrationID: number | null;
  dataDictionary: APIDictionary;
}

export default function RegistrationEditor(props: RegistrationEditorProps) {
  const [ isLoadingRegistration, setIsLoadingRegistration ] = React.useState<boolean>(false);
  const [ isLoadingPolicyList, setIsLoadingPolicyList, ] = React.useState<boolean>(false);
  const [ isLoadingRegistrantFactsList, setIsLoadingRegistrantFactsList ] = React.useState<boolean>(false);
  const [ isLoadingPolicy, setIsLoadingPolicy ] = React.useState<boolean>(false);
  const [ isLoadingRegistrantFacts, setIsLoadingRegistrantFacts ] = React.useState<boolean>(false);
  const [ registration, setRegistration ] = React.useState<APIRegistration | null>(null);
  const [ registrantFactsList, setRegistrantFactsList ] = React.useState<NamedItem[] | null>(null);
  const [ policyList, setPolicyList ] = React.useState<PolicyListItem[] | null>(null);
  const [ registrantFacts, setRegistrantFacts ] = React.useState<APIRegistrantFacts | null>(null);
  const [ policy, setPolicy ] = React.useState<APIPolicy | null>(null);
  const [ pdfStartPage, setPdfStartPage ] = React.useState<number | null>(null);
  const [ pdfAnnotation, setPdfAnnotation ] = React.useState<string | null>(null);
  const [ unsavedRegistrationChanges, setUnsavedRegistrationChanges ] = React.useState<boolean>(false);
  const [ unsavedPolicyChanges, setUnsavedPolicyChanges ] = React.useState<boolean>(false);

  const singletonScopes = [1, 2, 4];
  const REG_ATTRIBUTES = {
    V3RQ: 14,
    DG: 15
  };

  if (isLoadingRegistration) {
    return (<LoadingSpinner/>);
  }

  if (!registration || (
      registration.id !== props.selectedRegistrationID && registration.id !== 0 && props.selectedRegistrationID !== null
    )) {

    if (props.selectedRegistrationID) {
      console.log("Initiating load of registration information");
      setIsLoadingRegistration(true);
      // TODO: handle error case (shouldn't endlessly retry)
      getRegistration(props.selectedRegistrationID).then(setRegistration).finally(() => {
          setIsLoadingRegistration(false);
      });
    }
    if (registration) {
        saveNewRegistration(registration);
    }
    return null;
  }

  if (registration) {
    if (!policy && registration.policy_id !== -1 && !isLoadingPolicy) {
        setIsLoadingPolicy(true);
        getPolicy(registration.policy_id).then(setPolicy).finally(() => setIsLoadingPolicy(false));
    }
    if (!registrantFacts && registration.registrant_facts_id !== -1 && !isLoadingRegistrantFacts) {
        setIsLoadingRegistrantFacts(true);
        getRegistrantFacts(registration.registrant_facts_id).then(setRegistrantFacts).finally(() => setIsLoadingRegistrantFacts(false));
    }
    if (!registrantFactsList && !isLoadingRegistrantFactsList) {
        setIsLoadingRegistrantFactsList(true);
        getRegistrantFactsList().then(setRegistrantFactsList).finally(() => setIsLoadingRegistrantFactsList(false));
    }
    if (!policyList && !isLoadingPolicyList) {
        setIsLoadingPolicyList(true);
        getPolicyList().then(policies => setPolicyList(policies.filter(policy => policy.org_type === "registrar"))).finally(() => setIsLoadingPolicyList(false));
    }
    if (registration.policy_id === -1 && policyList) { // default to first item in list
        let newReg = {...registration};
        newReg.policy_id = policyList.at(0)?.id ?? -1;
        if (newReg !== registration) setRegistration(newReg);
    }
    if (registration.registrant_facts_id === -1 && registrantFactsList) { // default to first item in list
        let newReg = {...registration};
        newReg.registrant_facts_id = registrantFactsList.at(0)?.id ?? -1;
        if (newReg !== registration) setRegistration(newReg);
    }
  }

  checkUnsavedBeforeUnload()(() => unsavedRegistrationChanges || unsavedPolicyChanges);

  return (
    <div> {navHeader()}
    <div className="RegistrationEditor">
        <table>
            <thead>
                <th>Registration</th>
                <th>Policy</th>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <label>Scope: {
                            registration?.scope_attributes && policy?.scope_attributes ?
                                (checkScopeSubset(registration, policy).length > 0 || !registration.tlds?.tlds.every(tld => policy?.tlds?.tlds.includes(tld))) ?
                                    <span className="attribute-value"
                                        style={{
                                        backgroundColor: 'black',
                                        color: 'yellow',
                                        cursor: 'cell',
                                        display: "inline-block",
                                        padding: '4px',
                                        textAlign: 'center',
                                        minWidth: '18px',
                                        // rounded corners
                                        borderRadius: '4px',
                                        }}>⚠️ Incompatible Scope: {
                                            checkScopeSubset(registration, policy).map((scopeId) => props.dataDictionary.scope_attributes.filter(a => a.id === scopeId).at(0)?.name).concat(
                                            !registration.tlds?.tlds.every(tld => policy?.tlds?.tlds.includes(tld)) ? " TLDs" : "").filter(disStr => disStr !== "").join(", ")
                                        }
                                    </span> :
                                    <span className="attribute-value"
                                        style={{
                                        backgroundColor: 'green',
                                        color: 'white',
                                        cursor: 'cell',
                                        display: "inline-block",
                                        padding: '4px',
                                        textAlign: 'center',
                                        minWidth: '18px',
                                        // rounded corners
                                        borderRadius: '4px',
                                        }}>Compatible Scope
                                    </span>
                            : ""
                        }</label>

                        {
                            registration ? scopeElement(
                                registration.scope_attributes,
                                registration.tlds,
                                props.dataDictionary.scope_attributes.map((att) => {
                                    return {id: att.id, name: att.name, values: att.values.filter((val) => singletonScopes.includes(val.value))}
                                }),
                                (newScopeAtts: APIRuleAttribute[]) => updateRegistration({
                                    scope_attributes: newScopeAtts
                                }),
                                (newTLDList: APITLDList) => updateRegistration({
                                    tlds: newTLDList,
                                    tld_list_id: newTLDList.id
                                }),
                                1
                            ) : null
                        }
                    </td>
                    <td>
                        <label>Scope: </label>
                        {
                            policy ? scopeElement(
                                policy.scope_attributes,
                                policy.tlds,
                                props.dataDictionary.scope_attributes,
                                (newScopeAtts: APIRuleAttribute[]) => updatePolicy({
                                    scope_attributes: newScopeAtts
                                }),
                                (newTLDList: APITLDList) => updatePolicy({
                                    tlds: newTLDList,
                                    tld_list_id: newTLDList.id
                                })
                            ) : null
                        }
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Name: </label>
                            <input type="text" value={registration?.name || ""}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    const newRegistration: APIRegistration = _.clone(registration);
                                    newRegistration.name = newValue;
                                    setRegistration(newRegistration);
                                }}
                        />
                        <button
                            disabled={!unsavedRegistrationChanges}
                            onClick={() => {
                            saveRegistration(registration);
                            if (registrantFacts) {
                                saveRegistrantFacts(registrantFacts);
                                setUnsavedRegistrationChanges(false);
                            }
                        }}>
                            <span className="material-icons-outlined" aria-hidden={true}>save</span>
                            Save Registration
                        </button>
                        <button onClick={() => {
                            if (window.confirm('Are you sure you wish to delete this item?') && registration) {
                                deleteRegistration(registration.id).then(() =>
                                window.location.hash = '/registration_list/');
                            }
                        }}>
                            <span className="material-icons-outlined" aria-hidden={true}>delete</span>
                            Delete Registration
                        </button>
                        <button onClick={() => {
                            copyRegistration(registration.id).then((newRegistrationId) =>
                                window.location.hash = `/registration/${newRegistrationId}`
                            );
                        }}>
                            <span className="material-icons-outlined" aria-hidden={true}>file_copy</span>
                            Copy Registration
                        </button>
                        <button type="button" onClick={() => {
                            window.location.hash = "/registration_old/"+registration?.id;
                            }}>
                            <span className="material-icons-outlined" aria-hidden={true}>exit_to_app</span>

                          Go to old Editor
                        </button>
                        <div></div>
                        <label htmlFor="starting_page">Starting Page Number:</label>
                        <input
                            name="starting_page"
                            type="number"
                            className="wrapper-pdf-meta"
                            onChange={(e) => {
                                setPdfStartPage(parseInt(e.target.value));
                            }}
                        />
                        <label className="wrapper-pdf-meta-label" htmlFor="annotation">Annotation:</label>
                        <input
                            name="annotation"
                            type="string"
                            className="wrapper-pdf-meta"
                            onChange={(e) => {
                                setPdfAnnotation(e.target.value);
                            }}
                        />
                        <button onClick={() => {
                            exportRegistrationToPDF(registration, pdfStartPage??0, pdfAnnotation??"", registrationEditorTable(), props.dataDictionary, registrantFacts);
                        }}>
                            <span className="material-icons-outlined" aria-hidden={true}>download</span>
                            Download PDF
                        </button>
                    </td>
                    <td>
                        <select value={registration.policy_id.toString()} onChange={e => {
                            getPolicy(parseInt(e.target.value)).then(setPolicy);
                            var newRegistration = _.clone(registration);
                            newRegistration.policy_id = parseInt(e.target.value);
                            setRegistration(newRegistration);
                        }}>
                            {policyList?.map((policy, i) => {
                                // if (i === policyList.length - 1) getPolicy(policy.id).then(setPolicy);
                                return <option value={policy.id}>{policy.name}</option>
                            })}
                        </select>
                        <button
                            type="button" onClick={() => {
                            window.location.hash = "/policy/"+policy?.id;
                            }}>
                            <span className="material-icons-outlined" aria-hidden={true}>exit_to_app</span>
                            Go to Policy
                        </button>
                        <button
                            disabled={!unsavedPolicyChanges}
                            onClick={() => {
                                if (policy) {
                                    savePolicy(policy);
                                    setUnsavedPolicyChanges(false);
                                }
                            }}>
                            <span className="material-icons-outlined" aria-hidden={true}>save</span>
                            Save Policy
                        </button>
                        <button onClick={() => {
                          if (registrantFacts && policy) {
                              let newFacts = props.dataDictionary.element_groups.map(eg => (
                                eg.elements.map(el => {
                                    if (policy) {
                                        const rule = getRuleForElement(policy, el.id);
                                        if (rule) {
                                            let V3RQ = rule.attributes.filter(a => a.attribute_id === RULE_ATTRIBUTES.V3RQ)[0]?.value || 0;
                                            let DG = rule.attributes.filter(a => a.attribute_id === RULE_ATTRIBUTES.DG)[0]?.value || 0;
                                            let curVal = registrantFacts.facts.find(rf => rf.element_id === el.id)?.value || "";
                                            if (V3RQ !== 0 || DG !== 0) {
                                                const newFactObject: APIRegistrantFacts = _.clone(registrantFacts);
                                                newFactObject.facts = newFactObject.facts.slice();
                                                const newFact: APIRegistrantFactItem = {
                                                    element_id: el.id,
                                                    value: curVal,
                                                    V3RQ: ""+V3RQ,
                                                    DG: ""+DG,
                                                };
                                                return newFact;
                                            }
                                        }
                                    }
                              return null;}))).flat().filter(x => x !== undefined);
                              const newFactsObject: APIRegistrantFacts = _.clone(registrantFacts);
                              //we have a check for undefined but ts doesn't seem to notice
                              //@ts-ignore
                              newFactsObject.facts = newFacts;
                              setRegistrantFacts(newFactsObject);
                          }
                        }}>
                          Copy Defaults
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div style={{height: "30px"}}></div>
        {registrationEditorTable()}
    </div>
    </div>
  );
    function registrationEditorTable(): JSX.Element {
        return (
            <table>
                <thead>
                  <tr>
                    <th colSpan={6}>Registrant Facts</th>
                    <th colSpan={props.dataDictionary.rule_attributes.length + 1}>Policy</th>
                    <th colSpan={3}>Registration</th>
                  </tr>
                  <tr>
                    <th>Category</th>
                    <th>Element</th>
                    <th>Registrant Value</th>
                    <th>Vrq</th>
                    <th>Srq</th>
                    <th style={{padding: "15px"}}></th>
                    {
                        props.dataDictionary.rule_attributes.map(att => <th key={att.id} colSpan={1}>{att.name}</th>)
                    }
                    <th style={{padding: "15px"}}></th>
                    <th>Registration Value</th>
                    <th>Validation</th>
                    <th>Sensitivity</th>
                  </tr>
                </thead>
                <tbody>
                    {
                        props.dataDictionary.element_groups.map(eg => (
                            eg.elements.map((el, i) => {
                                const regFact = registrantFacts?.facts.find(rf => rf.element_id === el.id);
                                var coll: number = 0;
                                //var validation: string = "";
                                //var sensitivity: string = "";
                                //var V3RQ: number = 0;
                                //var DG: number = 0;
                                if (policy) {
                                    const rule = getRuleForElement(policy, el.id);
                                    if (rule) {
                                        coll = getAttribute(RULE_ATTRIBUTES.COLL, rule, props.dataDictionary)?.value || 0;
                                        //validation = getAttribute(RULE_ATTRIBUTES.VAL, rule, props.dataDictionary)?.name || "";
                                        //sensitivity = getAttribute(RULE_ATTRIBUTES.SENS, rule, props.dataDictionary)?.name || "";
                                        //V3RQ = getAttribute(RULE_ATTRIBUTES.V3RQ, rule, props.dataDictionary)?.value || 0;
                                        //DG = getAttribute(RULE_ATTRIBUTES.DG, rule, props.dataDictionary)?.value || 0;
                                    }
                                }
                                let groupCell: React.ReactNode = null;
                                if (i === 0) {
                                    groupCell = (
                                        <td rowSpan={eg.elements.length}>{eg.name}</td>
                                    );
                                }
                                let rule = policy ? getRuleForElement(policy, el.id)??{ id: 0, element_id: el.id, attributes: [] } : { id: 0, element_id: el.id, attributes: [] };
                                let regValidationToDisplay = rule.attributes.find(att => att.attribute_id === RULE_ATTRIBUTES.VAL)?.value??0;
                                if ((parseInt(regFact?.V3RQ ?? "0") | regValidationToDisplay) === regValidationToDisplay && (parseInt(regFact?.V3RQ ?? "15") !== 15)) { //15 indicates any - use V3RQ
                                  regValidationToDisplay = parseInt(regFact?.V3RQ ?? "0");
                                } else {
                                  regValidationToDisplay = rule.attributes.find(att => att.attribute_id === RULE_ATTRIBUTES.V3RQ)?.value??0;
                                }
                                let regSensToDisplay = rule.attributes.find(att => att.attribute_id === RULE_ATTRIBUTES.SENS)?.value??0;
                                if ((parseInt(regFact?.DG ?? "0") | regSensToDisplay) === regSensToDisplay && (parseInt(regFact?.DG ?? "15") !== 15)) {
                                  regSensToDisplay = parseInt(regFact?.DG ?? "0");
                                } else {
                                  regSensToDisplay= rule.attributes.find(att => att.attribute_id === RULE_ATTRIBUTES.DG)?.value??0;
                                }
                                function regScope(): React.ReactNode {
                                    if (!registration) return;
                                    if (el.id === 4) { //PSL
                                        return <TLDItem
                                            tldList={registration.tlds}
                                            onTLDListChange={(newTLDList: APITLDList) => updatePolicy({
                                                tlds: newTLDList,
                                                tld_list_id: newTLDList.id,
                                            })}
                                            isButtonDisplayed={false}
                                        />
                                    } else {
                                        //I cannot think of a better way to do this.  Should prob be added to dataDictionary, or better yet a refactor of the dataDictionary
                                        const joinTable: {[key: number]: number} = {84: 10, 5: 11, 6: 12, 85: 13};
                                        const attValue = props.dataDictionary.scope_attributes.find(sa => sa.id === joinTable[el.id]);
                                        return (
                                        attValue ?
                                            <AttributeSelect
                                                attribute={attValue}
                                                value={registration.scope_attributes.find(sa => sa.attribute_id === joinTable[el.id])?.value??1}
                                                onChange={(newVal) => {
                                                    const newScopeAtts = registration?.scope_attributes.slice();
                                                    const existingAttIndex = newScopeAtts.findIndex(att => att.attribute_id === attValue.id);
                                                    // if this attribute doesn't have a value yet, add it to the list
                                                    if (existingAttIndex === -1) {
                                                        newScopeAtts.push({ attribute_id: attValue.id, value: newVal });
                                                    } else { // otherwise update the existing attribute
                                                        newScopeAtts[existingAttIndex] = Object.assign({}, newScopeAtts[existingAttIndex], { value: newVal });
                                                    }
                                                    updateRegistration({
                                                        scope_attributes: newScopeAtts
                                                    });
                                                }}
                                                alternateDropdown={attValue.values.filter((val) => singletonScopes.includes(val.value))}
                                            /> : null
                                        );
                                    }
                                }
                                if (registrantFacts) return (
                                    <tr>
                                        {groupCell}
                                        <td style={{fontStyle: el.rr_controlled ? 'italic' : "normal"}}>{(el.rr_controlled ? "*" : "") + el.name}</td>
                                        <td>
                                            <div hidden>{el.id}</div>
                                            {eg.id === 2 ?
                                                regScope()
                                            :
                                                <input type="text" value={regFact?.value || ""} style={{ width: 100 }}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value;
                                                        // empty string removes the value
                                                        if (newValue.trim() === '') {
                                                            return setRegistrantFacts(
                                                                Object.assign({},
                                                                    registrantFacts,
                                                                    { facts: registrantFacts.facts.filter(rf => rf.element_id !== el.id) }
                                                                )
                                                            );
                                                        } else {
                                                            const existingFactIndex = registrantFacts.facts.findIndex(rf => rf.element_id === el.id);
                                                            const newFactObject: APIRegistrantFacts = _.clone(registrantFacts);
                                                            newFactObject.facts = newFactObject.facts.slice();
                                                            // if modifying an existing fact, update it
                                                            if (existingFactIndex !== -1) {
                                                                const newFact = Object.assign({}, registrantFacts.facts[existingFactIndex], { value: newValue });
                                                                newFactObject.facts[existingFactIndex] = newFact;
                                                            } else { // otherwise, add it to the set of facts
                                                                const newFact: APIRegistrantFactItem = {
                                                                    element_id: el.id,
                                                                    value: newValue,
                                                                    V3RQ: "15",
                                                                    DG: "15"
                                                                };
                                                                newFactObject.facts.push(newFact);
                                                            }
                                                            setRegistrantFacts(newFactObject);
                                                            setUnsavedRegistrationChanges(true);
                                                        }
                                                    }}
                                                />
                                            }

                                        </td>
                                        <td>
                                            <div hidden>{el.id}</div>
                                            <AttributeSelect
                                                value={parseInt(regFact?.V3RQ ?? "15")}
                                                onChange={(newVal) => {
                                                    const existingFactIndex = registrantFacts.facts.findIndex(rf => rf.element_id === el.id);
                                                    const newFactObject: APIRegistrantFacts = _.clone(registrantFacts);
                                                    newFactObject.facts = newFactObject.facts.slice();
                                                    // if modifying an existing fact, update it
                                                    if (existingFactIndex !== -1) {
                                                        const newFact = Object.assign({}, registrantFacts.facts[existingFactIndex], { V3RQ: newVal });
                                                        newFactObject.facts[existingFactIndex] = newFact;
                                                    }
                                                    setRegistrantFacts(newFactObject);
                                                }}
                                                attribute={props.dataDictionary.registration_attributes.find((att: { id: number; }) => att.id === REG_ATTRIBUTES.V3RQ)}
                                            />

                                        </td>
                                        <td>
                                            <div hidden>{el.id}</div>
                                            <AttributeSelect
                                                value={parseInt(regFact?.DG ?? "15")}
                                                onChange={(newVal) => {
                                                    const existingFactIndex = registrantFacts.facts.findIndex(rf => rf.element_id === el.id);
                                                    const newFactObject: APIRegistrantFacts = _.clone(registrantFacts);
                                                    newFactObject.facts = newFactObject.facts.slice();
                                                    // if modifying an existing fact, update it
                                                    if (existingFactIndex !== -1) {
                                                        const newFact = Object.assign({}, registrantFacts.facts[existingFactIndex], { DG: newVal });
                                                        newFactObject.facts[existingFactIndex] = newFact;
                                                    }
                                                    setRegistrantFacts(newFactObject);
                                                }}

                                                attribute={props.dataDictionary.registration_attributes.find((att: { id: number; }) => att.id === REG_ATTRIBUTES.DG)}
                                            />
                                        </td>
                                        <td></td>
                                        {
                                            props.dataDictionary.rule_attributes.map(att => (
                                                <PolicyRuleCell
                                                    key={att.id}
                                                    rule={rule}
                                                    comparisonRule={undefined}
                                                    attribute={att}
                                                    onChange={(newVal) => {
                                                        if (policy) {
                                                            setPolicy(updatePolicyRuleAttributeValue(policy, rule.element_id, att.id, newVal));
                                                            setUnsavedPolicyChanges(true);
                                                        }
                                                    }}
                                                />
                                            ))
                                        }
                                        <td></td>
                                        <td>
                                            {eg.id === 2 ?
                                                regScope()
                                            : filterFact(regFact, coll, props.dataDictionary)}
                                        </td>
                                        <td>
                                        { // 2 is "Don't collect"
                                          coll !== 2 ? <AttributeSelect
                                            value={regValidationToDisplay}
                                            onChange={(newVal) => {}}
                                            //is this worth a ts-ignore?  we know this value will be in the datadictionary
                                            attribute={props.dataDictionary.rule_attributes.find(att => att.id === RULE_ATTRIBUTES.VAL)??{id: 0, name: "", values: []}}
                                          /> : <></>
                                        }
                                    </td>
                                    <td>
                                        { // 2 is "Don't collect"
                                          coll !== 2 ? <AttributeSelect
                                            value={regSensToDisplay}
                                            onChange={(newVal) => {}}
                                            //is this worth a ts-ignore?  we know this value will be in the datadictionary
                                            attribute={props.dataDictionary.rule_attributes.find(att => att.id === RULE_ATTRIBUTES.SENS)??{id: 0, name: "", values: []}}
                                          /> : <></>
                                        }
                                    </td>
                                    </tr>
                                );
                            return null;}
                            )))
                    }
                </tbody>
            </table>
        );
    }

    //TODO: will need to add unsaved changes warning here when this is merged with that branch
    function updatePolicy(newValues: Partial<APIPolicy>) {
        if (policy === null) { return; }
        const newPolicy = Object.assign({}, policy, newValues);
        setPolicy(newPolicy as APIPolicy);
    }

    function updateRegistration(newValues: Partial<APIRegistration>) {
        if (registration === null) { return; }
        const newRegistration = Object.assign({}, registration, newValues);
        setRegistration(newRegistration as APIRegistration);
    }
}

function getAttribute(id: number, rule: APIRule, dataDictionary: APIDictionary) {
    const attribute_validation = getAttributeFromRule(rule, id);
    if (attribute_validation) {
        const attribute_value = dataDictionary.rule_attributes.filter(a => a.id === attribute_validation.attribute_id).at(0)?.values.at(attribute_validation.value);
        return attribute_value ? attribute_value : null;
    }
    return null;
}

function checkScopeSubset(registration: APIRegistration, policy: APIPolicy) {
    return registration.scope_attributes.map((att, _) => {
        const policyAtt = policy?.scope_attributes.filter((policyAtt) => policyAtt.attribute_id === att.attribute_id)[0];
        if (att?.attribute_id) {
            if (att.value === policyAtt?.value) {
                // return att?.attribute_id;
            } else if ((att.value | (policyAtt?.value ?? 0)) === policyAtt?.value) {
                // return att?.attribute_id;
            } else if ((att.value | (policyAtt?.value ?? 0)) === att.value) {
                return att.attribute_id;
            } else {
                return att.attribute_id;
            }
        }
    return null;}).filter((att) => att !== undefined);
}

// 0: {value: 0, color: 'ff0000', name: 'ø'}
// 1: {value: 1, color: 'ffff00', name: 'Collect'}
// 2: {value: 2, color: '3165ff', name: "Don't Collect"}
// 3: {value: 3, color: 'ffc000', name: "Coll or Don't"}
// 4: {value: 4, color: 'b8ff82', name: 'Optional'}
// 5: {value: 5, color: '00efd2', name: 'Coll or Opt'}
// 6: {value: 6, color: 'ff5cff', name: "Opt or Don't"}
// 7: {value: 7, color: 'ffffff', name: 'Any'}
function filterFact(fact: APIRegistrantFactItem | undefined, attributeVal: number, dataDictionary: APIDictionary) {
    if (!fact) {
        //the fact is missing and the attribute is set to collect
        if (attributeVal === 1) {
            return (
                <span className="attribute-value"
                    style={{
                    backgroundColor: 'black',
                    color: 'yellow',
                    cursor: 'cell',
                    display: "inline-block",
                    padding: '4px',
                    textAlign: 'center',
                    minWidth: '18px',
                    // rounded corners
                    borderRadius: '4px',
                    }}
                >⚠️ MISSING</span>
            );
        }
        return "";
    }
    switch (attributeVal) {
        case 0: return "ø";
        case 1: return fact.value;
        case 2: return "";
        case 4: return fact.value;
        //TODO build other cases
        default: return "";
    }
}

function getRegistration(rfID: number): Promise<APIRegistration> {
    return new Promise((resolve, reject) => {
        fetch(`/api/registration/${rfID}`, {
            headers: getAPITokenHeaders()
        }).then(async rawResponse => {
            const response = await rawResponse.json() as unknown as GetRegistration;
            if (response.status === 'success') {
                return resolve(response.registration);
            } else {
                return reject(response.error);
            }
        }).catch(error => reject(error));
    });
}

function saveNewRegistration(registration: APIRegistration): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const headers = getAPITokenHeaders();
        headers.set('Content-Type', 'application/json');
        fetch(`/api/registration_new`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(registration)
        }).then(async rawResponse => {
            const response = await rawResponse.json() as unknown as APISuccess | APIError;
            if (response.status === 'success') {
                return resolve(true);
            } else {
                return reject(false);
            }
        }).catch(error => reject(error));
    });
}

function saveRegistration(registration: APIRegistration): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const headers = getAPITokenHeaders();
        headers.set('Content-Type', 'application/json');
        fetch(`/api/registration/${registration.id}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(_.omit(registration, ['tlds']))
        }).then(async rawResponse => {
            const response = await rawResponse.json() as unknown as APISuccess | APIError;
            if (response.status === 'success') {
                return resolve(true);
            } else {
                return reject(false);
            }
        }).catch(error => reject(error));
    });
}

async function deleteRegistration(regid: number): Promise<boolean> {
    const rawResponse = await fetch(`/api/registration/${regid}`,
      {
        method: 'DELETE',
        headers: getAPITokenHeaders()
      });
    const response = await rawResponse.json() as unknown as { status: "success" } | APIError;
    if (response.status === 'success') {
      return true;
    } else {
      throw new Error(response.error);
    }
}

async function copyRegistration(regid: number): Promise<number> {
    const rawResponse = await fetch(`/api/registration/copy/${regid}`,
    {
        method: 'POST',
        headers: getAPITokenHeaders()
      });
    const response = await rawResponse.json() as unknown as PostRegistration | APIError;
    if (response.status === 'success') {
      return response.id;
    } else {
      throw new Error(response.error);
    }
}
