import React from 'react';
import { PolicyListItem, NewPolicy } from './common/api';
import { ORG_TYPE_ABBR, timeDifference } from './common/util';
import LoadingSpinner from './LoadingSpinner';
import navHeader from './NavHeader';
import { getAPITokenHeaders } from './password';
import { getPolicyList } from './policyData';

// Any component receiving props with the PolicyListProps type must include a function prop named onSelectPolicy: takes a number argument representing a new policy ID; does not return any value (void)
interface PolicyListProps
  {
  onSelectPolicy: (newPolicyID: number) => void;
  }

// React component responsible for rendering a list of policies and associated information
export default function PolicyList(props: PolicyListProps): JSX.Element
  {
  const [policyList, setPolicyList] = React.useState<PolicyListItem[] | null>(null);
  const [isLoadingList, setIsLoadingList] = React.useState<boolean>(false);

// Extract the data fetching logic into a separate function for better readability.
  const fetchData = async () =>
      {
      setIsLoadingList(true);
      getPolicyList().then(setPolicyList).finally(() => {setIsLoadingList(false);});
      };

// send a PUT request to the '/api/policy' endpoint to create a new policy; return a Promise that resolves to a number representing the ID of the newly created policy
  const createNewPolicy = async (): Promise<number> =>
      {
      const rawResponse = await fetch('/api/policy', {method: 'PUT', headers: getAPITokenHeaders(),});
      const response = (await rawResponse.json()) as NewPolicy;

      return (response.status === 'success') ? response.id : (() => {throw new Error(response.error || 'Failed to create a new policy.');})();
      }

// Instead of placing the data fetching logic in the body of the component, consider using useEffect to separate the concerns of data fetching and rendering.
  React.useEffect(() =>
    {
    if (policyList === null && !isLoadingList) fetchData();
    }, [policyList, isLoadingList]);

// "Create New Policy" Button element logic
  const handleCreateNewPolicy = async () =>
    {
    try
      {
      const newPolicyID = await createNewPolicy();
      window.location.hash = `/policy/${newPolicyID}`;
      }
    catch (e: any)
      {
      console.error('Error creating a new policy:', e.message);
      }
    };

// Renders JSX based on the current state: If policyList is null, it displays a loading spinner; Otherwise, it renders a table with policy information, including a button to create a new policy.
  return (policyList === null) ? <LoadingSpinner /> : (<div> {navHeader()} <h2> Policy Expressions (PEX) </h2><button onClick={handleCreateNewPolicy}>Create New Policy</button><table className="policy-list-table"><tbody>{policyList.map((policy) => (<tr key={policy.id} onClick={() => props.onSelectPolicy(policy.id)}><td><a href={`#/policy/${policy.id}`}><span className="org-type-badge">{ORG_TYPE_ABBR[policy.org_type]}</span>{policy.name}</a><br />{policy.prime_poc !== '' ? policy.prime_poc + ', ' : null}{policy.org_name}</td><td style={{ textAlign: 'right' }}>Version {policy.version}<br /><small>(Edited {timeDifference(Date.now() / 1000, policy.modified)})</small></td></tr>))}</tbody></table></div>);
  }
