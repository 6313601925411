import { APIPolicy, GetPolicyList, PolicyListItem } from "./common/api";
import { getAPITokenHeaders } from "./password";

// Global variable to store cached policy list
let policyList: PolicyListItem[] | null = null;

// Fetch the policy list, update the cached value, and return it
export async function getPolicyList(): Promise<PolicyListItem[]>
  {
  policyList = await getPolicyList_uncached();
  return policyList;
  }

// Fetch metadata for specific policies using API
export async function getPolicyMetadata(policies: number[])
  {
  const rawResponse = await fetch(`/api/policies?policy=${policies.join(",")}`, {headers: getAPITokenHeaders()});
  const response = await rawResponse.json() as unknown as GetPolicyList;
  return response.status === 'success' ? response.policies : (() => { throw new Error(response.error); })();
  }

// Clear the cached policy list
export const clearPolicyListCache = () => policyList = null;

// Fetch policy list without using cache
const getPolicyList_uncached = (): Promise<PolicyListItem[]> => new Promise((resolve, reject) => {fetch('/api/policies', {headers: getAPITokenHeaders()}).then(async rawResponse => {const response = await rawResponse.json() as unknown as GetPolicyList; return response.status === 'success' ? (sortPolicyList(response.policies), resolve(response.policies)) : reject(response.error);}).catch(error => reject(error));});

// Order of organization types for sorting policy list
export const ORG_TYPE_ORDERING: { [key in APIPolicy['org_type']]: number } = {"policy_authority": 1, "registrar": 3, "registry": 2,};

// Sort the policy list based on organization type and name
const sortPolicyList = (policyList: PolicyListItem[]) => policyList.sort((a, b) => a.org_type === b.org_type ? a.name.localeCompare(b.name) : ORG_TYPE_ORDERING[a.org_type] - ORG_TYPE_ORDERING[b.org_type]);



// TODO: enable caching if needed; 5 minute cache time
// let policyListFetched: number = 0; // unix timestamp milliseconds
// if (policyList === null || Date.now() - policyListFetched > 1000 * 60 * 5) {}
