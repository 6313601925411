import { APIPolicy, APIRule, APIRuleAttribute } from "./api";

export function getRuleForElement(policy: APIPolicy, elementID: number): APIRule | undefined
  {
  return policy.rules.find(r => r.element_id === elementID);
  }

export function getAttributeFromRule(rule: APIRule, attributeID: number): APIRuleAttribute | undefined
  {
  return rule.attributes.find(a => a.attribute_id === attributeID);
  }

export function checkUnsavedBeforeUnload(): (isDirty: () => boolean) => void
  {
  return (isDirty: () => boolean) =>
    {
    window.onbeforeunload = (e) =>
      {
      if (isDirty()) {e.preventDefault(); e.returnValue = '';}
      return (isDirty() ? "There are unsaved changes. Are you sure you want to leave?" : undefined);
      }
    }
  }

// given a url, return the hash part of it not including the "#" character, or "" if there is no hash
export function getURLHash(url: string): string
  {
  return ((url.indexOf('#')) ? url.substring (url.indexOf('#') + 1) : "");
}

export function restoreOldHashChange(oldOnhashchange: (((this: WindowEventHandlers, ev: HashChangeEvent) => any) & ((this: Window, ev: HashChangeEvent) => any)) | null | undefined)
  {
  // @ts-ignore
  window.onbeforeunload = null;
  window.onhashchange = oldOnhashchange ?? null;
  }

export const ORG_TYPE_ABBR: { [key in APIPolicy['org_type']]: string } =
  {
  "policy_authority": "PA",
  "registrar": "Rr",
  "registry": "Ry",
  };

// given two unix timestamps, return the time difference as a human-readable string
export function timeDifference(current: number, previous: number)
  {
  const sPerMinute = 60;
  const sPerHour = sPerMinute * 60;
  const sPerDay = sPerHour * 24;
  const sPerMonth = sPerDay * 30;
  const sPerYear = sPerDay * 365;
  const elapsed = current - previous;
  let result: string;

  switch (true)
    {
    case elapsed < sPerMinute : result = Math.round(elapsed             ) + ' seconds ago'; break;
    case elapsed < sPerHour   : result = Math.round(elapsed / sPerMinute) + ' minutes ago'; break;
    case elapsed < sPerDay    : result = Math.round(elapsed / sPerHour  ) + ' hours ago'; break;
    case elapsed < sPerMonth  : result = Math.round(elapsed / sPerDay   ) + ' days ago'; break;
    case elapsed < sPerYear   : result = Math.round(elapsed / sPerMonth ) + ' months ago'; break;
    default                   : result = Math.round(elapsed / sPerYear  ) + ' years ago'; break;
    }

  return result;
  }
